import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { MDBDataTable } from "mdbreact";

import axios from "axios";

class Consumers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      consumers: [],
      user: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      axios
        .get(process.env.REACT_APP_API_LINK + "/api/consumer/all/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          const consumers2 = res.data;
          consumers2.forEach(function (part, index) {
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/admin/consumer/" + this[index].Id}
                  size="sm"
                  title="Στοιχεία Καταναλωτή"
                >
                  Στοιχεία Καταναλωτή
                </Button>
              </React.Fragment>
            );
          }, consumers2);
          this.datas = {
            columns: [
              {
                label: "Ονομα ▼",
                field: "Name",
                sort: "asc",
                width: 100,
              },
              {
                label: "Επιθετο ▼",
                field: "LastName",
                sort: "asc",
                width: 150,
              },
              {
                label: "Τηλεφωνο ▼",
                field: "phoneNumber",
                sort: "asc",
                width: 150,
              },
              ,
              {
                label: "Email ▼",
                field: "Email",
                sort: "asc",
                width: 150,
              },
              {
                label: "Επωνυμια εταιριας ▼",
                field: "companyName",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ενεργειες ▼",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const consumers = res.data;
          this.setState({ consumers });
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Καταναλωτές</h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>

                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονομα</th>
                      <th scope="col">Επιθετο</th>
                      <th scope="col">Τηλεφωνο</th>
                      <th scope="col">Email</th>
                      <th scope="col">Επωνυμια εταιριας</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.consumers.map((dynamicData) =>
                      <tr>
                        <td>{dynamicData.Name}</td>
                        <td>{dynamicData.LastName}</td>
                        <td>{dynamicData.phoneNumber}</td>
                        <td>{dynamicData.Email}</td>
                        <td>{dynamicData.consumerType == 2 ? dynamicData.companyName : "-"}</td>
                        <td>
                          <Button
                            color="primary"
                            href={"/admin/consumer/" + dynamicData.Id}
                            size="sm"
                          >
                            Στοιχεία Καταναλωτή
                          </Button>
                          {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                            <>
                              <Button
                                  color="danger"
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                <i className="fas fa-trash" />
                              </Button>
                            </> : <> </> }
                        </td>
                      </tr>
                     )}
                  </tbody>
                </Table> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Consumers;
