import React from "react";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   InfoWindow
// } from "react-google-maps";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  Button,
  CardHeader,
} from "reactstrap";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  LayerGroup,
} from "react-leaflet";

// core components
import MapsHeader from "components/Headers/MapsHeader.js";
import L from "leaflet";

import endpoint from "./config.js";

const customMarker_anoixtes_aitiseis = new L.icon({
  className: "energes_aitiseis",
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const customMarker_ekdilosi_endiaferontos = new L.icon({
  className: "ekdilosi_endiaferontos",
  iconUrl:
    "https://w3bspace.uk/wp-content/uploads/2016/01/location_map_pin_yellow5.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

// const { compose, withProps, withStateHandlers } = require("recompose");

// const MapWithAMakredInfoWindow = compose(
//   withStateHandlers(() => ({
//       isOpen: false,
//     }), {
//       onToggleOpen: ({ isOpen }) => () => ({
//         isOpen: !isOpen,
//       })
//     }),
//     withScriptjs,
//     withGoogleMap
//   )(props =>
//     <GoogleMap
//       defaultZoom={6}
//       defaultCenter={{ lat: 38.57245445033553, lng: 24.0608678264494 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "administrative",
//             elementType: "labels.text.fill",
//             stylers: [{ color: "#444444" }]
//           },
//           {
//             featureType: "landscape",
//             elementType: "all",
//             stylers: [{ color: "#f2f2f2" }]
//           },
//           {
//             featureType: "poi",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "road",
//             elementType: "all",
//             stylers: [{ saturation: -100 }, { lightness: 45 }]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "all",
//             stylers: [{ visibility: "simplified" }]
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "labels.icon",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "transit",
//             elementType: "all",
//             stylers: [{ visibility: "off" }]
//           },
//           {
//             featureType: "water",
//             elementType: "all",
//             stylers: [{ color: "#5e72e4" }, { visibility: "on" }]
//           }
//         ]
//       }}
//     >
//       <Marker key={'a8e3a283-c28b-45a7-ae2d-f0886e59bb1f'} position={{ lat: 37.9847814724, lng: 23.760927641947823 }} onClick={props.onToggleOpen} title="Click to zoom">
//         {props.isOpen &&
//           <InfoWindow onCloseClick={props.onToggleOpen}>
//             <span className="h3 font-weight-bold mb-0">
//               Αίτηση με Α/Α: 104/2021 <a href='/admin/instance/a8e3a283-c28b-45a7-ae2d-f0886e59bb1f'> Προβολή </a>
//             </span>
//           </InfoWindow>
//         }
//       </Marker>
//       <Marker position={{ lat: 41.122440, lng: 25.406557 }} />
//       <Marker position={{ lat: 40.629269, lng: 22.947412 }} />
//       <Marker position={{ lat: 40.845718, lng: 25.873962 }} />
//       <Marker position={{ lat: 39.665028, lng: 20.853746 }} />
//     </GoogleMap>
// );

class Maps extends React.Component {
  render() {
    return (
      // <>
      //   <MapsHeader />
      //
      //   <Container className="mt-2" fluid>
      //     <Row>
      //       <div className="col">
      //         <Card className="shadow border-0">
      //           <MapWithAMakredInfoWindow
      //             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBN8mVYiwamtmvNqLTm5CoCqYEB8Gg4nEg"
      //             loadingElement={<div style={{ height: `100%` }} />}
      //             containerElement={
      //               <div
      //                 style={{ height: `600px` }}
      //                 className="map-canvas"
      //                 id="map-canvas"
      //               />
      //             }
      //             mapElement={
      //               <div style={{ height: `100%`, borderRadius: "inherit" }} />
      //             }
      //           />
      //         </Card>
      //       </div>
      //     </Row>
      //   </Container>
      // </>

      <>
        <MapsHeader />

        <Container className="mt-2" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Ενεργές αιτήσεις</h3>
                    </Col>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/instances"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <MapContainer
                    center={[38.57245445033553, 24.0608678264494]}
                    zoom={7}
                    scrollWheelZoom={false}
                    style={{ height: "100vh", width: "100wh" }}
                  >
                    <LayersControl position="topright">
                      <LayersControl.Overlay
                        checked
                        name="Αιτήσεις Εκδήλωσης Ενδιαφέροντος "
                      >
                        <LayerGroup>
                          <Marker
                            position={[37.919384, 23.720712]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                          <Marker
                            position={[40.845718, 25.873962]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[39.665028, 20.853746]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                        </LayerGroup>
                      </LayersControl.Overlay>

                      <LayersControl.Overlay checked name="Ενεργές Αιτήσεις ">
                        <LayerGroup>
                          <Marker
                            position={[40.891360000000084, 22.914040000000057]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[41.12244, 25.406557]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[40.629269, 22.947412]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                        </LayerGroup>
                      </LayersControl.Overlay>
                    </LayersControl>

                    <TileLayer
                      attribution="&copy; EvolutionProjects+"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </MapContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ακίνητα</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/properties"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <MapContainer
                    center={[38.57245445033553, 24.0608678264494]}
                    zoom={7}
                    scrollWheelZoom={false}
                    style={{ height: "100vh", width: "100wh" }}
                  >
                    <LayersControl position="topright">
                      <LayersControl.Overlay checked name="Οικιακοί">
                        <LayerGroup>
                          <Marker
                            position={[37.919384, 23.720712]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                          <Marker
                            position={[40.845718, 25.873962]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[39.665028, 20.853746]}
                            icon={customMarker_ekdilosi_endiaferontos}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                        </LayerGroup>
                      </LayersControl.Overlay>

                      <LayersControl.Overlay checked name="Βιομηχανίες">
                        <LayerGroup>
                          <Marker
                            position={[40.891360000000084, 22.914040000000057]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[41.12244, 25.406557]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>

                          <Marker
                            position={[40.629269, 22.947412]}
                            icon={customMarker_anoixtes_aitiseis}
                          >
                            <Popup>
                              A/A : <br /> HKAΣΠ :
                            </Popup>
                          </Marker>
                        </LayerGroup>
                      </LayersControl.Overlay>
                    </LayersControl>

                    <TileLayer
                      attribution="&copy; EvolutionProjects+"
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </MapContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Εκδηλώσεις ενδιαφέροντος</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                  <MapContainer center={[38.57245445033553, 24.0608678264494]} zoom={7} scrollWheelZoom={false} style={{ height: '100vh', width: '100wh' }}>
                    <TileLayer
                      attribution='&copy; EvolutionProjects+'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[40.891360000000084, 22.914040000000057]} icon={customMarker}>
                      <Popup>
                        <span>A/A :  <br /> HKAΣΠ : </span>
                      </Popup>
                    </Marker>
                    
                    <Marker position={[41.122440, 25.406557 ]} icon={customMarker}>
                      <Popup>
                        A/A :  <br /> HKAΣΠ : 
                      </Popup>
                    </Marker>

                    <Marker position={[40.629269, 22.947412]} icon={customMarker}>
                      <Popup>
                        A/A :  <br /> HKAΣΠ : 
                      </Popup>
                    </Marker>

                    <Marker position={[40.845718, 25.873962]} icon={customMarker}>
                      <Popup>
                        A/A :  <br /> HKAΣΠ : 
                      </Popup>
                    </Marker>

                    <Marker position={[39.665028, 20.853746]} icon={customMarker}>
                      <Popup>
                        A/A :  <br /> HKAΣΠ : 
                      </Popup>
                    </Marker>
                  </MapContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
              
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Εκδηλώσεις ενδιαφέροντος εντός δικτύου</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/availableapplications"}
                        size="sm"
                      >
                        Προβολή όλων
                        
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Εκδηλώσεις ενδιαφέροντος εκτός δικτύου</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/unavailableapplications"}
                        size="sm"
                      >
                        Προβολή όλων
                        
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </>
    );
  }
}

export default Maps;
