import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import { MDBDataTable } from "mdbreact";

class Mechanics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      mechanics: [],
      user: [],
    };
  }

  async componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_LINK + "/api/mechanics/all/", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        const instances2 = res.data;
        instances2.forEach(function (part, index) {
          this[index].Buttons = (
            <React.Fragment>
              <Button
                color="primary"
                href={"/admin/mechanic/" + this[index].Id}
                size="sm"
              >
                Στοιχεία Μηχανικού
              </Button>
            </React.Fragment>
          );
        }, instances2);
        this.datas = {
          columns: [
            {
              label: "Ονομα ▼",
              field: "Name",
              sort: "asc",
              width: 100,
            },
            {
              label: "Επιθετο ▼",
              field: "LastName",
              sort: "asc",
              width: 100,
            },
            {
              label: "Email ▼",
              field: "Email",
              sort: "asc",
              width: 150,
            },
            {
              label: "Τηλεφωνο ▼",
              field: "Phone",
              sort: "asc",
              width: 150,
            },
            {
              label: "Μεταβαση ▼",
              field: "Buttons",
              sort: "asc",
              width: 150,
            },
          ],
          rows: res.data,
        };

        const mechanics = res.data;
        this.setState({ mechanics });
      });

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleClick = (instanceId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/" +
            instanceId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Μηχανικοί</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Μηχανικών ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Mechanics;
