import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import { MDBDataTable } from "mdbreact";

import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";
import { ApiCallerHelper } from "../../api/apiCallerHelper";

import axios from "axios";

class Supplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      instances: [],
      name: "",
      user: [],
      loadedDatatable: false,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync();
      this.setState({ user: currentUserResult.data });

      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/suppliers/supplier/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances2 = res.data.instances;

          const instances = [];
          instances2.forEach(function (part, index) {
            part[1].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/admin/details/" + part[1].Id}
                  size="sm"
                  title="Προβολή Στοιχείων"
                >
                  Προβολή Στοιχείων
                </Button>
                <Button
                  color="warning"
                  href={"/admin/instance/" + part[1].Id}
                  size="sm"
                >
                  Βήματα Αίτησης
                </Button>
              </React.Fragment>
            );

            instances.push(part[1]);
          }, instances2);

          this.state.datas = {
            columns: [
              {
                label: "Α/Α ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 100,
              },
              {
                label: "Kατασταση ▼",
                field: "Status",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ενεργειες",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: instances,
          };

          this.setState({ instances, loading: false, name: res.data.Name });
        });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Προμηθευτή</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Maintenance' ?
                          <>
                            <Button
                              color="primary"
                              href="#pablo"
                              //   onClick={() => { this.saveChanges(this.state.details.Id) }}
                              size="sm"
                            >
                              Αποθήκευση αλλαγών
                            </Button>
                          </> : <> </>} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.name}
                                id="input-username"
                                placeholder="Όνομα"
                                type="text"
                                disabled="true"
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <MDBDataTable
                          responsive
                          bordered
                          exportToCSV
                          hover
                          strip
                          data={this.state.datas}
                          id="mdbdttable"
                          entriesOptions={[10, 25, 50, 100, 500, 1000]}
                          entries={10}
                          noDataComponent="Δεν υπάρχουν δεδομένα"
                          searchLabel="Αναζήτηση"
                          paginationLabel={["Προηγούμενο", "Επόμενο"]}
                          infoLabel={[
                            "Εμφανίζονται",
                            "έως",
                            "από",
                            "καταχωρήσεις",
                          ]}
                          entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                        />
                      </div>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Supplier;
