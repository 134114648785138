import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { ApiCallerHelper } from "../../api/apiCallerHelper";
import DynamicGridView from "../../components/DynamicPages/DynamicGridView/dynamicGridView.js";
import { RedirectHelper } from "../../common/redirectHelper";
import EvoBaseComponent from "../../components/Abstract/EvoBaseComponent";
import EVOAlertComponent from "../../components/Abstract/EvoAlertComponent";
import TechnicalDescriptionStandAlone from "../../views/custom/florina/technicalDescriptionComponent/TechnicalDescriptionStandAlone"
class Funding extends EvoBaseComponent {
    constructor(props){
        super(props);
        this.state = {
        error: null,
        showDetails: false,
        isLoaded: false,      
        gridDataSource : {columns:[], data:[]},
        fundingFilesModal : [],
        selectedRowInstanceId : null,
        ...this.state,         
        };

        this.setShow = this.setShow.bind(this);
    }

    componentDidMount() {
        super.componentDidMount(     
            this.initializeComponent
        )
    }  

    initializeComponent = async () => { 
    
    }

    async setShow(boolean, instanceId) {
        if (boolean == false){
            this.setState({
                showDetails: boolean,               
            });
            return;
        }        
        let fundingFiles = await ApiCallerHelper.Files.getFilesForFunding(instanceId)

        this.setState({
            showDetails: boolean,
            fundingFilesModal: fundingFiles.data,       
            selectedRowInstanceId : instanceId
        });
    }

    viewFile = async (fileId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        if (data.url) {
          window.open(data.url, "_blank"); // Open the presigned URL in a new tab
        }
      } catch (error) {
        console.error("Failed to get presigned URL", error);
      }
    };
  
    deleteFile = async (fileId, instanceId) => {
      let promptResult = await EVOAlertComponent.Prompt({});
      if (promptResult.isConfirmed === true) {
        if (localStorage.getItem("token")) {
          let deleteResult = await ApiCallerHelper.Files.deleteFileByFileId(fileId);
          let refreshResult = await ApiCallerHelper.Files.getFilesForFunding(instanceId);

          this.setState({
              fundingFilesModal: refreshResult.data
          })
        }
      }
    };

    RenderHelper = {
        ModalMoreDetails: () => {
            return (
              <Modal
                show={this.state.showDetails}
                onHide={() => this.setShow(false)}
                //dialogClassName="modal-90w"
                dialogClassName="tool-theme_modal-dialog--large-90"
                aria-labelledby="example-custom-modal-styling-title"
                style={{ opacity: 1 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Λεπτομέρειες
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Tabs
                    defaultActiveKey="0"
                    className={"tool-tabs-title--bold"}
                  >
                    <Tab eventKey={"0"} title="ΠΙΝΑΚΑΣ ΟΙΚΟΝΟΜΙΚΩΝ" key={"0"}>
                      <Row className="mt-3">
                        <TechnicalDescriptionStandAlone
                          instanceId={this.state.selectedRowInstanceId}
                        />
                      </Row>
                    </Tab>
                    <Tab eventKey={"1"} title="ΕΓΓΡΑΦΑ" key={"1"}>
                    <Row className="mt-3">
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Ονομα</th>
                            <th scope="col">Ημερομηνια Υποβολης</th>
                            <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fundingFilesModal.map((file) => (
                            <tr>
                              <td>{file.FileName}</td>
                              <td>
                                {Moment(file.UploadDate)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => this.viewFile(file.Id)}
                                  size="sm"
                                  title="Προβολή"
                                >
                                  <i className="fas fa-eye" />
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.deleteFile(file.Id, file.InstanceId);
                                  }}
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      </Row>
                    </Tab>
                  </Tabs>
                </Modal.Body>
              </Modal>
            );
        }
    }

 
    render() {
        return (
        <>
        <GenericHeader />
            <Container className="mt--7" fluid>
                <Row className="mt-3">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                                    </Col>
                                    <Col className="text-right" xs="4"/>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <DynamicGridView
                                    gridViewId="ec9451f8-82d8-4886-a1e2-e1ca59a10488" // 003
                                    customActionsCallbackMethods = {{
                                        approveFunding : async value => {
                                            let promptResult = await EVOAlertComponent.Prompt({});
                                            if (promptResult.isConfirmed === true) {
                                              await ApiCallerHelper.WorkflowInstances.respondToFunding(value.Id, true);
                                              RedirectHelper.RedirectTo.reloadPageFromCache();
                                            }                                               
                                        },
                                        declineFunding : async value => {
                                          let promptResult = await EVOAlertComponent.Prompt({});
                                            if (promptResult.isConfirmed === true) {
                                            await ApiCallerHelper.WorkflowInstances.respondToFunding(value.Id, false);
                                            RedirectHelper.RedirectTo.reloadPageFromCache();
                                            }
                                        },
                                        moreDetails : value => {
                                            this.setShow(true, value.InstanceId);
                                        }
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.RenderHelper.ModalMoreDetails()}
            </Container>
        </>
    );
  }
}

export default Funding;
