import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Input,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import { useState } from "react";
import "moment/locale/el";

import { MDBDataTable } from "mdbreact";

// import '../../assets/css/datatable.css';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

class Reporting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      RAEpostsTitle: [],
      RAEpostsUrl: [],
      RAEpostsContent: [],
      posts: [],
      dummy: [],
      selectedCheckboxes: [],
      selectedCheckboxesOldPosts: [6],
      checkboxes: [],
      startDate: new Date(),
      otherDay: new Date(),
      startDateMechanicDate: new Date(),
      endDateMechanicDate: new Date(),
      startDateActivationDate: new Date(),
      endDateActivationDate: new Date(),
      locations: [],
      locationsdict: {},
      LocationId: "-",
      userDetails: [],
    };

    this.handleChangesigndate1 = this.handleChangesigndate1.bind(this);
    this.handleChangesigndate2 = this.handleChangesigndate2.bind(this);
    this.handleChangemechaniccompletedate1 =
      this.handleChangemechaniccompletedate1.bind(this);
    this.handleChangemechaniccompletedate2 =
      this.handleChangemechaniccompletedate2.bind(this);
    this.handleChangeactivationdate1 =
      this.handleChangeactivationdate1.bind(this);
    this.handleChangeactivationdate2 =
      this.handleChangeactivationdate2.bind(this);
    this.state.startDate.setMonth(this.state.otherDay.getMonth() - 2);
    this.state.startDateMechanicDate.setMonth(
      this.state.endDateMechanicDate.getMonth() - 2
    );
  }

  handleChangesigndate1(date) {
    this.setState({
      startDate: date,
    });

    this.getDates_call(date, this.state.otherDay);

    this.fetchDEDAInstances1(date, this.state.otherDay);
  }

  handleChangesigndate2(date) {
    this.setState({
      otherDay: date,
    });

    this.getDates_call(this.state.startDate, date);

    this.fetchDEDAInstances1(this.state.startDate, date);
  }

  handleChangemechaniccompletedate1(date) {
    this.setState({
      startDateMechanicDate: date,
    });

    this.getDates_call(date, this.state.endDateMechanicDate);

    this.fetchDEDAInstances1(date, this.state.endDateMechanicDate);
  }

  handleChangemechaniccompletedate2(date) {
    this.setState({
      endDateMechanicDate: date,
    });

    this.getDates_call(this.state.startDateMechanicDate, date);

    this.fetchDEDAInstances1(this.state.startDateMechanicDate, date);
  }

  handleChangeactivationdate1(date) {
    this.setState({
      startDateActivationDate: date,
    });

    this.getDates_call(date, this.state.endDateActivationDate);

    this.fetchDEDAInstances1(date, this.state.endDateActivationDate);
  }

  handleChangeactivationdate2(date) {
    this.setState({
      endDateActivationDate: date,
    });

    this.getDates_call(this.state.startDateActivationDate, date);

    this.fetchDEDAInstances1(this.state.startDateActivationDate, date);
  }

  updateLocation(evt) {
    //
    this.state.LocationId = evt.target.value;
    this.setState({
      LocationId: evt.target.value,
    });
    this.fetchDEDAInstances1(this.state.startDate, this.state.otherDay);
  }

  getDates(startDate, endDate) {
    var dates = [],
      currentDate = startDate,
      addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(currentDate.toLocaleDateString("en-GB"));
      currentDate = addDays.call(currentDate, 1);
    }
    this.state.array = dates;
    return dates;
  }

  getDates_call(date, otherDate) {
    var dates = this.getDates(date, otherDate);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });
    }

    await fetch(process.env.REACT_APP_API_LINK + "/api/user/locations/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          //
          let locations = [...result];
          const self = this;
          locations.forEach(function (element, index) {
            self.state.locationsdict[element.Id] = element.City;
          }, locations);

          this.setState({
            locations: result,
            locationsdict: self.state.locationsdict,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
    this.fetchDEDAInstances1(this.state.startDate, this.state.otherDay);
  }

  onChangeStatus = (id) => {
    var selectedCheckboxes = this.state.selectedCheckboxesOldPosts;

    // Find index
    const findIdx = selectedCheckboxes.indexOf(id);

    // Index > -1 means that the item exists and that the checkbox is checked
    // and in that case we want to remove it from the array and uncheck it

    if (findIdx > -1) {
      selectedCheckboxes.splice(findIdx, 1);
    } else {
      if (!selectedCheckboxes.includes(id)) {
        selectedCheckboxes = [id];
      }
    }

    this.state.selectedCheckboxesOldPosts = selectedCheckboxes;
    this.fetchDEDAInstances1(this.state.startDate, this.state.otherDay);
  };

  fetchDEDAInstances1 = (from = "", to = "") => {
    var fromArray = from.toLocaleDateString("en-GB").split("/");
    var toArray = to.toLocaleDateString("en-GB").split("/");

    var correctFromDate =
      fromArray[2] + "-" + fromArray[1] + "-" + fromArray[0];
    var correctToDate = toArray[2] + "-" + toArray[1] + "-" + toArray[0];

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/workflowinstances/depallfilter/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            items: this.state.selectedCheckboxesOldPosts,
            dateFrom: correctFromDate,
            dateTo: correctToDate,
            locationId: this.state.LocationId,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          try {
            const self = this;
            data.forEach(function (part, index) {
              // this[index].EditedBy = this[index].EditedBy.split()[0];
              this[index].Buttons = (
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/details/" + this[index].Id}
                    size="sm"
                  >
                    Στοιχεία Αίτησης
                  </Button>
                  <Button
                    color="warning"
                    href={"/admin/instance/" + this[index].Id}
                    size="sm"
                  >
                    Βήματα Αίτησης
                  </Button>
                </React.Fragment>
              );

              this[index].Status =
                this[index].Status == 1
                  ? "1. Αίτηση Σύνδεσης"
                  : this[index].Status == 2
                  ? "2. Πρόσθετα Δικαιολογητικά"
                  : this[index].Status == 3
                  ? "3. Τεχνική Αξιολόγηση Ακινήτου"
                  : this[index].Status == 4
                  ? "4. Σύνταξη Προσφοράς"
                  : this[index].Status == 5
                  ? "5. Υπογραφή Σύμβασης"
                  : this[index].Status == 6
                  ? "6. Μηχ.Ολοκλήρωση & Μελέτη εσωτ. εγκατ."
                  : this[index].Status == 7
                  ? "7. Σύμβαση με Προμηθευτή"
                  : this[index].Status == 8
                  ? "8. Αίτημα Αυτοψίας"
                  : this[index].Status == 9
                  ? "9. Ενεργοποίηση εσωτερικής εγκατάστασης"
                  : this[index].Status == 10
                  ? "10. Φύλλο Καύσης"
                  : this[index].Status == 11
                  ? "11. Έκδοση άδειας χρήσης"
                  : "";
              this[index].City =
                self.state.locationsdict[this[index].LocationId];
              this[index]["Mechanic Completion Date"] =
                this[index]["Mechanic Completion Date"] == null ||
                this[index]["Mechanic Completion Date"] == ""
                  ? "-"
                  : this[index]["Mechanic Completion Date"];
              this[index]["Activation Date"] =
                this[index]["Activation Date"] == null ||
                this[index]["Activation Date"] == ""
                  ? "-"
                  : this[index]["Activation Date"];
              // this[index]['Mechanic Completion Date'] = typeof(this[index]['Mechanic Completion Date']);

              // if(this[index]['Mechanic Completion Date'] == null || this[index]['Mechanic Completion Date'] == ''){
              //     this[index]['Mechanic Completion Date'] = '-';
              // }
            }, data);

            this.setState({
              dummy: {
                columns: [
                  {
                    label: "Αριθμος Αιτησης ▼",
                    field: "SerialNumber",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Πολη ▼",
                    field: "City",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Κατασταση ▼",
                    field: "Status",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ονομα Καταναλωτη ▼",
                    field: "Name",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Επιθετο Καταναλωτη ▼",
                    field: "Surname",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ημερομηνια Υπογραφης ▼",
                    field: "Signing Date",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ημερομηνια Μηχανικης Ολοκληρωσης ▼",
                    field: "Mechanic Completion Date",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "Ημερομηνια Ενεργοποιησης ▼",
                    field: "Activation Date",
                    sort: "asc",
                    width: 150,
                  },
                  {
                    label: "ΤΕΛ. ΕΝΗΜΕΡΩΣΗ ΑΠΟ ▼",
                    field: "EditedBy",
                    sort: "asc",
                    width: 150,
                  },
                  // {
                  //     label: 'Επίθετο Καταναλωτή ▼',
                  //     field: 'AssignedUser',
                  //     sort: 'asc',
                  //     width: 150
                  // },
                  {
                    label: "Μεταβαση ▼",
                    field: "Buttons",
                    sort: "asc",
                    width: 150,
                  },
                ],
                rows: data,
              },
            });
          } catch {
            //// console.log('no category selected!');
          }
        });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ paddingBottom: "25px" }} className="mb-0">
                    Εξαγωγή δεδομένων
                  </h3>
                  <Row className="align-items-center">
                    <div className="container-fluid">
                      <div className="row">
                        {this.state.selectedCheckboxesOldPosts.includes(6) ? (
                          <>
                            <div className="col-sm">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                style={{ marginTop: "35px" }}
                              >
                                Ημ. Υπογραφής Σύμβασης
                              </label>
                            </div>
                            <div className="col-sm">
                              Από
                              <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangesigndate1}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            {this.getDates_call()}
                            <div className="col-sm">
                              Έως
                              <DatePicker
                                selected={this.state.otherDay}
                                onChange={this.handleChangesigndate2}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div
                          className="col-sm"
                          style={{ marginTop: "-10px" }}
                        ></div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        {this.state.selectedCheckboxesOldPosts.includes(7) ? (
                          <>
                            <div className="col-sm">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                style={{ marginTop: "35px" }}
                              >
                                Ημ. Μηχανικής Ολοκλήρωσης
                              </label>
                            </div>

                            <div
                              className="col-sm"
                              style={{ marginTop: "10px" }}
                            >
                              Από
                              <DatePicker
                                selected={this.state.startDateMechanicDate}
                                onChange={
                                  this.handleChangemechaniccompletedate1
                                }
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            {this.getDates_call()}
                            <div
                              className="col-sm"
                              style={{ marginTop: "10px" }}
                            >
                              Έως
                              <DatePicker
                                selected={this.state.endDateMechanicDate}
                                onChange={
                                  this.handleChangemechaniccompletedate2
                                }
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div
                          className="col-sm"
                          style={{ marginTop: "-10px" }}
                        ></div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        {this.state.selectedCheckboxesOldPosts.includes(9) ? (
                          <>
                            <div className="col-sm">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                style={{ marginTop: "35px" }}
                              >
                                Ημ. Ενεργοποίησης
                              </label>
                            </div>
                            <div
                              className="col-sm"
                              style={{ marginTop: "10px" }}
                            >
                              Από
                              <DatePicker
                                selected={this.state.startDateActivationDate}
                                onChange={this.handleChangeactivationdate1}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                            {this.getDates_call()}
                            <div
                              className="col-sm"
                              style={{ marginTop: "10px" }}
                            >
                              Έως
                              <DatePicker
                                selected={this.state.endDateActivationDate}
                                onChange={this.handleChangeactivationdate2}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div
                          className="col-sm"
                          style={{ marginTop: "-10px" }}
                        ></div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm">
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                            style={{ marginTop: "35px" }}
                          >
                            Επιλέξτε Πόλη
                          </label>
                        </div>

                        <div className="col-sm" style={{ marginTop: "10px" }}>
                          Από
                          <Input
                            style={{
                              height: "40px",
                              border: "1px solid rgb(118, 118, 118)",
                            }}
                            className="form-control-alternative"
                            defaultValue={""}
                            id="input-first-name"
                            type="select"
                            onChange={(evt) => this.updateLocation(evt)}
                          >
                            {/* {this.state.userDetails.PersonRole != 'Admin' ? <></> : <><option value={'-'}>{'Όλες οι πόλεις'}</option></>} */}
                            <option value={"-"}>{"Όλες οι πόλεις"}</option>
                            {this.state.locations.map((dynamicLocations) => (
                              <option value={dynamicLocations.Id}>
                                {dynamicLocations.City}
                              </option>
                            ))}
                          </Input>
                        </div>
                        <div
                          className="col-sm"
                          style={{ marginTop: "15px" }}
                        ></div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                        <div className="col-sm">
                          {/* One of three columns */}
                        </div>
                      </div>
                    </div>
                    <span style={{ color: "red" }} id="datepicker_error"></span>

                    <div
                      className="container-fluid"
                      id="datatableContainer"
                      style={{ display: "block" }}
                    >
                      <div className="row">
                        <div className="col" style={{ marginTop: "30px" }}>
                          <div className="row" style={{ marginBottom: "30px" }}>
                            <label key={5}>
                              <input
                                type="checkbox"
                                defaultChecked={true}
                                checked={this.state.selectedCheckboxesOldPosts.includes(
                                  6
                                )}
                                onChange={() => this.onChangeStatus(6)}
                                style={{ marginLeft: "10px" }}
                              />
                              &nbsp; Υπογεγραμμένη Σύμβαση
                            </label>
                            <label key={6}>
                              <input
                                type="checkbox"
                                checked={this.state.selectedCheckboxesOldPosts.includes(
                                  7
                                )}
                                onChange={() => this.onChangeStatus(7)}
                                style={{ marginLeft: "10px" }}
                              />
                              &nbsp; Μηχανική Ολοκλήρωση
                            </label>
                            <label key={7}>
                              <input
                                type="checkbox"
                                checked={this.state.selectedCheckboxesOldPosts.includes(
                                  9
                                )}
                                onChange={() => this.onChangeStatus(9)}
                                style={{ marginLeft: "10px" }}
                              />
                              &nbsp; Ενεργοποίηση
                            </label>
                          </div>

                          <MDBDataTable
                            bordered
                            exportToCSV
                            hover
                            responsive
                            strip
                            data={this.state.dummy}
                            id="mdbdttable"
                            entriesOptions={[10, 25, 50, 100, 500, 1000]}
                            entries={10}
                            // noDataComponent="Δεν υπάρχουν δεδομένα"
                            searchLabel="Αναζήτηση"
                            paginationLabel={["Προηγούμενο", "Επόμενο"]}
                            infoLabel={[
                              "Εμφανίζονται",
                              "έως",
                              "από",
                              "καταχωρήσεις",
                            ]}
                            entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="row">
                    <div className="col">
                      <div className="col-md-12"></div>
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-success btn-sm"
                        table="mdbdttable"
                        filename={
                          "Δημοσιεύσεις από " +
                          this.state.startDate.toLocaleDateString("en-GB") +
                          " έως " +
                          this.state.otherDay.toLocaleDateString("en-GB")
                        }
                        sheet="Δημοσιέυσεις"
                        buttonText="Εξαγωγή σε EXCEL"
                      />
                      <span>
                        &nbsp; Το αρχείο .xls θα περιέχει μόνο όσες καταχωρήσεις
                        έχετε επιλέξει να εμφανίζονται στην τρέχουσα σελίδα.
                      </span>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reporting;
