import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import { MDBDataTable } from "mdbreact";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

class IndustrialApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      applications: [],
      user: [],
    };
  }

  async componentDidMount() {
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/applications/industrials/-1/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const applications2 = res.data;
        applications2.forEach(function (part, index) {
          this[index].Buttons = (
            <React.Fragment>
              <Button
                color="primary"
                href={"/admin/industrial/" + this[index].Id}
                size="sm"
                title="Επεξεργασία"
              >
                <i className="fas fa-pencil-alt" />
              </Button>
              {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                <>  */}
              <Button
                color="danger"
                // onClick={this.callToDelete(dynamicData.Id)}
                size="sm"
                title="Διαγραφή"
              >
                <i className="fas fa-trash" />
              </Button>
            </React.Fragment>
          );
        }, applications2);
        this.datas = {
          columns: [
            {
              label: "Περιφερεια ▼",
              field: "state",
              sort: "asc",
              width: 100,
            },
            {
              label: "Πολη ▼",
              field: "city",
              sort: "asc",
              width: 150,
            },
            //  {
            //     label: 'ΤΚ ▼',
            //     field: 'zipCode',
            //     sort: 'asc',
            //     width: 150
            //   },
            {
              label: "Διευθυνση ▼",
              field: "mainAddress",
              sort: "asc",
              width: 150,
            },
            {
              label: "Αρ. Διευθυνσης ▼",
              field: "addressNum",
              sort: "asc",
              width: 150,
            },
            {
              label: "Ημερομηνια Υποβολης ▼",
              field: "dateCreated",
              sort: "asc",
              width: 150,
            },
            {
              label: "Επιθετο ▼",
              field: "LastName",
              sort: "asc",
              width: 150,
            },
            {
              label: "Ενεργειες ▼",
              field: "Buttons",
              sort: "asc",
              width: 150,
            },
          ],
          rows: res.data,
        };

        const applications = res.data;
        this.setState({ applications });
      });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        Εκδηλώσεις ενδιαφέροντος <strong>Βιομηχανικών</strong>
                      </h3>
                    </Col>
                  </Row>

                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IndustrialApplications;
