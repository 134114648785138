import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import Iframe from "react-iframe";
import { CommonLoading } from "react-loadingg";

import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

import { ApiCallerHelper } from "../../api/apiCallerHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";

import endpoint from "./config.js";

class NewProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      details: [],
      user: [],
      GISDataAddress: null,
    };

    this.messageHandler = this.messageHandler.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync();
      this.setState({ user: currentUserResult.data });
    }
  }

  messageHandler = (event) => {
    const { action, key, value, data } = event.data;
    if (action && action == "returnData") {
      let GISDataAddress = null;
      data.forEach((item) => {
        if (item["address"] != null) {
          GISDataAddress = item;
        }
      }, this);

      if (GISDataAddress) {
        this.setState({
          GISDataAddress: GISDataAddress,
        });
        this.createProperty(GISDataAddress);
      }
    }
  };

  async createProperty(GISDataAddress) {
    const createPropertyResult =
      await ApiCallerHelper.Property.postNewPropertyGIS(GISDataAddress);

    window.location.replace("/admin/property/" + createPropertyResult.data.Id);
  }

  fetchAddress() {
    const iframe = document.getElementById("iframe-map");
    iframe.contentWindow.postMessage(
      {
        action: "get",
        key: "GISData",
      },
      "*"
    );

    window.addEventListener("message", this.messageHandler, false);
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Προσθήκη Νέου Ακινήτου</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          onClick={() => {
                            this.fetchAddress();
                          }}
                          size="sm"
                        >
                          Δημιουργία Ακινήτου
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Iframe
                        url={GlobalParametersHelper.getDedaMapLocation()}
                        width="1010px"
                        height="490px"
                        id="iframe-map"
                        className=""
                        display="block"
                        position="relative"
                        scrolling="no"
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default NewProperty;
