import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

// export async function getAllMeters() {
//     var route = "/api/meters/all/";
//     return getAsync(route);
// }

export async function setMOPBilling(meterId, checked) {
  var route = "/api/meters/mopbilling/";
  let data = {
    MeterId: meterId,
    Checked: checked,
  };

  return putAsync(route, data);
}

export async function setMOPDeclaration(meterId, declaration, year) {
  var route = "/api/meters/mopdeclaration/";
  let data = {
    MeterId: meterId,
    Declaration: declaration,
    Year: year,
  };

  return putAsync(route, data);
}

export async function setMetterActiveState(meterId, isActive) {
  var route = "/api/meters/active/";
  let data = {
    MeterId: meterId,
    Option: isActive,
  };

  return postAsync(route, data);
}

export async function getMeterById(meterId) {
  var route = "/api/meters/meter/" + meterId + "/";
  return getAsync(route);
}

export async function updateMeter(
  meterId,
  type,
  serialNumber,
  adaptation,
  dateInstalled,
  metricSystem,
  metricSystemCategory,
  SEDDId
) {
  var route = "/api/meters/update/";
  let data = {
    MeterId: meterId,
    Type: type,
    Serial: serialNumber,
    Adaptation: adaptation,
    Date: dateInstalled,
    MetricSystem: metricSystem,
    MetricSystemCategory: metricSystemCategory,
    SEDDId: SEDDId,
  };
  return putAsync(route, data);
}

export async function getMetricSystems() {
  var route = "/api/meters/metricsystems/";
  return getAsync(route);
}
