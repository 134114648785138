import React, { useState } from "react";

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  Button,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { CommonLoading } from "react-loadingg";
import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

import endpoint from "./config.js";

class CommentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      step: 0,
      comments: [],
      showModal: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      const result1 = await axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/comments/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.setState({
            comments: res.data.comments,
            loading: false,
          });
        });
    }
  }

  checkComment = (commentId) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/checkcomment/" +
          commentId +
          "/",
        {
          method: "PUT",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  deleteComment = (commentId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/deletecomment/" +
            commentId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Ιστορικό Σχολίων
                            </span>
                            {/* <CardBody>
                                                    <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Α/Α: </span> &nbsp; 28/2021</Row>
                                                    <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Ημερομηνία Έναρξης: </span> &nbsp; Παρασκευή, 05 Φεβ 2021</Row>
                                                    <Row className="align-items-center"><span style={{fontWeight: "bold"}}>Κωδικός ΗΚΑΣΠ: </span> &nbsp; 6c143f6b-52ff-4615-b08f-613b75c8939f </Row>
                                                </CardBody> */}
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt--4" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardBody>
                    <div>
                      {this.state.comments.map((dynamicData) => (
                        <>
                          <Col xs="8">
                            <h3 className="mb-0">{dynamicData.step.Name}</h3>
                            <p></p>
                          </Col>
                          <Table
                            className="align-items-center table-flush"
                            responsive
                          >
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Σχολιο</th>
                                <th scope="col">Ημερομηνια Δημιουργιας</th>
                                <th scope="col">Πεδιο Βηματος</th>
                                <th scope="col">Ενεργειες</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dynamicData.actions.map((dynamicAction) => (
                                <>
                                  {dynamicAction.comments.map(
                                    (dynamicComment) => (
                                      <tr>
                                        <td>{dynamicComment.Comment}</td>
                                        <td>
                                          {Moment(dynamicComment.CreatedAt)
                                            .locale("el")
                                            .format("dddd, DD MMM YYYY")}
                                        </td>
                                        <td>{dynamicAction.Name}</td>
                                        <td>
                                          <Button
                                            color="success"
                                            onClick={() => {
                                              this.checkComment(
                                                dynamicComment.Id
                                              );
                                            }}
                                            size="sm"
                                            title="Επίλυση"
                                          >
                                            <i className="fas fa-check" />
                                          </Button>
                                          <Button
                                            color="danger"
                                            onClick={() => {
                                              this.deleteComment(
                                                dynamicComment.Id
                                              );
                                            }}
                                            size="sm"
                                            title="Διαγραφή"
                                          >
                                            <i className="fas fa-trash" />
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </>
                              ))}
                            </tbody>
                          </Table>
                          <hr />
                        </>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default CommentHistory;
