import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import { MDBDataTable } from "mdbreact";
import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

import axios from "axios";

class Meters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      meters: [],
      user: [],
    };
  }

  async componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_LINK + "/api/meters/all/", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        const meters2 = res.data;
        meters2.forEach(function (part, index) {
          this[index].Buttons = (
            <React.Fragment>
              <Button
                color="primary"
                href={"/admin/meter/" + this[index].Id}
                size="sm"
                title="Προβολή"
              >
                <i className="fas fa-eye" />
              </Button>
            </React.Fragment>
          );
        }, meters2);

        EVOMDBDataTableHelper.GridFilter.setStateFilters(
          this,
          "mdbdttable",
          meters2,
          ["Type", "Adaptation"]
        );

        this.state.datas = {
          columns: [
            {
              label: "Σειριακος Αριθμος ▼",
              field: "SerialNumber",
              sort: "asc",
              width: 100,
            },
            {
              label: "Τυπος ▼",
              field: "Type",
              sort: "asc",
              width: 150,
            },
            {
              label: "ΗΚΑΣΠ ▼",
              field: "Ikasp",
              sort: "asc",
              width: 150,
            },
            {
              label: "Ημ. Εγκαταστασης ▼",
              field: "DateInstalled",
              sort: "asc",
              width: 150,
            },
            {
              label: "Προσθηκη(β) / Παροχη(Π) ▼",
              field: "Adaptation",
              sort: "asc",
              width: 150,
            },
            // {
            //  label: 'Ενεργος ▼',
            //   field: 'City',
            //   sort: 'asc',
            //   width: 150
            // },
            {
              label: "Επιλογες ▼",
              field: "Buttons",
              sort: "asc",
              width: 150,
            },
          ],
          rows: res.data,
        };

        const meters = res.data;
        this.setState({ meters });
      });

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Μετρητές</h3>
                    </div>
                  </Row>

                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "Type",
                          fieldTitle: "Τυπος",
                          isIncludeEmptyItem: true,
                        },
                        {
                          fieldName: "Adaptation",
                          fieldTitle: "Προσθηκη(β) / Παροχη(Π) ",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Σειριακος Αριθμος</th>
                      <th scope="col">Τυπος</th>
                      <th scope="col">ΗΚΑΣΠ</th>
                      <th scope="col">Ημ. Εγκαταστασης</th>
                      <th scope="col">Προσθηκη(β) / Παροχη(Π)</th>
                      <th scope="col">Ενεργος</th>
                      <th scope="col">Επιλογες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.meters.map((dynamicData) =>
                        <tr> 
                            <th scope="row">{dynamicData.SerialNumber}</th>
                            <td>{dynamicData.Type}</td>
                            <td>{dynamicData.Ikasp}</td>
                            <td>{dynamicData.DateInstalled}</td>
                            <td>{dynamicData.Adaptation}</td>
                            {dynamicData.Active ?
                                <>
                                    <td>Ναι</td>
                                </> 
                                : <> <td>Όχι</td> </>}
                                
                            <td>
                                <Button
                                    color="primary"
                                    href={"/admin/meter/" + dynamicData.Id}
                                    size="sm"
                                    title="Προβολή"
                                >
                                    <i className="fas fa-eye" />
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    title="Διαγραφή"
                                >
                                    <i className="fas fa-trash" />
                                </Button>
                              </td>
                        </tr>
                      ) 
                    }
                  </tbody>
                </Table> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Meters;
