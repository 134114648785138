import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import Login from "views/examples/Login.js";
import { Base } from "contexts/BaseContext";
import GlobalParametersHelper from "../common/GlobalParametersHelper";
async function verifyToken(token) {
    //here
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + GlobalParametersHelper.Token.get(),
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })

        .then(
            (result) => {
                if (result.status != 200) {
                    GlobalParametersHelper.Token.remove();
                    window.location.replace("/auth/login");
                }
                else {
                    GlobalParametersHelper.Token.set(token);
                }
            },
            (error) => {
                GlobalParametersHelper.Token.remove();                
                window.location.replace("/auth/login");
            }
        )
};

function App() {
    const baseContext = useContext(Base);             

    if (!GlobalParametersHelper.Token.get()) {
        return <Login/>
    }
    else {
        verifyToken(GlobalParametersHelper.Token.get());

        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/admin" render={props => <AdminLayout {...props} />} />
                    <Route path="/auth" render={props => <Login {...props} />} />
                    <Redirect from="/" to="/admin/index" />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
