import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import InstanceHeader from "components/Headers/InstanceHeader.js";

import { CommonLoading } from "react-loadingg";

// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Moment from "moment";
import "moment/locale/el";
// import 'leaflet/dist/leaflet.css';

import axios from "axios";
import Modal from "react-modal";

import endpoint from "./config.js";

// import L from "leaflet";

// const customMarker = new L.icon({
//   iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
//   iconSize: [25, 41],
//   iconAnchor: [10, 41],
//   popupAnchor: [2, -40]
// });

class OldInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      meter: [],
      supplier: [],
      property: [],
      price: [],
      coords: [],
      instance: [],
      ikasp: [],
      excel: [],
      consumer: [],
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/excelinstance/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              excel: result.excel,
              instance: result.instance,
              consumer: result.consumer,
              price: result.price,
              coords: result.coords,
              ikasp: result.ikasp,
              property: result.property,
              meter: result.meter,
              supplier: result.supplier,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
              loading: false,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Αίτησης Excel</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.instance.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Έναρξης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={Moment(
                                  this.state.instance.StartedAt
                                )
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός ΗΚΑΣΠ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.ikasp.Ikasp}
                                placeholder="Αριθμός ΗΚΑΣΠ"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αίτησης
                              </label>
                              {this.state.instance.Status == 1 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="1. Αίτηση Σύνδεσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 2 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="2. Πρόσθετα Δικαιολογητικά"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 3 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="3. Τεχνική Αξιολόγηση Ακινήτου"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 4 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="4. Σύνταξη Προσφοράς"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 5 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="5. Υπογραφή Σύμβασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 6 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="6. Μηχανική Ολοκλήρωση και Μελέτη εσωτερικής εγκατάστασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 7 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="7. Σύμβαση με Προμηθευτή"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 8 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="8. Αίτημα Αυτοψίας"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 9 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="9. Ενεργοποίηση εσωτερικής εγκατάστασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 10 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="10. Φύλλο Καύσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.instance.Status == 11 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="11. Έκδοση άδειας χρήσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Τέλη σύνδεσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.price.Price}
                                placeholder="Τέλη σύνδεσης"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updatePhone(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Σημαντικες Ημερομηνιες και Καταστασεις
                        </h6>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Υπογραφής Σύμβασης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.excel.DateProposal}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Μηχανική Ολοκλήρωση
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.excel.DateMC}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Ενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.excel.DateActivation}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Παραλαβής Φύλλου Καύσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.excel.DateCS}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Ολοκλήρωσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.excel.DateCompletion}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολιο Αιτησης
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={this.state.instance.Comments}
                              placeholder="Σχόλιο"
                              type="textarea"
                              // disabled={true}
                              // onChange={evt => this.updatePhone(evt)}
                            />
                            <Button
                              color="primary"
                              href="#pablo"
                              onClick={() => {
                                this.saveChanges(this.state.details.Id);
                              }}
                              size="sm"
                            >
                              Αποθήκευση σχολίου
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Στοιχεια συμβεβλημενων προσωπων
                            </h6>
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ||
                            this.state.user.PersonRole == "Finance" ? (
                              <>
                                <Button
                                  color="primary"
                                  href={
                                    "/admin/consumer/" + this.state.consumer.Id
                                  }
                                  size="sm"
                                >
                                  Στοιχεία Καταναλωτή
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ? (
                              <>
                                {this.state.supplier.exists ? (
                                  <>
                                    <Button
                                      color="primary"
                                      href={
                                        "/admin/supplier/" +
                                        this.state.supplier.data.Id
                                      }
                                      size="sm"
                                    >
                                      Στοιχεία Προμηθευτή
                                    </Button>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                {this.state.property.exists ? (
                                  <>
                                    <Button
                                      color="primary"
                                      href={
                                        "/admin/property/" +
                                        this.state.property.data.Id
                                      }
                                      size="sm"
                                    >
                                      Στοιχεία Ακινήτου
                                    </Button>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                <Button
                                  color="primary"
                                  href={
                                    "/admin/meter/" + this.state.meter.data.Id
                                  }
                                  size="sm"
                                >
                                  Στοιχεία Μετρητή
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' || this.state.user.PersonRole == 'Sales' || this.state.user.PersonRole == 'Marketing'? 
              <>
                <Row className="mt-5">
                  <Col className="mb-5 mb-xl-0" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                              <h3 className="mb-0">Έγγραφα αίτησης</h3>
                          </Col>
                        </Row>
                        
                      </CardHeader>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                              <th scope="col">Ονομα αρχειου</th>
                              <th scope="col">Ημερομηνια Αναρτησης</th>
                              <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          this.state.files.map((dynamicFiles) => 
                            <tr>
                                <td>{dynamicFiles.FileName}</td>
                                <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                <td>
                                  <Button
                                    color="primary"
                                    href={dynamicFiles.Link}
                                    size="sm"
                                    title="Προβολή"
                                  >
                                    <i className="fas fa-eye" />
                                  </Button>
                                  {this.state.user.PersonRole == 'Admin' ? 
                                    <>
                                      <Button
                                        color="danger"
                                        // href={dynamicFiles.Link}
                                        size="sm"
                                        title="Διαγραφή"
                                      >
                                        <i className="fas fa-trash" />
                                      </Button>
                                    </> : <> </> } 
                                </td>
                            </tr> 
                          ) 
                        }
                        </tbody>
                      </Table>
                    </Card>
                  </Col>

                  <Col className="mb-5 mb-xl-0" xl="6">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Στίγμα αίτησης</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                              <MapContainer center={[40.891360000000084, 22.914040000000057]} zoom={13} scrollWheelZoom={false} style={{ height: '23vh', width: '50wh' }}>
                                <TileLayer
                                  attribution='&copy; EvolutionProjects+'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={[40.891360000000084, 22.914040000000057]} icon={customMarker}>
                                  <Popup>
                                    A/A : {this.state.details.SerialNumber} <br /> HKAΣΠ : {this.state.ikasp.Ikasp}
                                  </Popup>
                                </Marker>
                              </MapContainer>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
              </> : <> </>} */}
          </Container>
        </>
      );
    }
  }
}

export default OldInstance;
