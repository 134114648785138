import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from "axios";

import endpoint from "./config.js";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      roles: [],
      pass1: "",
      pass2: "",
      error: 0,
      valid: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            user: result,
          });
        });
    }

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            roles: result,
          });
        });
    }
  }

  changePassword = (consumerId) => {
    if (this.state.pass1 == this.state.pass2) {
      if (localStorage.getItem("token")) {
        fetch(process.env.REACT_APP_API_LINK + "/api/auth/changepassword/", {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            password: this.state.pass1,
          }),
        }).then((res) => {
          this.setState({
            error: 2,
          });

          setTimeout(function () {
            window.location.replace("/consumer/index/");
          }, 2500);
        });
      }
    } else {
      this.setState({
        error: 1,
      });
    }
  };

  getPass1(evt) {
    const lengthSatisfied = evt.target.value.length >= 8;
    const uppercaseSatisfied = /[a-z]/.test(evt.target.value);
    const lowercaseSatisfied = /[A-Z]/.test(evt.target.value);
    const numbersSatisfied = /\d/.test(evt.target.value);

    this.setState({
      pass1: evt.target.value,
      valid:
        lengthSatisfied &&
        uppercaseSatisfied &&
        lowercaseSatisfied &&
        numbersSatisfied,
    });
  }

  getPass2(evt) {
    this.setState({
      pass2: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Κωδικός πρόσβασης</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={() => {
                          this.changePassword();
                        }}
                        size="sm"
                        disabled={!this.state.valid}
                      >
                        Αποθήκευση αλλαγών
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Αλλαγη κωδικου προσβασης
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="4"></Col>
                        <Col md="4" style={{ alignTextAlign: "center" }}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Νέος κωδικός πρόσβασης
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-address"
                              type="password"
                              onChange={(evt) => this.getPass1(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4"></Col>
                        <Col md="4" style={{ alignTextAlign: "center" }}>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Επιβεβαίωση νέου κωδικού πρόσβασης
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="input-address"
                              type="password"
                              onChange={(evt) => this.getPass2(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div>
                        <div
                          style={{
                            width: "fit-content",
                            display: this.state.valid ? "none" : "block",
                            margin: "auto",
                          }}
                        >
                          <h3>Ο κωδικός πρέπει να περιέχει:</h3>
                          <p id="letter" className="invalid">
                            <b>Πεζούς</b> χαρακτήρες
                          </p>
                          <p id="capital" className="invalid">
                            <b>Κεφαλαίους</b> χαρακτήρες
                          </p>
                          <p id="number" className="invalid">
                            Τουλάχιστον 1 <b>αριθμό</b>
                          </p>
                          <p id="length" className="invalid">
                            Τουλάχιστον <b>8 ψηφία</b>
                          </p>
                        </div>
                      </div>
                      {this.state.error == 1 ? (
                        <Row>
                          <Col md="4" style={{ alignTextAlign: "center" }}>
                            <p style={{ color: "red" }}>
                              Οι κωδικοί που εισάγετε δεν ταιριάζουν.
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        <> </>
                      )}
                      {this.state.error == 2 ? (
                        <Row>
                          <Col md="4" style={{ alignTextAlign: "center" }}>
                            <p style={{ color: "green" }}>
                              Ο κωδικός σας άλλαξε επιτυχώς.
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        <> </>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Settings;
