import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Table,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

class Testing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tests: [],
      date: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/testing/tests/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            isLoaded: true,
            tests: result,
          });
        });
    }
  }

  sendDate = (stepStatus) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/testing/date/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Date: this.state.date,
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateDate(evt) {
    this.setState({
      date: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Τest Ημερομηνίας</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ημερομηνία
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-date"
                              type="date"
                              onChange={(evt) => this.updateDate(evt)}
                            />
                          </FormGroup>
                          <Button
                            color="success"
                            onClick={(evt) => {
                              this.sendDate(evt);
                            }}
                            size="sm"
                          >
                            Αποστολή
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Ημερομηνια</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tests.map((dynamicData) => (
                        <tr>
                          <th scope="row"> {dynamicData.Id}</th>
                          <td>
                            <Input
                              className="form-control-alternative"
                              id="input-date"
                              type="date"
                              defaultValue={Moment(dynamicData.Date)
                                .locale("el")
                                .format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Testing;
