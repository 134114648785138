import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import "../../assets/css/datatables.css";

import { MDBDataTable } from "mdbreact";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import { RedirectHelper } from "../../common/redirectHelper";
import EvoBaseComponent from "../../components/Abstract/EvoBaseComponent";

class SupplierData extends EvoBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      details: [],
      meter: [],
      sidata: [],
      dates: [],
      dateSuccession: "",
      name: "",
      lastname: "",
      address: "",
      phone: "",
      city: "",
      mpower: "",
      mcount: "",
      afm: "",
      suppliersHistoryGridDatasource: {
        columns: this.historyColumns,
        rows: [],
      },
      ...this.state,
    };

    this.changeName = this.changeName.bind(this);
    this.changeLastName = this.changeLastName.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changePower = this.changePower.bind(this);
    this.changeCount = this.changeCount.bind(this);
    this.changeSucc = this.changeSucc.bind(this);
    this.changeAFM = this.changeAFM.bind(this);
  }

  componentDidMount() {
    super.componentDidMount(this.initializeComponent);
  }

  historyColumns = [
    {
      label: "Ονομα Καταναλωτη ▼",
      field: "Name",
      sort: "asc",
      width: 100,
    },
    {
      label: "Επίθετο Καταναλωτή ▼",
      field: "LastName",
      sort: "asc",
      width: 100,
    },
    {
      label: "Τηλέφωνο Καταναλωτή ▼",
      field: "phoneNumber",
      sort: "asc",
      width: 150,
    },
    {
      label: "Διεύθυνση ▼",
      field: "Address",
      sort: "asc",
      width: 150,
    },
    {
      label: "Πολη ▼",
      field: "City",
      sort: "asc",
      width: 150,
    },
    {
      label: "Δυναμικοτητα Μετρητη ▼",
      field: "MeterPower",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ημ. Διακοπης ▼",
      field: "StopDate",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ενδειξη Διακοπης ▼",
      field: "StopMeterCount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ημ. Επανενεργοποιησης ▼",
      field: "RestartDate",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ενδειξη Επανενεργοποιησης ▼",
      field: "RestartMeterCount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ημ. Παυσης Εκπροσωπησης ▼",
      field: "StopRepresentDate",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ενδειξη Παυσης Εκπροσωπησης ▼",
      field: "StopRepresentMeterCount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Ημ. Τροποποιησης ▼",
      field: "DateRemoved",
      sort: "asc",
      width: 150,
    },
  ];

  initializeComponent = async () => {
    const profileId = this.props.match.params.id;
    let suppliersResult = await ApiCallerHelper.Suppliers.getSuppliersProfile(
      profileId
    );
    let suppliersResultData = suppliersResult.data;

    let suppliersHistoryResult =
      await ApiCallerHelper.Suppliers.getSuppliersHistory(profileId);

    //Calculate date
    function _getDateFromString(dateValue) {
      try {
        if (!dateValue) {
          return null;
        }
        let dateParts = dateValue.split("/");
        return dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
      } catch (exception) {
        console.error(exception);
        return null;
      }
    }
    let dateSuccession = _getDateFromString(
      suppliersResult.data.dates.dateSuccession
    );
    let stopDate = _getDateFromString(suppliersResultData.sidata.stopDate);
    let restartDate = _getDateFromString(
      suppliersResultData.sidata.restartDate
    );
    let stopRepresentDate = _getDateFromString(
      suppliersResultData.sidata.stopRepresentDate
    );

    this.setState({
      details: suppliersResultData.details,
      meter: suppliersResultData.meter,
      dates: suppliersResultData.dates,
      name: suppliersResultData.sidata.name,
      lastname: suppliersResultData.sidata.lastname,
      phone: suppliersResultData.sidata.phone,
      address: suppliersResultData.sidata.address,
      city: suppliersResultData.sidata.city,
      mpower: suppliersResultData.sidata.mpower,
      mcount: suppliersResultData.sidata.mcount,

      stopDate: stopDate,
      stopMeterCount: suppliersResultData.sidata.stopMeterCount,
      restartDate: restartDate,
      restartMeterCount: suppliersResultData.sidata.restartMeterCount,

      stopRepresentDate: stopRepresentDate,
      stopRepresentMeterCount:
        suppliersResultData.sidata.stopRepresentMeterCount,

      afm: suppliersResultData.sidata.afm
        ? suppliersResultData.sidata.afm
        : "Δεν έχει οριστεί ακόμη",
      dateSuccession: dateSuccession ?? this.state.dateSuccession,
      suppliersHistoryGridDatasource: {
        columns: this.historyColumns,
        rows: suppliersHistoryResult.data,
      },
    });
  };

  saveData = async () => {
    await ApiCallerHelper.Suppliers.postSuppliersCrmSaveData({
      serialNumber: this.state.details.SerialNumber,
      name: this.state.name,
      lastName: this.state.lastname,
      phoneNumber: this.state.phone,
      address: this.state.address,
      city: this.state.city,
      meterPower: this.state.mpower,
      meterCount: this.state.mcount,
      dateSucc: this.state.dateSuccession,
      meterType: this.state.meter.Type,
      afm: this.state.afm,
      consType: this.state.details.constype,
      stopDate: this.state.stopDate,
      stopMeterCount: this.state.stopMeterCount,
      restartDate: this.state.restartDate,
      restartMeterCount: this.state.restartMeterCount,
      stopRepresentDate: this.state.stopRepresentDate,
      stopRepresentMeterCount: this.state.stopRepresentMeterCount,
    });
    // RedirectHelper.RedirectTo.reloadPageFromCache();
  };

  changeName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  changeLastName(evt) {
    this.setState({
      lastname: evt.target.value,
    });
  }

  changePhone(evt) {
    this.setState({
      phone: evt.target.value,
    });
  }

  changeAddress(evt) {
    this.setState({
      address: evt.target.value,
    });
  }

  changeCity(evt) {
    this.setState({
      city: evt.target.value,
    });
  }

  changePower(evt) {
    this.setState({
      mpower: evt.target.value,
    });
  }

  changeCount(evt) {
    this.setState({
      mcount: evt.target.value,
    });
  }

  changeSucc(evt) {
    this.setState({
      dateSuccession: evt.target.value,
    });
  }

  changeAFM(evt) {
    this.setState({
      afm: evt.target.value,
    });
  }

  changeData = {
    StopDate: (evt) => {
      this.setState({
        stopDate: evt.target.value,
      });
    },
    StopMeterCount: (evt) => {
      this.setState({
        stopMeterCount: evt.target.value,
      });
    },
    RestartDate: (evt) => {
      this.setState({
        restartDate: evt.target.value,
      });
    },
    RestartMeterCount: (evt) => {
      this.setState({
        restartMeterCount: evt.target.value,
      });
    },
    StopRepresentDate: (evt) => {
      this.setState({
        stopRepresentDate: evt.target.value,
      });
    },
    StopRepresentMeterCount: (evt) => {
      this.setState({
        stopRepresentMeterCount: evt.target.value,
      });
    },
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Στοιχεία Αιτήματος Εκπροσώπησης
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        <Button
                          color="success"
                          size="sm"
                          onClick={() => {
                            this.saveData();
                          }}
                        >
                          Αποθήκευση αλλαγών
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός ΗΚΑΣΠ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.Ikasp}
                                placeholder="Αριθμός ΗΚΑΣΠ"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Υποβολής Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                // defaultValue={Moment(this.state.details.dateCreated).locale('el').format('dddd, DD MMM YYYY')}
                                defaultValue={this.state.dates.dateSubmit}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Έγκρισης Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                // defaultValue={Moment(this.state.details.dateCreated).locale('el').format('dddd, DD MMM YYYY')}
                                defaultValue={this.state.dates.dateApproved}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                disabled="true"
                              />
                            </FormGroup>
                          </Col> */}
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Ενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.dates.activation}
                                // defaultValue={Moment(this.state.details.activation).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-username"
                                placeholder="Ημερομηνία Ενεργοποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Αποδοχής
                              </label>
                              <Input
                                className="form-control-alternative"
                                // defaultValue={Moment(this.state.details.dateCreated).locale('el').format('dddd, DD MMM YYYY')}
                                defaultValue={this.state.dates.dateApproved}
                                id="input-email"
                                placeholder="Ημερομηνία Αποδοχής"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col md="3" >
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Διακοπής
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.dates.activation}
                                // defaultValue={Moment(this.state.details.activation).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-username"
                                placeholder="Ημερομηνία Ενεργοποίησης"
                                type="text"
                                disabled="true"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3" >
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Επανενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.dates.activation}
                                // defaultValue={Moment(this.state.details.activation).locale('el').format('dddd, DD MMM YYYY')}
                                id="input-username"
                                placeholder="Ημερομηνία Ενεργοποίησης"
                                type="text"
                                disabled="true"
                              />
                            </FormGroup>
                          </Col> */}
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Καταναλωτη
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Όνομα Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.name}
                                  placeholder="Όνομα Καταναλωτή"
                                  type="text"
                                  onChange={this.changeName}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Επίθετο Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.lastname}
                                  placeholder="Επίθετο Καταναλωτή"
                                  type="text"
                                  onChange={this.changeLastName}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  ΑΦΜ Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.afm}
                                  placeholder="ΑΦΜ Καταναλωτή"
                                  type="text"
                                  onChange={this.changeAFM}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τηλέφωνο Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.phone}
                                  placeholder="Τηλέφωνο Καταναλωτή"
                                  type="text"
                                  onChange={this.changePhone}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Διεύθυνση
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.address}
                                  placeholder="Διεύθυνση"
                                  type="text"
                                  onChange={this.changeAddress}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Πόλη
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.city}
                                  placeholder="Πόλη"
                                  type="text"
                                  onChange={this.changeCity}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Κατηγορία Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.details.constype}
                                  placeholder="Κατηγορία"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <hr></hr>
                          <Row>
                            <Col md="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Ημερομηνία Διαδοχής
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.dateSuccession}
                                  // defaultValue={(new Date(this.state.dateSuccession.replaceAll('/', '-'))).toLocaleDateString('en-CA')}
                                  id="input-username"
                                  placeholder="Ημερομηνία Διαδοχής"
                                  type="date"
                                  onChange={this.changeSucc}
                                  // disabled="true"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Ένδειξη Μετρητή (Διαδοχή)
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.mcount}
                                  placeholder="Ένδειξη Μετρητή (Διαδοχή)"
                                  type="text"
                                  onChange={this.changeCount}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ημερομηνία διακοπής
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.stopDate}
                                  placeholder="Ημερομηνία διακοπής"
                                  type="date"
                                  onChange={this.changeData.StopDate}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ένδειξη μετρητή (διακοπή)
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.stopMeterCount}
                                  placeholder=" Ένδειξη μετρητή (διακοπή)"
                                  type="text"
                                  onChange={this.changeData.StopMeterCount}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ημερομηνία επανενεργοποίησης
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.restartDate}
                                  placeholder="Ημερομηνία επανενεργοποίησης"
                                  type="date"
                                  onChange={this.changeData.RestartDate}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ένδειξη μετρητή (επανενεργοποίησης)
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.restartMeterCount}
                                  placeholder="Ένδειξη μετρητή (επανενεργοποίησης)"
                                  type="text"
                                  onChange={this.changeData.RestartMeterCount}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ημερομηνία Παυσης Εκπροσώπησης
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.stopRepresentDate}
                                  placeholder="Ημερομηνία Παυσης Εκπροσώπησης"
                                  type="date"
                                  onChange={this.changeData.StopRepresentDate}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Ένδειξη μετρητή (Παυσης Εκπροσώπησης)
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={
                                    this.state.stopRepresentMeterCount
                                  }
                                  placeholder="Ένδειξη μετρητή (Παυσης Εκπροσώπησης)"
                                  type="text"
                                  onChange={
                                    this.changeData.StopRepresentMeterCount
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Μετρητη
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τύπος Μετρητή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.meter.Type}
                                  placeholder="Τύπος Μετρητή"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Σειριακός Αριθμός
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.meter.SerialNumber}
                                  placeholder="Σειριακός Αριθμός"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τιμολογούμενη Δυναμικότητα Μετρητή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue={this.state.mpower}
                                  placeholder="Δυναμικότητα Μετρητή"
                                  type="text"
                                  onChange={this.changePower}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr className="my-4" />
                          <Row>
                            <Col lg="4"> </Col>
                            <Col lg="4">
                              <p style={{ width: "60%", textAlign: "center" }}>
                                Υπόμνημα Μετρητή-ΔΩΔ
                              </p>
                              <Table
                                className="align-items-center table-flush"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Μετρητης</th>
                                    <th scope="col">KWh</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">G4 (Οικιακοί)</th>
                                    <td>53.21</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G4 (Εμπορικοί)</th>
                                    <td>68.4</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G6 (Οικιακοί, Εμπορικοί)</th>
                                    <td>114</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G10</th>
                                    <td>182.4</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G16</th>
                                    <td>285</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G25</th>
                                    <td>456</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Row>
                <br />
                <br />
              </Row>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Ιστορικό Τροποποιήσεων</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardHeader>
                    <MDBDataTable
                      bordered
                      exportToCSV
                      hover
                      //strip
                      responsive
                      data={this.state.suppliersHistoryGridDatasource}
                      id="mdbdttable"
                      entriesOptions={[10, 25, 50, 100, 500, 1000]}
                      entries={10}
                      //noDataComponent="Δεν υπάρχουν δεδομένα"
                      searchLabel="Αναζήτηση"
                      //sNext="Suivant"
                      paginationLabel={["Προηγούμενο", "Επόμενο"]}
                      infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                      entriesLabel={"Εμφάνιση Ιστορικού ανά: "}
                    />
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default SupplierData;
