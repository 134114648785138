import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import { MDBDataTable } from "mdbreact";

class SupplierApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      const handle = this.props.match.params.id;
      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/suppliers/instanceapps/" +
            handle +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances2 = res.data;
          instances2.forEach(function (part, index) {
            this[index].StateApp =
              this[index].Status == "Pending" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i class="fas fa-clock" style={{ fontSize: "25px" }}></i>
                </div>
              ) : this[index].Status == "Declined" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i
                    class="far fa-window-close"
                    style={{ fontSize: "25px" }}
                  ></i>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i class="fas fa-check" style={{ fontSize: "25px" }}></i>
                </div>
              );
          }, instances2);
          this.datas = {
            columns: [
              {
                label: "Αριθμος Αιτησης ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 100,
              },
              {
                label: "ΗΚΑΣΠ ▼",
                field: "Ikasp",
                sort: "asc",
                width: 150,
              },
              {
                label: "Τοποθεσια ▼",
                field: "Location",
                sort: "asc",
                width: 150,
              },
              {
                label: "Κατασταση ▼",
                field: "StateApp",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Aιτήματα Αίτησης</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>

                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SupplierApplications;
