import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

import axios from "axios";

class IkaspCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      codes: [],
      user: [],
    };
  }

  async componentDidMount() {
    fetch(process.env.REACT_APP_API_LINK + "/api/ikasp/areacodes/0/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            codes: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <div className="col">
                        <h3 className="mb-0">Κωδικοί ΗΚΑΣΠ ανά Νομό</h3>
                      </div>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == "Admin" ? (
                        <>
                          <Button
                            color="success"
                            href="/admin/newareacode"
                            size="sm"
                          >
                            Προσθήκη νέου κωδικού
                          </Button>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Περιοχη</th>
                      <th scope="col">Κωδικος Νομου</th>
                      <th scope="col">Περιφερεια</th>
                      <th scope="col">Επιλογες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.codes.map((dynamicData) => (
                      <tr>
                        <th scope="row"> {dynamicData.Area}</th>
                        <td>{dynamicData.Code}</td>
                        <td>{dynamicData.State}</td>
                        <td>
                          {this.state.user.PersonRole == "Admin" ? (
                            <>
                              <Button
                                color="primary"
                                size="sm"
                                title="Επεξεργασία"
                              >
                                <i className="fas fa-pencil-alt" />
                              </Button>
                              <Button color="danger" size="sm" title="Διαγραφή">
                                <i className="fas fa-trash" />
                              </Button>
                            </>
                          ) : (
                            <> </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IkaspCodes;
