import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class Workflows extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      workflows: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflows/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              workflows: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πρότυπες αιτήσεις</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        href="#pablo"
                        href="/admin/newworkflow"
                        size="sm"
                      >
                        Προσθήκη νέου προτύπου
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Ονομα</th>
                      <th scope="col">Προτεραιοτητα</th>
                      <th scope="col">Ημερομηνια Εναρξης</th>
                      <th scope="col">Ημερομηνια Ληξης</th>
                      <th scope="col">Διαρκεια σε ημερες</th>
                      <th scope="col">Ενεργειες</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.workflows.map((dynamicData) => (
                      <tr style={{ textAlign: "center" }}>
                        <th scope="row"> {dynamicData.Name}</th>
                        <td>{dynamicData.Priority}</td>
                        <td>
                          {Moment(dynamicData.StartingDate)
                            .locale("el")
                            .format("dddd, DD MMM YYYY")}
                        </td>
                        <td>
                          {Moment(dynamicData.EndingDate)
                            .locale("el")
                            .format("dddd, DD MMM YYYY")}
                        </td>
                        <td>{dynamicData.Duration}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            color="primary"
                            href={"/admin/workflow/" + dynamicData.Id}
                            size="sm"
                            title="Προβολή"
                          >
                            <i className="fas fa-eye" />
                          </Button>
                          <Button
                            color="danger"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            size="sm"
                            title="Διαγραφή"
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Workflows;
