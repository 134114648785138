import React from "react";
import axios from "axios";
import "../../assets/css/datatables.css";
import {
  Button,
  Card,
  CardHeader,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
class AutopsyInstances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      instances: [],
      user: [],
      selectedUser: "",
    };
  }

  unAssign = (serialno) => {
    if (
      window.confirm(
        "Είστε σίγουροι ότι θέλετε να αποδρομολογήσετε την συγκεκριμένη αίτηση;"
      ) == true
    ) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/unassigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            SerialNumber: serialno,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result != -1) window.location.reload();
          else
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " δεν είναι πλέον δρομολογημένη!"
            );
        });
    } else {
    }
  };

  reAssign = (instance_id, serialno) => {
    if (
      window.confirm(
        "Είστε σίγουροι ότι θέλετε να επαναδρομολογήσετε την συγκεκριμένη αίτηση;"
      ) == true
    ) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/reassigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            InstanceId: instance_id,
            SerialNumber: serialno,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result != -1) window.location.reload();
          else
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " είναι πλέον επαναδρομολογημένη!"
            );
        });
    } else {
    }
  };

  async componentDidMount() {
    await fetch(process.env.REACT_APP_API_LINK + "/api/user/all/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let temp_list = [...result];
          let options_list = [];

          temp_list.forEach((part, index) => {
            if (part.Roles && part.Roles.includes("Maintenance")) {
              options_list.push({ value: part.Id, label: part.DisplayName });
            }
          });

          this.setState({
            allUsers: options_list,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/mechanics/autopsyinstances/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const instances2 = res.data;
        var self = this;
        instances2.forEach(function (part, index) {
          const isInitialSubmission =
            this[index].Data === undefined || this[index].Data === null;
          this[index].SubmissionType = isInitialSubmission
            ? "Αρχική"
            : "Επανυποβολή";
          this[index].Buttons = (
            <React.Fragment>
              <Button
                color="success"
                href={"/admin/instanceroutinghistory/" + this[index].Id + "/"}
                size="sm"
                title="Ιστορικό Δρομολόγησης"
              >
                Ιστορικό Δρομολόγησης
              </Button>
              <Button
                color="primary"
                href={"/admin/details/" + this[index].Id}
                size="sm"
                title="Στοιχεία Αίτησης"
              >
                Στοιχεία Αίτησης
              </Button>
              {(self.state.user.Email == "g.panagopoulos@deda.gr" ||
                self.state.user.Email == "crmadmin@deda.gr") &&
              this[index].isAssigned == false ? (
                <>
                  {/* <Button
                    color="success"
                    onClick={() => {
                      self.reAssign(this[index].Id, this[index].SerialNumber);
                    }}
                    size="sm"
                    title="Επαναδρομολόγηση"
                  >
                    Επαναδρομολόγηση
                  </Button> */}
                </>
              ) : (
                <></>
              )}
              {(self.state.user.Email == "g.panagopoulos@deda.gr" ||
                self.state.user.Email == "crmadmin@deda.gr") &&
              this[index].isAssigned == true ? (
                <>
                  <Button
                    color="warning"
                    onClick={() => {
                      self.unAssign(this[index].SerialNumber);
                    }}
                    size="sm"
                    title="Ξεκλείδωμα"
                  >
                    Ξεκλείδωμα
                  </Button>
                </>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        }, instances2);
        // instances2.forEach(function (part, index) {
        //     this[index].Buttons =
        //         <React.Fragment>
        //             <Button
        //                 color="success"
        //                 // onClick={() => { self.acceptReq(this[index].ReqId) }}
        //                 href={"/admin/instanceroutinghistory/" + this[index].Id + "/"}
        //                 size="sm"
        //                 title="Ιστορικό Δρομολόγησης"
        //             >
        //                 Ιστορικό Δρομολόγησης
        //             </Button>
        //             <Button
        //                 color="primary"
        //                 href={"/admin/details/" + this[index].Id}
        //                 size="sm"
        //                 title="Στοιχεία Αίτησης"
        //             >
        //                 Στοιχεία Αίτησης
        //             </Button>
        //             {/* <Button
        //           color="danger"
        //           // onClick={() => { self.declineReq(this[index].ReqId) }}
        //           size="sm"
        //           title="Απόρριψη"
        //         >
        //           <i className="fas fa-window-close" />
        //         </Button> */}
        //         </React.Fragment>
        //     //
        // }, instances2);
        this.datas = {
          columns: [
            {
              label: "Αριθμος Αιτησης ▼",
              field: "SerialNumber",
              sort: "asc",
              width: 100,
            },
            {
              label: "Πολη ▼",
              field: "Location",
              sort: "asc",
              width: 150,
            },
            // {
            //     label: 'Ανατεθηκε σε ▼',
            //     field: 'MechanicName',
            //     sort: 'asc',
            //     width: 150
            // },
            {
              label: "Υποβολη",
              field: "SubmitDate",
              sort: "asc",
              width: 150,
            },
            {
              label: "Υποβολη ▼",
              field: "SubmissionType",
              sort: "asc",
              width: 150,
            },
            // {
            //     label: 'Τελευταια Αναθεση',
            //     field: 'ChangedDate',
            //     sort: 'asc',
            //     width: 150
            // },
            {
              label: "Ενεργειες",
              field: "Buttons",
              sort: "asc",
              width: 150,
            },
          ],
          rows: res.data,
        };
        const instances = res.data;
        this.setState({ instances });
      });
  }

  handleSelectChange(e) {
    this.setState({
      selectedUser: e.value,
    });
  }

  handleChange(e) {
    this.setState({
      instanceNumber: e.target.value,
    });
  }

  assignFunc() {
    if (this.state.selectedUser != "" && this.state.instanceNumber != "") {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/assigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            UserId: this.state.selectedUser,
            SerialNumber: this.state.instanceNumber,
            type: "Αυτοψία",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result != -1 && result != -2) window.location.reload();
            if (result == -1)
              alert(
                "Η αίτηση με αριθμό " +
                  this.state.instanceNumber +
                  " δεν βρέθηκε!"
              );
            if (result == -2)
              alert(
                "Η αίτηση με αριθμό " +
                  this.state.instanceNumber +
                  " είναι ήδη δρομολογημένη!"
              );
          },
          (error) => {
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " δεν βρέθηκε!"
            );
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        {this.state.user.Email == "g.panagopoulos@deda.gr" ||
        this.state.user.Email == "crmadmin@deda.gr" ? (
          <>
            <Container className="mt--7" fluid>
              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3>Δρομολόγηση Αιτημάτων Αυτοψίας</h3>
                        </Col>
                        <Col className="text-right" xs="4"></Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="input-instance-number"
                          >
                            Αριθμός Αίτησης
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-instance-number"
                            type="number"
                            placeholder="Αριθμός Αίτησης"
                            onChange={(evt) => this.handleChange(evt)}
                          />
                        </Col>
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="select-user"
                          >
                            Επιλογή Χρήστη
                          </label>
                          <Select
                            id="select-user"
                            options={this.state.allUsers}
                            placeholder={"Επιλέξτε Χρήστη..."}
                            onChange={(e) => {
                              this.handleSelectChange(e);
                            }}
                          />
                        </Col>
                        <Col xs="4">
                          <Button
                            color="primary"
                            size="sm"
                            style={{ padding: "10px", marginTop: "20px" }}
                            onClick={() => this.assignFunc()}
                          >
                            Ανάθεση
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
            </Container>
          </>
        ) : (
          <></>
        )}
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Αιτήματα Αυτοψίας</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default AutopsyInstances;
