import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";
import Modal from "react-modal";

import Calendar from "react-calendar";
import Select from "react-select";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import { ApiCallerHelper } from "../../api/apiCallerHelper";
import DynamicButtonsActionsHelper from "../../common/DynamicButtonsActionsHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      workflow: [],
      user: [],
      logged_user: [],
      steps: [],
      files: [],
      enabled: true,
      fileUpload: null,
      comment: "",
      buttonCondition: 0,
      buttonMessage: "",
      newSerial: "",
      alert: "",
      alertColor: "",
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    const result = await axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/instance/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          instance: res.data.instance,
          workflow: res.data.workflow,
          user: res.data.user,
          logged_user: res.data.logged_user,
          steps: res.data.stepsInfused,
          fileUpload: null,
          comment: "",
          newSerial: res.data.instance.SerialNumber.toString(),
        });
      });

    const result2 = await axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/files/status/" + handle + "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          files: res.data,
        });
      });
  }

  saveInstance = (stepStatus) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/update/" +
          this.state.instance.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify(this.state.steps[stepStatus - 1]),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  async checkAndUpdate() {
    const checkAndUpdateResult =
      await ApiCallerHelper.WorkflowInstances.checkSerialAndUpdate(
        this.state.newSerial,
        this.state.instance.Id
      );

    this.setState({
      alert: checkAndUpdateResult.Alert,
      alertColor: checkAndUpdateResult.AlertColor,
    });

    if (checkAndUpdateResult.Success) {
      setTimeout(function () {
        window.location.reload(false);
      }, 2500);
    }
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          axios
          .get(
            process.env.REACT_APP_API_LINK +
              "/api/files/status/" +
              this.state.instance.Id +
              "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.setState({
              loading: false,
              files: res.data,
            });
          });
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    formData.append("serial_number", this.state.instance.SerialNumber);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then((data) => {
      axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/files/status/" +
          this.state.instance.Id +
          "/",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          files: res.data,
        });
      });
    });
  }

  updateInputValue(evt) {
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
          }
        }
      }
    }
  }

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  updateNewSerial(evt) {
    this.setState({
      newSerial: evt.target.value,
    });
  }

  async dynamicCall(call) {
    await DynamicButtonsActionsHelper.executor(
      this,
      this.state.instance.Id,
      document,
      call
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Φυσικό Αέριο
                            </span>
                            <CardBody>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Α/Α: &nbsp;
                                </span>
                                {this.state.instance.SerialNumber}/
                                {new Date().getFullYear()}
                              </Row>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Έναρξης: &nbsp;
                                </span>
                                {Moment(this.state.instance.StartedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                              <Row className="align-items-center">
                                <br></br>
                              </Row>
                              <Row className="align-items-center">
                                <Button
                                  color="primary"
                                  href={
                                    "/admin/details/" + this.state.instance.Id
                                  }
                                  size="sm"
                                >
                                  Στοιχεία Αίτησης
                                </Button>
                              </Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-serial"
                          >
                            Αριθμός Αίτησης
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-serial"
                            placeholder="Αριθμός Αίτησης"
                            type="text"
                            defaultValue={this.state.instance.SerialNumber}
                            onChange={(evt) => this.updateNewSerial(evt)}
                          />
                          <Button
                            color="success"
                            onClick={() => {
                              this.checkAndUpdate();
                            }}
                            size="sm"
                          >
                            Έλεγχος Διαθεσιμότητας & Αλλαγή
                          </Button>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <h3 style={{ color: this.state.alertColor }}>
                          {this.state.alert}
                        </h3>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Accordion>
            {this.state.steps.map((dynamicData) => (
              <Container className="mt-2" fluid>
                <Row className="mt-5">
                  <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Accordion.Toggle
                          as={CardHeader}
                          eventKey={dynamicData.step.Status}
                        >
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">
                                {dynamicData.step.Status}.
                                {dynamicData.step.Name}
                              </h3>
                            </Col>
                            {dynamicData.step.EditPermission.includes(
                              this.state.logged_user.PersonRole
                            ) ? (
                              <Col className="text-right" xs="4">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.saveInstance(dynamicData.step.Status);
                                  }}
                                  size="sm"
                                >
                                  Αποθήκευση
                                </Button>
                              </Col>
                            ) : (
                              <> </>
                            )}
                          </Row>
                        </Accordion.Toggle>
                      </CardHeader>
                      <Accordion.Collapse eventKey={dynamicData.step.Status}>
                        <CardBody>
                          <Form
                            id={dynamicData.step.Id}
                            onSubmit={this.handleSubmit}
                          >
                            <div className="pl-lg-4">
                              <Row>
                                {this.state.enabled ===
                                  (dynamicData.step.Status ===
                                    this.state.instance.Status) &&
                                  dynamicData.actions.map((dynamicActions) => (
                                    <>
                                      {dynamicData.step.ViewPermission.includes(
                                        this.state.logged_user.PersonRole
                                      ) ? (
                                        <>
                                          <Col lg="6">
                                            <FormGroup>
                                              <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                              >
                                                {dynamicActions.action.Name}
                                              </label>
                                              {dynamicActions.action.Type ==
                                              "file" ? (
                                                <>
                                                  {dynamicData.step.EditPermission.includes(
                                                    this.state.logged_user
                                                      .PersonRole
                                                  ) ? (
                                                    <>
                                                      <Row>
                                                        <Col lg="10">
                                                          <label
                                                            className="btn btn-primary"
                                                            htmlFor={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                          >
                                                            Επιλογή αρχείου
                                                          </label>
                                                          <Input
                                                            className="form-control-alternative"
                                                            // defaultValue={ dynamicActions.data.Data }
                                                            id={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                            // placeholder={dynamicActions.action.Description}
                                                            style={{
                                                              visibility:
                                                                "hidden",
                                                            }}
                                                            type={
                                                              dynamicActions
                                                                .action.Type
                                                            }
                                                            // disabled={!this.state.enabled}
                                                            onChange={(evt) =>
                                                              this.uploadFile(
                                                                evt,
                                                                dynamicData.step
                                                                  .Id +
                                                                  "/" +
                                                                  dynamicActions
                                                                    .action.Id
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                        {/* <Col>
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    defaultValue={ dynamicActions.data.DateCreated }
                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    placeholder={dynamicActions.data.DateCreated}
                                                                                                    type='date'
                                                                                                    // disabled={!this.state.enabled}
                                                                                                    // onChange={evt => this.updateInputValue(evt)}
                                                                                                />
                                                                                                <Input
                                                                                                    className="form-control-alternative"
                                                                                                    defaultValue={ dynamicActions.data.DateCreated }
                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                    placeholder={dynamicActions.data.DateCreated}
                                                                                                    type='time'
                                                                                                    // disabled={!this.state.enabled}
                                                                                                    // onChange={evt => this.updateInputValue(evt)}
                                                                                                />
                                                                                            </Col> */}
                                                      </Row>
                                                    </>
                                                  ) : (
                                                    <> </>
                                                  )}

                                                  <Table
                                                    className="align-items-center table-flush"
                                                    responsive
                                                  >
                                                    <thead className="thead-light">
                                                      <tr>
                                                        <th scope="col">
                                                          Ονομα
                                                        </th>
                                                        <th scope="col">
                                                          Ημερομηνια Υποβολης
                                                        </th>
                                                        <th scope="col">
                                                          Ενεργειες
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.state.files.map(
                                                        (dynamicFiles) => (
                                                          <>
                                                            {dynamicActions
                                                              .action.Id ==
                                                            dynamicFiles.ActionId ? (
                                                              <>
                                                                <tr>
                                                                  <td>
                                                                    {
                                                                      dynamicFiles.FileName
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {Moment(
                                                                      dynamicFiles.UploadDate
                                                                    )
                                                                      .locale(
                                                                        "el"
                                                                      )
                                                                      .format(
                                                                        "dddd, DD MMM YYYY"
                                                                      )}
                                                                  </td>
                                                                  <td>
                                                                    <Button
                                                                      color="primary"
                                                                      onClick={() => this.viewFile(dynamicFiles.Id)}
                                                                      size="sm"
                                                                      title="Προβολή"
                                                                    >
                                                                      <i className="fas fa-eye" />
                                                                    </Button>
                                                                    <Button
                                                                      color="danger"
                                                                      onClick={() => {
                                                                        this.deleteFile(
                                                                          dynamicFiles.Id
                                                                        );
                                                                      }}
                                                                      size="sm"
                                                                      title="Διαγραφή"
                                                                    >
                                                                      <i className="fas fa-trash" />
                                                                    </Button>
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </Table>
                                                </>
                                              ) : (
                                                <>
                                                  {dynamicActions.action.Type ==
                                                  "button" ? (
                                                    <>
                                                      {dynamicData.step.EditPermission.includes(
                                                        this.state.logged_user
                                                          .PersonRole
                                                      ) ? (
                                                        <>
                                                          <Col lg="6">
                                                            <Button
                                                              color="primary"
                                                              onClick={(evt) =>
                                                                this.dynamicCall(
                                                                  dynamicActions
                                                                    .action
                                                                    .Target
                                                                )
                                                              }
                                                              size="lg"
                                                              disabled={false}
                                                            >
                                                              {
                                                                dynamicActions
                                                                  .action
                                                                  .Description
                                                              }
                                                            </Button>
                                                          </Col>
                                                          {/* <Col>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                defaultValue={ dynamicActions.data.DateCreated }
                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                placeholder={dynamicActions.data.DateCreated}
                                                                                                type='date'
                                                                                                // disabled={!this.state.enabled}
                                                                                                // onChange={evt => this.updateInputValue(evt)}
                                                                                            />
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                defaultValue={ dynamicActions.data.DateCreated }
                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                placeholder={dynamicActions.data.DateCreated}
                                                                                                type='time'
                                                                                                // disabled={!this.state.enabled}
                                                                                                // onChange={evt => this.updateInputValue(evt)}
                                                                                            />
                                                                                        </Col> */}
                                                        </>
                                                      ) : (
                                                        <> </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Input
                                                        className="form-control-alternative"
                                                        defaultValue={
                                                          dynamicActions.data
                                                            .Data
                                                        }
                                                        id={
                                                          dynamicData.step.Id +
                                                          "/" +
                                                          dynamicActions.action
                                                            .Id
                                                        }
                                                        placeholder={
                                                          dynamicActions.action
                                                            .Description
                                                        }
                                                        type={
                                                          dynamicActions.action
                                                            .Type
                                                        }
                                                        // disabled={!this.state.enabled}
                                                        onChange={(evt) =>
                                                          this.updateInputValue(
                                                            evt
                                                          )
                                                        }
                                                      />
                                                      {/* <Calendar
                                                                                    onChange={evt => this.updateInputValue(evt)}
                                                                                    value={dynamicActions.data.DateCreated}
                                                                                /> */}
                                                      {/* <Col>
                                                                                    {dynamicActions.data.DateCreated ? 
                                                                                        <Input
                                                                                            className="form-control-alternative"
                                                                                            defaultValue={Moment(dynamicActions.data.DateCreated).format('YYYY-MM-DD')}                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                            placeholder={dynamicActions.data.DateCreated}
                                                                                            type='date'
                                                                                            // disabled={!this.state.enabled}
                                                                                            onChange={evt => this.updateInputDate(evt)}
                                                                                        />
                                                                                    : 
                                                                                        <Input
                                                                                            className="form-control-alternative"
                                                                                            // defaultValue={Moment(dynamicActions.data.DateCreated).format('YYYY-MM-DD')}                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                            // placeholder={dynamicActions.data.DateCreated}
                                                                                            type='date'
                                                                                            // disabled={!this.state.enabled}
                                                                                            onChange={evt => this.updateInputDate(evt)}
                                                                                        />
                                                                                    } */}

                                                      {/* <Input
                                                                                        className="form-control-alternative"
                                                                                        defaultValue={ dynamicActions.data.DateCreated }
                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                        placeholder={dynamicActions.data.DateCreated}
                                                                                        type='time'
                                                                                        // disabled={!this.state.enabled}
                                                                                        // onChange={evt => this.updateInputValue(evt)}
                                                                                    /> */}
                                                      {/* </Col> */}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </FormGroup>
                                          </Col>
                                        </>
                                      ) : (
                                        <> </>
                                      )}
                                    </>
                                  ))}
                              </Row>
                            </div>
                          </Form>
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                  </Col>
                </Row>
              </Container>
            ))}
          </Accordion>
        </>
      );
    }
  }
}

export default Edit;
