import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import { MDBDataTable } from "mdbreact";
import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

class UnvailableApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      unavailableApplications: [],
      user: [],
      loadedApplications: false,
    };

    this.callToDelete = this.callToDelete.bind(this);
  }

  callToDelete = (appId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK + "/api/applications/" + appId + "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  async componentDidMount() {
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    if (!this.state.loadedApplications) {
      axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/applications/unavailable/-1/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const applications = res.data;
          var self = this;

          applications.forEach(function (part, index) {
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/admin/application/" + this[index].Id}
                  size="sm"
                  title="Επεξεργασία"
                >
                  <i className="fas fa-pencil-alt" />
                </Button>
                {self.state.user.PersonRole == "Admin" ||
                self.state.user.PersonRole == "Sales" ? (
                  <>
                    <Button
                      color="danger"
                      onClick={() => self.callToDelete(this[index].Id)}
                      size="sm"
                      title="Διαγραφή"
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </>
                ) : (
                  <> </>
                )}
              </React.Fragment>
            );
          }, applications);

          EVOMDBDataTableHelper.GridFilter.setStateFilters(
            this,
            "mdbdttable",
            res.data,
            ["state", "city"]
          );

          this.state.datas = {
            columns: [
              {
                label: "Περιφερεια ▼",
                field: "state",
                sort: "asc",
                width: 100,
              },
              {
                label: "Πολη ▼",
                field: "city",
                sort: "asc",
                width: 150,
              },
              //  {
              //     label: 'ΤΚ ▼',
              //     field: 'zipCode',
              //     sort: 'asc',
              //     width: 150
              //   },
              {
                label: "Διευθυνση ▼",
                field: "mainAddress",
                sort: "asc",
                width: 150,
              },
              {
                label: "Αρ. Διευθυνσης ▼",
                field: "addressNum",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ημερομηνια Υποβολης ▼",
                field: "dateCreated",
                sort: "asc",
                width: 150,
              },
              {
                label: "Επιθετο ▼",
                field: "LastName",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ενεργειες ▼",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const unavailableApplications = res.data;
          this.setState({ unavailableApplications });
          this.setState({ loadedApplications: true });
        });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        Εκδηλώσεις ενδιαφέροντος <strong>εκτός δικτύου</strong>
                      </h3>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "state",
                          fieldTitle: "Περιφερεια",
                          isIncludeEmptyItem: true,
                        },
                        {
                          fieldName: "city",
                          fieldTitle: "Πολη",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                        <th scope="col">Περιφερεια</th>
                        <th scope="col">Πολη</th>
                        <th scope="col">ΤΚ</th>
                        <th scope="col">Διευθυνση</th>
                        <th scope="col">Αρ. Διευθυνσης</th>
                        <th scope="col">Ημερομηνια Υποβολης</th>
                        <th scope="col">Επιθετο</th>
                        <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.unavailableApplications.map((dynamicData) =>
                        <tr>
                          <td>{dynamicData.state}</td>
                          <td>{dynamicData.city}</td>
                          <td>{dynamicData.zipCode}</td>
                          <td>{dynamicData.mainAddress}</td>
                          <td>{dynamicData.addressNum}</td>
                          <td>{Moment(dynamicData.dateCreated).locale('el').format('dddd, DD MMM YYYY, h:mm:ss a')}</td>
                          <td>{dynamicData.LastName}</td>
                          <td><Button
                              color="primary"
                              href={"/admin/application/" + dynamicData.Id}
                              size="sm"
                              title="Επεξεργασία"
                            >
                              <i className="fas fa-pencil-alt" />
                            </Button>
                            {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                              <> 
                                <Button
                                  color="danger"
                                  // onClick={this.callToDelete(dynamicData.Id)}
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </> : <> </>}
                            <Button
                              color="success"
                              href={"/admin/application/" + dynamicData.Id}
                              size="sm"
                              title="Τεχνικό Δελτίο"
                            >
                              <i className="fas fa-file-alt" />
                            </Button></td>
                        </tr>
                      ) 
                    }
                  </tbody>
                </Table> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default UnvailableApplications;
