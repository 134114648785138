import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from "axios";

import endpoint from "./config.js";

import Select from "react-select";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      role: "",
      name: "",
      email: "",
      userRole: "",
      // user: [],
      defaultValues: [],
      locations: [],
      location: "",
      userId: "",
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    this.state.userId = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/user/users/" + handle + "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              defaultValues: result,
              user: result,
              name: result.DisplayName,
              email: result.Email,
              role: result.Roles,
              userRole: result.Roles[0],
              LocationId: result.LocationId,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      fetch(process.env.REACT_APP_API_LINK + "/api/user/locations/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              locations: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  resetPassword = (userId) => {
    if (localStorage.getItem("token")) {
      axios
        .put(
          process.env.REACT_APP_API_LINK +
            "/api/auth/resetpassword/" +
            userId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {});
    }
  };

  updateName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  updateRole(evt) {
    this.setState({
      role: evt.target.value,
      userRole: evt.target.value,
    });
  }

  updateLocation(evt) {
    this.setState({
      LocationId: evt.target.value,
    });
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  saveChanges = () => {
    fetch(
      process.env.REACT_APP_API_LINK +
        "/api/user/update/" +
        this.state.userId +
        "/",
      {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
        body: JSON.stringify({
          DisplayName: this.state.name,
          Email: this.state.email,
          Roles: this.state.userRole,
          City: this.state.LocationId,
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    // if (localStorage.getItem('token')) {
    //   axios.put(process.env.REACT_APP_API_LINK + '/api/user/update/', {
    //     data: { 'DisplayName': this.state.name, 'Email': this.state.email, 'Roles': this.state.role, 'City': this.state.location },
    //     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    //   })
    //     .then(res => {
    //
    //     })
    // }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Πληροφορίες χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == "Admin" ? (
                        <>
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={() => {
                              this.saveChanges();
                            }}
                            size="sm"
                          >
                            Αποθήκευση αλλαγών
                          </Button>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ονοματεπώνυμο
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.name}
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={(evt) => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.email}
                              id="input-email"
                              placeholder=""
                              type="email"
                              onChange={(evt) => this.updateEmail(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ρόλος
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={this.state.roles}
                              id="input-first-name"
                              type="select"
                              onChange={(evt) => this.updateRole(evt)}
                            >
                              {this.state.roles.map((dynamicRoles) =>
                                this.state.userRole == dynamicRoles.Name ? (
                                  <option selected>{dynamicRoles.Name}</option>
                                ) : (
                                  <>
                                    <option>{dynamicRoles.Name}</option>
                                  </>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Πόλη
                            </label>
                            <Input
                              className="form-control-alternative"
                              // defaultValue={this.state.LocationId}
                              id="input-first-name"
                              type="select"
                              onChange={(evt) => this.updateLocation(evt)}
                            >
                              {this.state.locations.map((dynamicLocations) =>
                                this.state.LocationId == dynamicLocations.Id ? (
                                  <option value={dynamicLocations.Id} selected>
                                    {dynamicLocations.City}
                                  </option>
                                ) : (
                                  <>
                                    <option value={dynamicLocations.Id}>
                                      {dynamicLocations.City}
                                    </option>
                                  </>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {this.state.user.PersonRole == "Admin" ? (
                      <>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">
                          Επαναφορα κωδικου προσβασης
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col md="4" style={{ alignTextAlign: "center" }}>
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => {
                                  this.resetPassword(this.state.user.Id);
                                }}
                                size="sm"
                              >
                                Επαναφορά κωδικού
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default User;
