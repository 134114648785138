import { getBoolean } from "./utils";

/* 
    Contains global parameters settings for web site
*/
const GlobalParametersHelper = {
  //constants default breakpoints
  BreakPoints: {
    md_minWidth: 768,
    sm_maxWidth: 767,
    lg_maxWidth: 767,
    custom_md_1_maxWidth: 992,
  },
  getResponsiveBreakPointWidth: () => {
    return 992;
  },
  getIsDefault: () => {
    const isDemo = getBoolean(process.env.REACT_APP_ISDEMO);
    return false;
  },
  Token: {
    //app validation token
    _TOKEN_KEY: "token",
    get: () => {
      const token = localStorage.getItem(
        GlobalParametersHelper.Token._TOKEN_KEY
      );
      return token;
    },
    set: (value) => {
      localStorage.setItem(GlobalParametersHelper.Token._TOKEN_KEY, value);
    },
    remove: () => {
      localStorage.removeItem(GlobalParametersHelper.Token._TOKEN_KEY);
    },
  },
  getReactAppVersion: () => {
    return `${process.env.REACT_APP_NAME ?? ""} ${
      process.env.REACT_APP_VERSION ?? ""
    }`;
  },
  getFileManagerLocation: () => {
    return "https://filemanager.deda.gr/";
  },
  getDedaMapLocation: () => {
    return "https://deda.gr/crm-internal-iframe-map/";
  },
  // DEPRECATED USED IN CONSUMER
  // getIsCustomFlorinaEnabled : () => {
  //   return false;
  //   // const isCustomFlorinaEnabled = getBoolean(process.env.REACT_APP_IS_CUSTOM_FLORINA_ENABLED);
  //   // return isCustomFlorinaEnabled;
  // }
};

export default GlobalParametersHelper;
