import React, { useState, useContext, useEffect } from "react";
import { Base } from "contexts/BaseContext";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import "moment/locale/el";

export const Suppliers = () => {
  const baseContext = useContext(Base);
  const [_suppliers, setSuppliers] = React.useState(null);
  const [loggedUser, setLoggedUser] = React.useState(
    baseContext.baseCommon.loggedUser
  );
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    (async () => {
      let suppliersResult = await ApiCallerHelper.Suppliers.getAllSuppliers();
      setSuppliers(suppliersResult.data);
    })();
  }, [loggedUser]);

  useEffect(() => {
    setIsLoaded(true);
  }, [_suppliers]);

  const telisExport = async () => {
    let exportFileName = "connections.xlsx";
    let exportResult = await ApiCallerHelper.Export.getExportConnections();
    const url = window.URL.createObjectURL(new Blob([exportResult.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", exportFileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  if (_suppliers == null) {
    return <></>;
  }

  return (
    <>
      <GenericHeader />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <div className="col">
                      <h3 className="mb-0">Αλλαγές χρήστη σε εκκρεμότητα</h3>
                    </div>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href={"/admin/pendingrequests/"}
                      size="sm"
                    >
                      Προβολή
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <div className="col">
                      <h3 className="mb-0">Αιτήματα προμηθευτών</h3>
                    </div>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href={"/admin/supplierrequests/"}
                      size="sm"
                    >
                      Προβολή
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <div className="col">
                      <h3 className="mb-0">Προμηθευτές</h3>
                    </div>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      href={"/admin/allsupplierdata/"}
                      size="sm"
                    >
                      Συνολική Προβολή
                    </Button>
                    <Button
                      color="success"
                      onClick={() => telisExport()}
                      size="sm"
                    >
                      Εξαγωγή στοιχείων
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ονομα Προμηθευτη</th>
                    <th scope="col">Ενεργειες</th>
                  </tr>
                </thead>
                <tbody>
                  {_suppliers.map((dynamicData, key) => (
                    <tr key={key}>
                      <th scope="row"> {dynamicData.Name}</th>
                      <td>
                        <Button
                          color="primary"
                          href={"/admin/supplier/" + dynamicData.Id}
                          size="sm"
                          title="Επεξεργασία"
                        >
                          Προβολή στοιχείων
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Suppliers;
