import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";
import { MDBDataTable } from "mdbreact";
import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import "../../assets/css/datatables.css";

class SupplierRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/suppliers/requests/crmpending/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances2 = res.data;
          var self = this;

          instances2.forEach(function (part, index) {
            this[index].AnswerButtons = (
              <React.Fragment>
                <Button
                  color="success"
                  onClick={() => {
                    self.acceptReq(this[index].ReqData.Id);
                  }}
                  size="sm"
                  title="Αποδοχή"
                >
                  <i className="fas fa-check" />
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    self.declineReq(this[index].ReqData.Id);
                  }}
                  size="sm"
                  title="Απόρριψη"
                >
                  <i className="fas fa-window-close" />
                </Button>
              </React.Fragment>
            );

            this[index].Buttons = (
              <React.Fragment>
                {/* <Button
                color="primary"
                href={"/admin/requestinfo/" + this[index].Id}
                size="sm"
              >
                Στοιχεία Αιτήματος
              </Button> */}
                <Button
                  color="primary"
                  href={"/admin/instance/" + this[index].ReqData.InstanceId}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);

          EVOMDBDataTableHelper.GridFilter.setStateFilters(
            this,
            "mdbdttable",
            res.data,
            ["Supplier", "ReqKind"]
          );

          this.state.datas = {
            columns: [
              {
                label: "ΗΚΑΣΠ ▼",
                field: "Ikasp",
                sort: "asc",
                width: 150,
              },
              {
                label: "Αιτων ▼",
                field: "Supplier",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ειδος Αιτηματος ▼",
                field: "ReqKind",
                sort: "asc",
                width: 150,
              },
              {
                label: "Hμ. Αιτηματος ▼",
                field: "ReqDate",
                sort: "asc",
                width: 150,
              },
              {
                label: "Επιλεγμενη Ημ. ▼",
                field: "SelDate",
                sort: "asc",
                width: 150,
              },
              {
                label: "Λoγος Διακοπης ▼",
                field: "Reason",
                sort: "asc",
                width: 150,
              },
              {
                label: "Απαντηση ▼",
                field: "AnswerButtons",
                sort: "asc",
                width: 150,
              },
              // {
              //     label: 'Μεταβαση ▼',
              //     field: 'Buttons',
              //     sort: 'asc',
              //     width: 150
              // }
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });
    }
  }

  acceptReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/suppliers/request_permission/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            ConnectionId: reqId,
            Option: 1,
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  declineReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/suppliers/request_permission/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            ConnectionId: reqId,
            Option: 0,
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Αιτήματα Προμηθευτών</h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "Supplier",
                          fieldTitle: "Αιτών",
                          isIncludeEmptyItem: true,
                        },
                        {
                          fieldName: "ReqKind",
                          fieldTitle: "Είδος Αιτήματος",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default SupplierRequests;
