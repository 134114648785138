import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function getAllWorkflows() {
  var route = "/api/workflows/";
  return getAsync(route);
}

export async function postSuppliersRequest({
  option,
  dateModal,
  reasonModal,
  SerialNumber,
}) {
  var route = "/api/suppliers/request/";
  let data = {
    option: option,
    dateModal: dateModal,
    reasonModal: reasonModal,
    SerialNumber: SerialNumber,
  };
  return postAsync(route, data);
}
