export const RedirectHelper = {
  RedirectTo: {
    reloadPage: () => {
      window.location.reload();
    },
    reloadPageFromCache: () => {
      window.location.reload(false);
    },
    // home: () => _location("/basicLayout/index/"),
    // login: () => _location("/auth/login"),
    details: (workflowInstanceId) =>
      _location("/admin/details/" + workflowInstanceId),
    meterPage: (meterId) => _location("/admin/meter/" + meterId),
    property: (propertyId, isOpenWindow = false) => {
      let route = "/admin/property/" + propertyId;
      if (isOpenWindow == true) {
        _openWindow(route);
      }else{
        _location(route);
      }      
    },
    // dynamicRedirect: (url) => _location(url),
    // passwordChange: () => _location("/passwordchange/"),
  },
};
    
    function _locationRedirectReact(self, route) {
      self.props.history.push(route)
    }
    
    function _location(route) {
      window.location.replace(route);
    }

    function _openWindow(route) {
      window.open(window.location.origin + route, '_blank');      
    }

export default RedirectHelper;