import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

import axios from "axios";

class Mechanic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      connections: [],
      applications: [],
      canceled: [],
      mechanic: [],
      user: [],
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/mechanics/mechanic/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              mechanic: result.mechanic,
              connections: result.connections,
              applications: result.applications,
              canceled: result.canceled,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );
    }

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  //   saveChanges = consumerId => {
  //     if (localStorage.getItem('token')) {
  //       axios.put(process.env.REACT_APP_API_LINK + '/api/consumer/update/', {
  //         data: {'Name' : this.state.name},
  //         headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  //       })
  //       .then(res => {
  //
  //       })
  //     }
  //   }

  //   updateName(evt) {
  //
  //     this.setState({
  //       name: evt.target.value
  //     })
  //   }

  acceptReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/answerreq/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Id: reqId,
          Answer: 1,
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  declineReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/answerreq/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Id: reqId,
          Answer: 0,
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Μηχανικού</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {this.state.user.PersonRole == "Admin" ||
                        this.state.user.PersonRole == "Maintenance" ? (
                          <>
                            <Button
                              color="primary"
                              href="#pablo"
                              //   onClick={() => { this.saveChanges(this.state.details.Id) }}
                              size="sm"
                            >
                              Αποθήκευση αλλαγών
                            </Button>
                          </>
                        ) : (
                          <> </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.mechanic.Name}
                                id="input-username"
                                placeholder="Όνομα"
                                type="text"
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επίθετο
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.mechanic.LastName}
                                id="input-username"
                                placeholder="Επίθετο"
                                type="text"
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.mechanic.Email}
                                id="input-username"
                                placeholder="Email"
                                type="text"
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Τηλέφωνο
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.mechanic.Phone}
                                id="input-username"
                                placeholder="Τηλέφωνο"
                                type="text"
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Εγκεκριμένα αιτήματα</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ΗΚΑΣΠ</th>
                        <th scope="col">ΤΕΛΕΥΤΑΙΑ ΕΝΗΜΕΡΩΣΗ</th>
                        <th scope="col">ΕΝΕΡΓΕΙΕΣ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.connections.map((dynamicData) => {
                        return (
                          <tr>
                            <td>{dynamicData.Ikasp}</td>
                            <td>{dynamicData.SubmittedAt}</td>
                            <td>
                              <Button
                                color="primary"
                                href={
                                  "/admin/details/" + dynamicData.InstanceId
                                } // Use dynamic data for the URL
                                size="sm"
                                title="Στοιχεία Αίτησης"
                              >
                                Στοιχεία Αίτησης
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Αιτήματα σε αναμονή</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Ονομα</th>
                        <th scope="col">Ημερομηνια Υποβολης</th>
                        <th scope="col">Ενεργειες</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.applications.map((dynamicData) => {
                        return (
                          <tr>
                            <td>{dynamicData.Ikasp}</td>
                            <td>{dynamicData.SubmittedAt}</td>
                            <td>
                              <Button
                                color="primary"
                                href={
                                  "/admin/details/" + dynamicData.InstanceId
                                } // Use dynamic data for the URL
                                size="sm"
                                title="Στοιχεία Αίτησης"
                              >
                                Στοιχεία Αίτησης
                              </Button>
                              {/* <Button
                                color="success"
                                onClick={() => {
                                  this.acceptReq(dynamicData.Id);
                                }}
                                size="sm"
                                title="Αποδοχή"
                              >
                                <i className="fas fa-check" />
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.declineReq(dynamicData.Id);
                                }}
                                size="sm"
                                title="Απόρριψη"
                              >
                                <i className="fas fa-window-close" />
                              </Button> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ακυρωμένα αιτήματα</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Ονομα</th>
                        <th scope="col">Ημερομηνια Υποβολης</th>
                        <th scope="col">Ενεργειες</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.canceled.map((dynamicData) => {
                        return (
                          <tr>
                            <td>{dynamicData.Ikasp}</td>
                            <td>{dynamicData.SubmittedAt}</td>
                            <td>
                              <Button
                                color="primary"
                                href={
                                  "/admin/details/" + dynamicData.InstanceId
                                } // Use dynamic data for the URL
                                size="sm"
                                title="Στοιχεία Αίτησης"
                              >
                                Στοιχεία Αίτησης
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Mechanic;
