import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Alert,
  UncontrolledAlert,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import InstanceHeader from "components/Headers/InstanceHeader.js";

import { CommonLoading } from "react-loadingg";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import Moment from "moment";
import "moment/locale/el";
import "leaflet/dist/leaflet.css";

import axios from "axios";
import Modal from "react-modal";

import Select from "react-select";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

class researchersManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      allUsers: [],
      researchersMechanics: [],
      instanceNumber: "",
      selectedUser: "",
      todeleteuser: "",
      user: "",
      isOpenDanger: false,
      isOpenSuccess: false,
      isOpenSuccessDelete: false,
      studyMechanics: [],
    };
    this.onDismissSuccess = this.onDismissSuccess.bind(this);
    this.onDismissSuccessDelete = this.onDismissSuccessDelete.bind(this);
    this.onDismissDanger = this.onDismissDanger.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/all/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            let temp_list = [...result];
            let options_list = [];

            temp_list.forEach((part, index) => {
              // options_list.push({ 'value': part[0].Id, 'label': part[0].DisplayName + ' (' + part[1].City + ')' })
              options_list.push({ value: part.Id, label: part.DisplayName });
            });
            this.setState({
              allUsers: options_list,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      await fetch(
        process.env.REACT_APP_API_LINK + "/api/user/studymechanics/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let temp_list = [...result];
            let options_list = [];

            temp_list.forEach((part, index) => {
              // options_list.push({ 'value': part[0].Id, 'label': part[0].DisplayName + ' (' + part[1].City + ')' })
              options_list.push({ value: part.Id, label: part.DisplayName });
            });
            this.setState({
              researchersMechanics: options_list,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  handleSelectChange(e) {
    this.setState({
      selectedUser: e.value,
    });
  }

  handleChangeDelete(e) {
    this.setState({
      todeleteuser: e.value,
    });
  }

  assignFunc = () => {
    fetch(
      process.env.REACT_APP_API_LINK +
        "/api/user/adduserresearcher/" +
        this.state.selectedUser +
        "/",
      {
        method: "post",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          //

          if (result != -1) {
            if (this.state.selectedUser != "")
              this.setState({
                isOpenSuccess: true,
              });

            fetch(
              process.env.REACT_APP_API_LINK + "/api/user/studymechanics/",
              {
                method: "get",
                headers: new Headers({
                  Authorization: "Bearer " + localStorage.getItem("token"),
                  "Content-Type": "application/x-www-form-urlencoded",
                }),
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  let temp_list = [...result];
                  let options_list = [];

                  temp_list.forEach((part, index) => {
                    // options_list.push({ 'value': part[0].Id, 'label': part[0].DisplayName + ' (' + part[1].City + ')' })
                    options_list.push({
                      value: part.Id,
                      label: part.DisplayName,
                    });
                  });
                  this.setState({
                    researchersMechanics: options_list,
                  });
                },
                (error) => {
                  this.setState({
                    isLoaded: true,
                    error,
                  });
                }
              );
          } else {
            this.setState({
              isOpenDanger: true,
            });
          }
          // this.state.fade = false;
        },
        (error) => {
          alert(
            "Η αίτηση με αριθμό " + this.state.instanceNumber + " δεν βρέθηκε!"
          );
        }
      );
  };

  deleteMechanic = () => {
    fetch(
      process.env.REACT_APP_API_LINK +
        "/api/user/userresearcher/" +
        this.state.todeleteuser +
        "/",
      {
        method: "delete",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (this.state.todeleteuser != "")
          this.setState({
            isOpenSuccessDelete: true,
          });
        fetch(process.env.REACT_APP_API_LINK + "/api/user/studymechanics/", {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        })
          .then((res) => res.json())
          .then(
            (result) => {
              let temp_list = [...result];
              let options_list = [];

              temp_list.forEach((part, index) => {
                // options_list.push({ 'value': part[0].Id, 'label': part[0].DisplayName + ' (' + part[1].City + ')' })
                options_list.push({ value: part.Id, label: part.DisplayName });
              });
              this.setState({
                researchersMechanics: options_list,
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error,
              });
            }
          );
        // this.state.fade = false;
      });
  };

  onDismissSuccess = () => {
    this.setState({ isOpenSuccess: false });
  };

  onDismissSuccessDelete = () => {
    this.setState({ isOpenSuccessDelete: false });
  };

  onDismissDanger = () => {
    this.setState({ isOpenDanger: false });
  };

  render() {
    return (
      <>
        {this.state.user.Email == "crmadmin@deda.gr" ||
        this.state.user.Email == "a.kasoutsa@deda.gr" ? (
          <>
            <GenericHeader />
            <Container className="mt--7" fluid>
              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Μηχανικοί Μελέτης</h3>
                        </Col>
                        <Col className="text-right" xs="4"></Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        {/* <Col xs="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-instance-number"
                                                    >
                                                        Αριθμός Αίτησης
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue=""
                                                        id="input-instance-number"
                                                        type="number"
                                                        placeholder='Αριθμός Αίτησης'
                                                        onChange={evt => this.handleChange(evt)}
                                                    />
                                                </Col> */}
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="select-user"
                          >
                            Επιλογή Χρήστη για Προσθήκη
                          </label>
                          <Select
                            id="select-user"
                            options={this.state.allUsers}
                            placeholder={"Επιλέξτε Χρήστη..."}
                            onChange={(e) => {
                              this.handleSelectChange(e);
                            }}
                          />
                        </Col>
                        <Col xs="4">
                          <Button
                            color="primary"
                            size="sm"
                            style={{ padding: "10px", marginTop: "20px" }}
                            onClick={() => this.assignFunc()}
                          >
                            Προσθήκη
                          </Button>
                        </Col>
                        <Col>
                          <UncontrolledAlert
                            color="success"
                            toggle={this.onDismissSuccess}
                            isOpen={this.state.isOpenSuccess}
                            fade={false}
                          >
                            <span className="alert-inner--text">
                              <strong>Επιτυχία!</strong> Ο Μηχανικός προστέθηκε
                              επιτυχώς!
                            </span>
                          </UncontrolledAlert>

                          <UncontrolledAlert
                            color="danger"
                            toggle={this.onDismissDanger}
                            isOpen={this.state.isOpenDanger}
                            fade={false}
                          >
                            <span className="alert-inner--text">
                              <strong>Σφάλμα!</strong> Ο Μηχανικός βρίσκεται ήδη
                              στην λίστα!
                            </span>
                          </UncontrolledAlert>
                        </Col>
                      </Row>
                      <Row
                        className="align-items-center"
                        style={{ marginTop: "50px" }}
                      >
                        {/* <Col xs="4">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-instance-number"
                                                    >
                                                        Αριθμός Αίτησης
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue=""
                                                        id="input-instance-number"
                                                        type="number"
                                                        placeholder='Αριθμός Αίτησης'
                                                        onChange={evt => this.handleChange(evt)}
                                                    />
                                                </Col> */}
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="select-user"
                          >
                            Επιλογή Χρήστη για Αφαίρεση
                          </label>
                          <Select
                            id="select-user"
                            options={this.state.researchersMechanics}
                            placeholder={"Επιλέξτε Χρήστη..."}
                            onChange={(e) => {
                              this.handleChangeDelete(e);
                            }}
                          />
                        </Col>
                        <Col xs="4">
                          <Button
                            color="warning"
                            size="sm"
                            style={{ padding: "10px", marginTop: "20px" }}
                            onClick={() => this.deleteMechanic()}
                          >
                            Αφαίρεση
                          </Button>
                        </Col>
                        <Col>
                          <UncontrolledAlert
                            color="success"
                            toggle={this.onDismissSuccessDelete}
                            isOpen={this.state.isOpenSuccessDelete}
                            fade={false}
                          >
                            <span className="alert-inner--text">
                              <strong>Επιτυχία!</strong> Ο Μηχανικός αφαιρέθηκε
                              επιτυχώς!
                            </span>
                          </UncontrolledAlert>

                          {/* <UncontrolledAlert color="danger" toggle={this.onDismissDanger} isOpen={this.state.isOpenDanger} fade={false}>
                                                        <span className="alert-inner--text">
                                                            <strong>Σφάλμα!</strong> Ο Μηχανικός βρίσκεται ήδη στην λίστα!
                                                        </span>
                                                    </UncontrolledAlert> */}
                        </Col>
                      </Row>
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default researchersManagement;
