import Index from "views/Index.js";
import Maps from "views/examples/Maps.js";
import Login from "views/examples/Login.js";
import Users from "views/examples/Users.js";
import User from "views/examples/User.js";
import NewUser from "views/examples/newUser.js";
import Consumers from "views/examples/Consumers.js";
import Consumer from "views/examples/Consumer.js";
import Instances from "views/examples/Instances.js";
import InstanceDetails from "views/examples/InstanceDetails.js";
import Instance from "views/examples/Instance.js";
import InstanceSettings from "views/examples/InstanceSettings.js";
import Timers from "views/examples/Timers.js";
import SupplierApplications from "views/examples/SupplierApplications.js";
import SupplierApplication from "views/examples/SupplierApplication.js";
import SupplierData from "views/examples/SupplierData.js";
import History from "views/examples/History.js";
import OldInstances from "views/examples/OldInstances.js";
import OldInstance from "views/examples/OldInstance.js";
import CurrentConsumers from "views/examples/CurrentConsumers.js";
import CurrentConsumer from "views/examples/CurrentConsumer.js";
import CompletedInstances from "views/examples/CompletedInstances.js";
import CompletedInstance from "views/examples/CompletedInstance.js";
import CommentHistory from "views/examples/commentHistory.js";
import InstanceComments from "views/examples/instanceComments.js";
import newInstance from "views/examples/newInstance.js";
import createInstance from "views/examples/createInstance.js";
import Workflows from "views/examples/Workflows.js";
import Workflow from "views/examples/Workflow.js";
import WorkflowSteps from "views/examples/WorkflowSteps.js";
import StepActions from "views/examples/StepActions.js";
import Documents from "views/examples/Documents.js";
import Logs from "views/examples/Logs.js";
import Profile from "views/examples/Profile.js";
import Applications from "views/examples/Applications.js";
import AvailableApplications from "views/examples/availableApplications.js";
import UnvailableApplications from "views/examples/unavailableApplications.js";
import Application from "views/examples/Application.js";
import Industrials from "views/examples/Industrials.js";
import IndustrialClients from "views/examples/IndustrialClients.js";
import IndustrialApplications from "views/examples/IndustrialApplications";
import CanceledApps from "views/examples/CanceledApps";
import Support from "views/examples/Support.js";
import ConsumerSupport from "views/examples/consumerSupport.js";
import ConsumerTicket from "views/examples/consumerTicket.js";
import Settings from "views/examples/Settings.js"
import Suppliers from "views/examples/Suppliers.js";
import AllSuppliers from "views/examples/allSuppliers.js";
import Supplier from "views/examples/Supplier.js";
import PendingRequests from "views/examples/PendingRequests.js";
import SupplierRequests from "views/examples/SupplierRequests.js";
import Requests from "views/examples/Requests.js";
import MechanicMenu from "views/examples/MechanicMenu.js";
import MechanicApps from "views/examples/MechanicApps.js";
import RoutedInstances from "views/examples/RoutedInstances.js";
import Mechanics from "views/examples/Mechanics.js";
import Mechanic from "views/examples/Mechanic.js";
import Properties from "views/examples/Properties.js";
import Property from "views/examples/Property.js";
import NewProperty from "views/examples/newProperty.js";
import Meters from "views/examples/Meters.js";
import Meter from "views/examples/Meter.js";
import IkaspCodes from "views/examples/IkaspCodes.js";
import newAreaCode from "views/examples/newAreaCode.js";
import Management from "views/examples/Management.js";
import EditMenu from "views/examples/EditMenu.js";
import Edit from "views/examples/Edit.js";
import Reporting from "views/examples/Reporting.js";
import Testing from "views/examples/Testing.js";
import Routing from "views/examples/Routing.js";
import RoutingHistory from "views/examples/RoutingHistory.js";
import InstanceRoutingHistory from "views/examples/InstanceRoutingHistory.js";
import researchersManagement from "views/examples/researchersManagement";
import AutopsyInstances from "views/examples/AutopsyInstances.js";
import Funding from "views/examples/Funding.js";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Χρήστες",
    icon: "ni ni-single-02 text-yellow",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/user/:id",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: User,
    layout: "/admin"
  },
  {
    path: "/newuser",
    name: "Χρήστης",
    icon: "ni ni-single-02 text-yellow",
    component: NewUser,
    layout: "/admin"
  },
  {
    path: "/consumers",
    name: "Καταναλωτές",
    icon: "ni ni-single-02 text-yellow",
    component: Consumers,
    layout: "/admin"
  },
  {
    path: "/consumer/:id",
    name: "Καταναλωτής",
    icon: "ni ni-single-02 text-yellow",
    component: Consumer,
    layout: "/admin"
  },
  {
    path: "/instances",
    name: "Ροές",
    icon: "ni ni-circle-08 text-pink",
    component: Instances,
    layout: "/admin"
  },
  {
    path: "/details/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceDetails,
    layout: "/admin"
  },
  {
    path: "/instance/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: Instance,
    layout: "/admin"
  },
  {
    path: "/commenthistory/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: CommentHistory,
    layout: "/admin"
  },
  {
    path: "/comments/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceComments,
    layout: "/admin"
  },
  {
    path: "/instancesettings/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceSettings,
    layout: "/admin"
  },
  {
    path: "/timers/:id",
    name: "Timers",
    icon: "ni ni-circle-08 text-pink",
    component: Timers,
    layout: "/admin"
  },
  {
    path: "/supplierapplications/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierApplications,
    layout: "/admin"
  },
  {
    path: "/supplierapplication/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierApplication,
    layout: "/admin"
  },
  {
    path: "/supplierdata/:id",
    name: "Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierData,
    layout: "/admin"
  },
  {
    path: "/newinstance",
    name: "Nέα Ροή",
    icon: "ni ni-circle-08 text-pink",
    component: newInstance,
    layout: "/admin"
  },
  {
    path: "/createinstance",
    name: "Δημιουργία Ροής",
    icon: "ni ni-circle-08 text-pink",
    component: createInstance,
    layout: "/admin"
  },
  {
    path: "/history",
    name: "Δημιουργία Ροής",
    icon: "ni ni-circle-08 text-pink",
    component: History,
    layout: "/admin"
  },
  {
    path: "/currentconsumers/",
    name: "Ενεργοί Καταναλωτές",
    icon: "ni ni-circle-08 text-pink",
    component: CurrentConsumers,
    layout: "/admin"
  },
  {
    path: "/current/:id",
    name: "Ενεργός Καταναλωτής",
    icon: "ni ni-circle-08 text-pink",
    component: CurrentConsumer,
    layout: "/admin"
  },
  {
    path: "/completedinstances",
    name: "Ολοκληρωμένες Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: CompletedInstances,
    layout: "/admin"
  },
  {
    path: "/completed/:id",
    name: "Ολοκληρωμένη Αιτήση",
    icon: "ni ni-circle-08 text-pink",
    component: CompletedInstance,
    layout: "/admin"
  },
  {
    path: "/oldinstances",
    name: "Παλιές Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: OldInstances,
    layout: "/admin"
  },
  {
    path: "/oldinstance/:id",
    name: "Παλιά Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: OldInstance,
    layout: "/admin"
  },
  {
    path: "/applications",
    name: "Πρώιμες Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: Applications,
    layout: "/admin"
  },
  {
    path: "/availableapplications",
    name: "Πρώιμες Αιτήσεις εντός δικτύου",
    icon: "ni ni-circle-08 text-pink",
    component: AvailableApplications,
    layout: "/admin"
  },
  {
    path: "/routinghistory",
    name: "Ιστορικό Δρομολόγησης",
    icon: "ni ni-circle-08 text-pink",
    component: RoutingHistory,
    layout: "/admin"
  },
  {
    path: "/instanceroutinghistory/:id",
    name: "Ιστορικό Δρομολόγησης",
    icon: "ni ni-circle-08 text-pink",
    component: InstanceRoutingHistory,
    layout: "/admin"
  },
  {
    path: "/unavailableapplications",
    name: "Πρώιμες Αιτήσεις εντός δικτύου",
    icon: "ni ni-circle-08 text-pink",
    component: UnvailableApplications,
    layout: "/admin"
  },
  {
    path: "/application/:id",
    name: "Πρώιμη αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: Application,
    layout: "/admin"
  },
  {
    path: "/industrials",
    name: "Βιομηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: Industrials,
    layout: "/admin"
  },
  {
    path: "/industrialclients",
    name: "Βιομηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: IndustrialClients,
    layout: "/admin"
  },
  {
    path: "/industrialapplications",
    name: "Βιομηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: IndustrialApplications,
    layout: "/admin"
  },
  {
    path: "/canceledapps",
    name: "Βιομηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: CanceledApps,
    layout: "/admin"
  },
  {
    path: "/workflows",
    name: "Προγράμματα",
    icon: "ni ni-circle-08 text-pink",
    component: Workflows,
    layout: "/admin"
  },
  {
    path: "/workflow/:id",
    name: "Πρόγραμμα",
    icon: "ni ni-circle-08 text-pink",
    component: Workflow,
    layout: "/admin"
  },
  {
    path: "/steps/:id",
    name: "Βήματα",
    icon: "ni ni-circle-08 text-pink",
    component: WorkflowSteps,
    layout: "/admin"
  },
  {
    path: "/stepactions/:id",
    name: "Ενέργειες",
    icon: "ni ni-circle-08 text-pink",
    component: StepActions,
    layout: "/admin"
  },
  {
    path: "/documents",
    name: "Αρχεία",
    icon: "ni ni-circle-08 text-pink",
    component: Documents,
    layout: "/admin"
  },
  {
    path: "/logs",
    name: "Αρχείο Δραστηριοτήτων",
    icon: "ni ni-circle-08 text-pink",
    component: Logs,
    layout: "/admin"
  },
  {
    path: "/suppliers",
    name: "Προμηθευτές",
    icon: "ni ni-circle-08 text-pink",
    component: Suppliers,
    layout: "/admin"
  },
  {
    path: "/allsupplierdata",
    name: "Προμηθευτές",
    icon: "ni ni-circle-08 text-pink",
    component: AllSuppliers,
    layout: "/admin"
  },
  {
    path: "/supplier/:id",
    name: "Προμηθευτής",
    icon: "ni ni-circle-08 text-pink",
    component: Supplier,
    layout: "/admin"
  },
  {
    path: "/pendingrequests",
    name: "Αλλαγές χρήστη σε εκκρεμότητα",
    icon: "ni ni-circle-08 text-pink",
    component: PendingRequests,
    layout: "/admin"
  },
  {
    path: "/supplierrequests",
    name: "Αιτήματα Προμηθευτών",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierRequests,
    layout: "/admin"
  },
  {
    path: "/requests/:id",
    name: "Αιτήματα Προμηθευτών",
    icon: "ni ni-circle-08 text-pink",
    component: Requests,
    layout: "/admin"
  },
  {
    path: "/mechanicmenu",
    name: "Μηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicMenu,
    layout: "/admin"
  },
  {
    path: "/mechanics",
    name: "Μηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: Mechanics,
    layout: "/admin"
  },
  {
    path: "/mechanicapps",
    name: "Μηχανικοί",
    icon: "ni ni-circle-08 text-pink",
    component: MechanicApps,
    layout: "/admin"
  },
  {
    path: "/routedinstances",
    name: "Δρομολογημένες Μελέτες",
    icon: "ni ni-circle-08 text-pink",
    component: RoutedInstances,
    layout: "/admin"
  },
  {
    path: "/autopsyinstances",
    name: "Αιτήματα Αυτοψίας",
    icon: "ni ni-circle-08 text-pink",
    component: AutopsyInstances,
    layout: "/admin"
  },
  {
    path: "/mechanic/:id",
    name: "Μηχανικός",
    icon: "ni ni-circle-08 text-pink",
    component: Mechanic,
    layout: "/admin"
  },
  {
    path: "/properties",
    name: "Ακίνητα",
    icon: "ni ni-circle-08 text-pink",
    component: Properties,
    layout: "/admin"
  },
  {
    path: "/property/:id",
    name: "Ακίνητο",
    icon: "ni ni-circle-08 text-pink",
    component: Property,
    layout: "/admin"
  },
  {
    path: "/newproperty",
    name: "Ακίνητο",
    icon: "ni ni-circle-08 text-pink",
    component: NewProperty,
    layout: "/admin"
  },
  {
    path: "/meters",
    name: "Μετρητές",
    icon: "ni ni-circle-08 text-pink",
    component: Meters,
    layout: "/admin"
  },
  {
    path: "/meter/:id",
    name: "Μετρητής",
    icon: "ni ni-circle-08 text-pink",
    component: Meter,
    layout: "/admin"
  },
  {
    path: "/ikasp",
    name: "ΗΚΑΣΠ",
    icon: "ni ni-circle-08 text-pink",
    component: IkaspCodes,
    layout: "/admin"
  },
  {
    path: "/newareacode",
    name: "ΗΚΑΣΠ",
    icon: "ni ni-circle-08 text-pink",
    component: newAreaCode,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Προφίλ χρήστη",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/support",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: Support,
    layout: "/admin"
  },
  {
    path: "/supportconsumer",
    name: "Υποστήριξη",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerSupport,
    layout: "/admin"
  },
  {
    path: "/ticket/:id",
    name: "Mηνυμα Υποστήριξης",
    icon: "ni ni-circle-08 text-pink",
    component: ConsumerTicket,
    layout: "/admin"
  },
  {
    path: "/management",
    name: "Διαχείριση",
    icon: "ni ni-circle-08 text-pink",
    component: Management,
    layout: "/admin"
  },
  {
    path: "/editmenu",
    name: "Διαχείριση",
    icon: "ni ni-circle-08 text-pink",
    component: EditMenu,
    layout: "/admin"
  },
  {
    path: "/edit/:id",
    name: "Διαχείριση",
    icon: "ni ni-circle-08 text-pink",
    component: Edit,
    layout: "/admin"
  },
  {
    path: "/reporting",
    name: "Reporting",
    icon: "ni ni-circle-08 text-pink",
    component: Reporting,
    layout: "/admin"
  },
  {
    path: "/testing",
    name: "Testing",
    icon: "ni ni-circle-08 text-pink",
    component: Testing,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/routing",
    name: "Routing",
    icon: "ni ni-key-25 text-info",
    component: Routing,
    layout: "/admin"
  },
  {
    path: "/researchersmanagement",
    name: "Research Mechanics",
    icon: "ni ni-key-25 text-info",
    component: researchersManagement,
    layout: "/admin"
  },
  {
    path: "/funding",
    name: "Χρηματοδοτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: Funding,
    layout: "/admin"
  },
];

export default routes;
