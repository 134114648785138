import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class MechanicMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      availableApplications: [],
      unavailableApplications: [],
      user: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Μηχανικοί</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/mechanics/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Αιτήματα Αυτοψίας</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/autopsyinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          {/* <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Εκκρεμή Αιτήματα Μηχανικών</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/mechanicapps/"}
                        size="sm"
                      >
                        Προβολή όλων
                        
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row> */}
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Δρομολογημένες Μελέτες</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/routedinstances/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Απορριφθέντα Αιτήματα</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/canceledapps/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ιστορικό Δρομολόγησης</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href={"/admin/routinghistory/"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default MechanicMenu;
