import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function createMeter(instanceId) {
  var route = "/api/dynamic/newmeter/" + instanceId + "/";
  return postAsync(route, {});
}

export async function getLocations() {
  var route = "/api/dynamic/locations/";
  return getAsync(route);
}

export async function instanceLocation(instanceId) {
  var route = "/api/dynamic/instance_location/" + instanceId + "/";
  return getAsync(route);
}

export async function updateLocation(instanceId, locationId) {
  var route = "/api/dynamic/update_location/" + instanceId + "/" + locationId;
  return postAsync(route);
}

export async function createIkasp(instanceId) {
  var route = "/api/ikasp/new/";
  let data = {
    InstanceId: instanceId,
  };
  return postAsync(route, data);
}

export async function failedMechanicCompletion(instanceId) {
  var route = "/api/dynamic/failmechcompl/" + instanceId + "/";
  return getAsync(route);
}

export async function requestAutopsy(instanceId) {
  var route = "/api/dynamic/autopsy/" + instanceId + "/";
  return getAsync(route);
}

export async function calculatePriceOrRiser(
  riser,
  deposit,
  collateral,
  instanceId
) {
  var route = "/api/pricing/";
  let data = {
    Riser: riser,
    Deposit: deposit,
    Collateral: collateral,
    InstanceId: instanceId,
  };
  return postAsync(route, data);
}
