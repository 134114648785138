import React from "react";
import Iframe from "react-iframe";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import { ApiCallerHelper } from "../../api/apiCallerHelper";

import endpoint from "./config.js";

class newInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      workflows: [],
      showMap: false,
      selectedWorkflow: "",
    };

    this.createInstance = this.createInstance.bind(this);
  }

  selectWorkflow() {
    const wId = document.getElementById("selected-workflow").value;

    this.setState({
      selectedWorkflow: wId,
      showMap: true,
    });
  }

  messageHandler = (event) => {
    const { action, key, value, data } = event.data;
    if (action && action == "returnData") {
      let GISDataAddress = null;
      data.forEach((item) => {
        if (item["address"] != null) {
          GISDataAddress = item;
        }
      }, this);

      if (GISDataAddress) {
        this.setState({
          GISDataAddress: GISDataAddress,
        });
        this.createInstance(GISDataAddress);
      }
    }
  };

  fetchAddress() {
    const iframe = document.getElementById("iframe-map");
    iframe.contentWindow.postMessage(
      {
        action: "get",
        key: "GISData",
      },
      "*"
    );

    window.addEventListener("message", this.messageHandler, false);
  }

  async createInstance(GISDataAddress) {
    const createInstanceResult =
      await ApiCallerHelper.WorkflowInstances.newInstanceSystemic(
        GISDataAddress,
        this.state.selectedWorkflow
      );

    window.location.replace("/admin/instance/" + createInstanceResult.Id);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      const getWorkflowsResult =
        await ApiCallerHelper.Workflows.getAllWorkflows();
      this.setState({
        workflows: getWorkflowsResult.data,
      });
    }
  }

  render() {
    return (
      <>
        <InstanceHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>
                          <span className="h4 font-weight-bold mb-0">
                            Δημιουργία νέας αίτησης : Επιλογή Προτύπου
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--4" fluid>
          {this.state.showMap ? (
            <Row className="mt-5">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Επιλογή Τοποθεσίας Αίτησης</h3>
                      </Col>
                    </Row>
                    <div className="col text-right">
                      <Button
                        color="success"
                        onClick={() => this.fetchAddress()}
                        size="sm"
                      >
                        Δημιουργία νέας αίτησης
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Iframe
                      url="https://deda.gr/crm-internal-iframe-map/"
                      width="1010px"
                      height="490px"
                      id="iframe-map"
                      className=""
                      display="block"
                      position="relative"
                      scrolling="no"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className="mt-5">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Δημιουργία νέας αίτησης</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Επιλογη προτυπου αιτησης
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Πρότυπο αίτησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="none"
                                id="selected-workflow"
                                placeholder="First name"
                                type="select"
                              >
                                {this.state.workflows.map(
                                  (dynamicWorkflows) => (
                                    <option value={dynamicWorkflows.Id}>
                                      {dynamicWorkflows.Name}
                                    </option>
                                  )
                                )}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <Button
                              color="primary"
                              onClick={() => this.selectWorkflow()}
                              title="Επιλογή"
                            >
                              Επιλογή <i className="fas fa-tick" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default newInstance;
