import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import axios from "axios";

import endpoint from "./config.js";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      roles: [],
      role: "",
      name: "",
      email: "",
      created: false,
      newUser: [],
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  createUser = (userId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/new/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          DisplayName: this.state.name,
          Email: this.state.email,
          Roles: this.state.role,
          City: "Κεντρικά",
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  updateRole(evt) {
    this.setState({
      role: evt.target.value,
    });
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Δημιουργία νέου χρήστη</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        onClick={() => {
                          this.createUser();
                        }}
                        size="sm"
                      >
                        Δημιουργία
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ονοματεπώνυμο
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder=""
                              type="text"
                              onChange={(evt) => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder=""
                              type="email"
                              onChange={(evt) => this.updateEmail(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Ρόλος
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="Επιλογή Ρόλου"
                              type="select"
                              defaultValue="Επιλογή Ρόλου"
                              onChange={(evt) => this.updateRole(evt)}
                            >
                              <option>Επιλογή Ρόλου</option>
                              {this.state.roles.map((dynamicRoles) => (
                                <option value={dynamicRoles.Name}>
                                  {dynamicRoles.NormalizedName}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewUser;
