import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class MapsHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-4 pt-5" style={{background: "linear-gradient(to right, #00467F, #5CBC59)"}}>
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>

        <div className="header pb-4" style={{background: "linear-gradient(to right, #00467F, #5CBC59)"}}>
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <Col lg="8" xl="12">
                                <Card className="card-stats mb-4 mb-xl-0">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">
                                                       Αιτήσεις σε χάρτη
                                                    </span>
                                            </div>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
      </>
    );
  }
}

export default MapsHeader;
