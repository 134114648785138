import React from "react";
import axios from "axios";
import "../../assets/css/datatables.css";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";
import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

class RoutedInstances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      instances: [],
      user: [],
      routingUser: [],
    };
  }

  unAssign = (serialno) => {
    if (
      window.confirm(
        "Είστε σίγουροι ότι θέλετε να αποδρομολογήσετε την συγκεκριμένη αίτηση;"
      ) == true
    ) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/unassigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            SerialNumber: serialno,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result != -1) window.location.reload();
          else
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " δεν είναι πλέον δρομολογημένη!"
            );
        });
    } else {
    }
  };

  reAssign = (instance_id, serialno) => {
    if (
      window.confirm(
        "Είστε σίγουροι ότι θέλετε να επαναδρομολογήσετε την συγκεκριμένη αίτηση;"
      ) == true
    ) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/reassigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            InstanceId: instance_id,
            SerialNumber: serialno,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result != -1) window.location.reload();
          else
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " είναι πλέον επαναδρομολογημένη!"
            );
        });
    } else {
    }
  };

  async componentDidMount() {
    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    await fetch(
      process.env.REACT_APP_API_LINK + "/api/user/routing_privileges/",
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            routingUser: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    axios
      .get(process.env.REACT_APP_API_LINK + "/api/mechanics/routedinstances/", {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then((res) => {
        const instances2 = res.data;
        var self = this;

        instances2.forEach(function (part, index) {
          this[index].Buttons = (
            <React.Fragment>
              <Button
                color="success"
                href={"/admin/instanceroutinghistory/" + this[index].Id + "/"}
                size="sm"
                title="Ιστορικό Δρομολόγησης"
              >
                Ιστορικό Δρομολόγησης
              </Button>
              <Button
                color="primary"
                href={"/admin/details/" + this[index].Id}
                size="sm"
                title="Στοιχεία Αίτησης"
              >
                Στοιχεία Αίτησης
              </Button>
              {self.state.routingUser.includes(self.state.user.Email) &&
              this[index].isAssigned == false ? (
                <>
                  <Button
                    color="success"
                    onClick={() => {
                      self.reAssign(this[index].Id, this[index].SerialNumber);
                    }}
                    size="sm"
                    title="Επαναδρομολόγηση"
                  >
                    Επαναδρομολόγηση
                  </Button>
                </>
              ) : (
                <></>
              )}
              {self.state.routingUser.includes(self.state.user.Email) &&
              this[index].isAssigned == true ? (
                <>
                  <Button
                    color="warning"
                    onClick={() => {
                      self.unAssign(this[index].SerialNumber);
                    }}
                    size="sm"
                    title="Ξεκλείδωμα"
                  >
                    Ξεκλείδωμα
                  </Button>
                </>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        }, instances2);

        this.setState({
          datas: {
            columns: [
              {
                label: "Αριθμος Αιτησης ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 50,
              },
              {
                label: "Πολη ▼",
                field: "Location",
                sort: "asc",
                width: 120,
              },
              {
                label: "Ανατεθηκε σε ▼",
                field: "MechanicName",
                sort: "asc",
                width: 120,
              },
              {
                label: "Υποβολη",
                field: "SubmitDate",
                sort: "asc",
                width: 120,
              },
              {
                label: "Τελευταια Αναθεση",
                field: "ChangedDate",
                sort: "asc",
                width: 120,
              },
              {
                label: "Ενεργειες",
                field: "Buttons",
                sort: "asc",
                width: 120,
              },
            ],
            rows: res.data,
          },
        });

        EVOMDBDataTableHelper.GridFilter.setStateFilters(
          this,
          "mdbdttablerouted",
          res.data,
          ["MechanicName"]
        );

        const instances = res.data;
        this.setState({ instances });
      });
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Δρομολογημένες Μελέτες</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttablerouted"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "MechanicName",
                          fieldTitle: "Μηχανικός",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttablerouted"
                    data={this.state.datas}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default RoutedInstances;
