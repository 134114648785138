import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import "moment/locale/el";

import { ApiCallerHelper } from "../../api/apiCallerHelper";
import { UserRoleHelper } from "../../common/userRoleHelper";
import { BlobLinkDownloader } from "../../common/exportHelper";
import { MDBDataTable } from "mdbreact";
import EvoBaseComponent from "../../components/Abstract/EvoBaseComponent";
import EVOAlertComponent from "../../components/Abstract/EvoAlertComponent";
import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

class Properties extends EvoBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      gridDataSource: { columns: [], data: [] },
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount(this.initializeComponent);
  }

  initializeComponent = async () => {
    let propertiesResult = await ApiCallerHelper.Property.getAllProperties();
    const rowData = propertiesResult.data;
    rowData.forEach(function (part, index) {
      part.Buttons = (
        <React.Fragment>
          <Button
            color="primary"
            href={"/admin/property/" + part.Id}
            size="sm"
            title="Προβολή"
          >
            <i className="fas fa-eye" />
          </Button>
        </React.Fragment>
      );
    }, rowData);

    EVOMDBDataTableHelper.GridFilter.setStateFilters(
      this,
      "mdbdttable",
      rowData,
      ["City"]
    );

    let gridDataSource = {
      columns: [
        {
          label: "Διευθυνση ▼",
          field: "Address",
          sort: "asc",
          width: 100,
        },
        {
          label: "Αριθμος ▼",
          field: "AddressNum",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ταχυδρομικος Κωδικας ▼",
          field: "PostalCode",
          sort: "asc",
          width: 150,
        },
        ,
        {
          label: "Πολη ▼",
          field: "City",
          sort: "asc",
          width: 150,
        },
        {
          label: "Επιλογες ▼",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
      ],
      rows: propertiesResult.data,
    };
    this.setState({ gridDataSource: gridDataSource });
  };

  DownloadExportTechnicalReview = async () => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      let exportResult =
        await ApiCallerHelper.Export.getExportTechnicalReview();
      let fileName = "ΑΚΙΝΗΤΑ_ΠΡΟΣ_ΣΗΜΕΙΩΣΗ.xlsx";
      BlobLinkDownloader(exportResult.data, fileName);
    }
  };

  IsUserValidForExport = () => {
    let user = this.Base.Security.getLoggedUser();
    let emails = ["sales@deda.gr", "n.sourouvali@deda.gr"];
    if (
      UserRoleHelper.isAdmin(user) ||
      emails.includes(this.state.user.Email)
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ακίνητα</h3>
                    </div>
                    <div className="col text-right">
                      {this.IsUserValidForExport && (
                        <>
                          <Button
                            color="success"
                            href={"/admin/newproperty"}
                            size="sm"
                          >
                            Δημιουργία ακινήτου
                          </Button>
                          <Button
                            color="success"
                            onClick={() => {
                              this.DownloadExportTechnicalReview();
                            }}
                            size="sm"
                            title="Εξαγωγή"
                          >
                            <i className="fas fa-file-excel" /> Εξαγωγή ακινήτων
                            προς σημείωση
                          </Button>
                        </>
                      )}
                    </div>
                  </Row>

                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"gridDataSource"}
                      fields={[
                        {
                          fieldName: "City",
                          fieldTitle: "Πόλη",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.gridDataSource}
                  />
                </CardHeader>
                {/* <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Διευθυνση</th>
                      <th scope="col">Αριθμος</th>
                      <th scope="col">Ταχυδρομικος Κωδικας</th>
                      <th scope="col">Πολη</th>
                      <th scope="col">Επιλογες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.properties.map((dynamicData) =>
                        <tr> 
                            <th scope="row">{dynamicData.Address}</th>
                            <td>{dynamicData.AddressNum}</td>
                            <td>{dynamicData.PostalCode}</td>
                            <td>{dynamicData.City}</td>
                            <td>
                                <Button
                                    color="primary"
                                    href={"/admin/property/" + dynamicData.Id}
                                    size="sm"
                                    title="Προβολή"
                                >
                                    <i className="fas fa-eye" />
                                </Button>
                              </td>
                        </tr>
                      ) 
                    }
                  </tbody>
                </Table> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Properties;
