import ApiCallerHelper from "../api/apiCallerHelper"
import RedirectHelper from "../common/redirectHelper"

export const DynamicButtonsActionsHelper = {
    checkProperty: async (self, target, instanceId) => {
      let result = await ApiCallerHelper.Property.getPropertyByInstanceId(
        instanceId
      );
      //DEFAULT
      let executionResult = {
        buttonMessage: "",
        buttonCondition: 3,
        isOpenUrl: true,
      };
      if (result.data.details.Id == "Δεν υπάρχει ακόμα ακίνητο.") {
        executionResult = {
          buttonMessage: "Δεν υπάρχει ακίνητο στο οποίο βρίσκεται η αίτηση.",
          buttonCondition: 3,
          isOpenUrl: false,
        };
      }

      self.setState(executionResult);
      let actionMessages = self.actionMessages ?? [];
      if (executionResult.isOpenUrl) {
        let propertyId = result.data.details.Id;
        RedirectHelper.RedirectTo.property(propertyId, true);
      }
  },
  _calculatePriceOrRiser: async (self, target, instanceId, document, riser) => {
    var deposit = document.getElementById(
      "1feedef1-df5f-4905-b68e-c0b8d9896ad7/c5d01c0f-41bc-47ca-8445-3597882b69d9"
    ).value;
    var collateral = document.getElementById(
      "1feedef1-df5f-4905-b68e-c0b8d9896ad7/077389e4-ccc8-4dd2-a3a3-81d30a9a9e79"
    ).value;

    deposit = deposit > 0 ? deposit : 0;
    collateral = collateral > 0 ? collateral : 0;
    let result = await ApiCallerHelper.Dynamic.calculatePriceOrRiser(
      riser,
      deposit,
      collateral,
      instanceId
    );

    let buttonMessage =
      "Yπολογίστηκαν τα τέλη σύνδεσης στα " + result.Price + "€.";
    if (riser == 1) {
      buttonMessage =
        "Yπολογίστηκαν τα τέλη σύνδεσης για Riser στα " + result.Price + "€.";
    }

    let executionResult = {
      buttonMessage: buttonMessage,
      buttonCondition: 4,
    };
    self.setState(executionResult);
  },
  calculatePrice: async (self, target, instanceId, document) => {
    return await DynamicButtonsActionsHelper._calculatePriceOrRiser(
      self,
      target,
      instanceId,
      document,
      0
    );
  },
  calculateRiser: async (self, target, instanceId, document) => {
    return await DynamicButtonsActionsHelper._calculatePriceOrRiser(
      self,
      target,
      instanceId,
      document,
      1
    );
  },
  checkSupplier: async (self, target, instanceId) => {
    let result = await ApiCallerHelper.Suppliers.getSupplierByInstanceId(
      instanceId
    );    
    let executionResult = {
      buttonMessage: "Συνδέθηκε προμηθευτής με την αίτηση.",
      buttonCondition: 7,
    };

    if (result.data.SupplierId == "Δεν υπάρχει ακόμα") {
      executionResult.buttonMessage = "Δεν έχει συνδεθεί ακόμα προμηθευτής με την αίτηση.";
    }

    self.setState(executionResult);
  },
  requestAutopsy: async (self, target, instanceId) => {
    let result = await ApiCallerHelper.Dynamic.requestAutopsy(instanceId);
    self.setState({
      buttonMessage:
        "Αιτηθήκατε για αυτοψία. Θα ενημερωθεί το αρμόδιο τμήμα για το αίτημά σας.",
      buttonCondition: 9,
    });
  },
  failedMechanicCompletion: async (self, target, instanceId) => {
    let result = await ApiCallerHelper.Dynamic.failedMechanicCompletion(
      instanceId
    );
    self.setState({
      buttonMessage: "Απέτυχε η μηχανική ολοκλήρωση.",
      buttonCondition: 6,
    });
  },
  createIkasp: async (self, target, instanceId) => {
    let result = await ApiCallerHelper.Dynamic.createIkasp(instanceId);
    self.setState({
      buttonMessage: result.Ikasp,
      buttonCondition: 4,
    });
  },
  createMeter: async (self, target, instanceId) => {
    let executionResult = {
      buttonMessage: "Δημιουργήθηκε μετρητής για το ακίνητο.",
      buttonCondition: 4,
    };
    let result = await ApiCallerHelper.Dynamic.createMeter(instanceId);
    if (result.Id == "Exists") {
      executionResult.buttonMessage = "Έχει δημιουργηθεί ήδη μετρητής.";
    } else if (result.Id == "Error") {
      executionResult.buttonMessage = "Δεν έχει παραχθεί ακόμα ΗΚΑΣΠ.";
    }
    self.setState(executionResult);
  },
  executor: async (self, target, instanceId, document) => {    
    //executes any of the above actions based on dynamic action target    
    let x = DynamicButtonsActionsHelper;
    if (target == "calc_price") {
      await x.calculatePrice(self, target, instanceId, document);
    }
    if (target == "calc_riser") {
      await x.calculateRiser(self, target, instanceId, document);
    }
    if (target == "check_property") {
      await x.checkProperty(self, target, instanceId);
    }
    if (target == "check_supp") {
      await x.checkSupplier(self, target, instanceId);
    }
    if (target == "req_aut") {
      await x.requestAutopsy(self, target, instanceId);
    }
    if (target == "fail_mech_comp") {
      await x.failedMechanicCompletion(self, target, instanceId);
    }
    if (target == "create_ikasp") {
      await x.createIkasp(self, target, instanceId);
    }
    if (target == "create_meter") {
      await x.createMeter(self, target, instanceId);
    }
  },
  getButtonMessage: (self, target) => {
    "calc_price";
    "calc_riser";    
    "check_property";
    "check_supp";
    "req_aut";
    "fail_mech_comp";
    "create_ikasp";            
    "create_meter";                                      
  },
};

export default DynamicButtonsActionsHelper;
