import { UserRoleTypes } from "./enums/userRoleTypes.enum";

export const UserRoleHelper = {
  isUser: isUser,
  isAdmin: isAdmin,
  isClient: isClient,
  isMaster: isMaster
};

function isUser(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.USER.toLowerCase();
}

function isClient(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.CLIENT.toLowerCase();
}

function isAdmin(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.ADMIN.toLowerCase();
}

function isMaster(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.MASTER.toLowerCase();  
}