import React, { Fragment, useEffect, useState } from "react";
import {  
  TechnicalDescriptionActionIdEnum,  
  CustomStepsIdEnum
} from "./TechnicalDescription.Helper";
import TechnicalDescription from "./TechnicalDescription"
import ApiCallerHelper from "../../../../api/apiCallerHelper"
export const TechnicalDescriptionStandAlone = ({  
    instanceId //used to pass a reference of all steps within a give instance
  }) => {  
    const [dynamicAction, setDynamicAction] = useState(null); 
    const [steps, setSteps] = useState(null); 
    const stepId = CustomStepsIdEnum.step_12_dynamic_grid_technical_description;
    const getCRMActionId= (steps)=>{        
        let action = steps.find((x) => x.step.Id == stepId)?.actions?.find((x) => x.action.Id == TechnicalDescriptionActionIdEnum.CRMActionId) ?? null;                
        return action
    }
    
    useEffect(() => {
        if (!instanceId) {
          return;
        }

        (async () => {
            let result = await ApiCallerHelper.WorkflowInstances.getWorkflowInstanceById(instanceId);            
            let steps = result.data.stepsInfused;            
            let action = getCRMActionId(steps);
            setSteps(steps);
            setDynamicAction(action);                       
        })();

      }, []);  

    let isSkipRender = (dynamicAction == null)
    if ( isSkipRender ) {return;}    

    return (
      <Fragment>
        <TechnicalDescription
          steps={steps}
          title={dynamicAction.action.Name}
          actionType={dynamicAction.action.Type}
          actionId={dynamicAction.action.Id}
          stepId={stepId}
          actionDataValue={dynamicAction.data.Data}
          onUpdateCallback={(evt) => {}}
          isReadOnly={true}
        />
      </Fragment>
    );

  }

  export default TechnicalDescriptionStandAlone