import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Table,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";

class EditMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      fee: "",
      serial: "",
      showDropdowns: 0,
      id: "",
      selectedStep: "",
      step: [],
      logged_user: [],
      steps: [],
      instance: [],
      files: [],
      fileUpload: null,
      comment: "",
      buttonCondition: 0,
      buttonMessage: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflows/steps/fbc2cb7f-eb1c-455f-a484-579ffb53f1a3/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            isLoaded: true,
            steps: result,
          });
        });
    }
  }

  saveInstance = (stepStatus) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/update/" +
          this.state.instance.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify(this.state.steps[stepStatus - 1]),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateFee(evt) {
    this.setState({
      fee: evt.target.value,
    });
  }

  updateSerial(evt) {
    this.setState({
      serial: evt.target.value,
    });
  }

  searchFee = (evt) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/search/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Search: 1,
          Fee: this.state.fee,
          Serial: this.state.serial,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          window.location.replace("/admin/edit/" + data.Id);
        });
    }
  };

  async searchSerial(evt) {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/search/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Search: 2,
          Fee: this.state.fee,
          Serial: this.state.serial,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          window.location.replace("/admin/edit/" + data.Id);
        });
    }
  }

  changeStep(event) {
    this.setState({ selectedStep: event.target.value });
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          axios
            .get(
              process.env.REACT_APP_API_LINK +
                "/api/files/status/" +
                this.state.instance.Id +
                "/",
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              this.setState({
                loading: false,
                files: res.data,
              });
            });
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    formData.append("serial_number", this.state.instance.SerialNumber);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then((data) => {
      axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/files/status/" +
          this.state.instance.Id +
          "/",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          files: res.data,
        });
      });
    });
  }

  updateInputValue(evt) {
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
          }
        }
      }
    }
  }

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          {/* {this.state.showDropdowns == 1 ?
                <Row>
                    <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="8">
                            <h3 className="mb-0">Επιλογή βήματος αίτησης</h3>
                            </Col>
                        </Row>
                        </CardHeader>
                        <CardBody>
                        <Form>
                            <div className="pl-lg-4">
                            <Row>
                                <Col lg="3">
                                <label
                                    className="form-control-label"
                                    htmlFor="consumer-client"
                                >
                                    Βήμα Αίτησης
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="consumer-client"
                                    type="select"
                                    onChange={this.changeStep}
                                >   
                                    <option value='0'>-- Επιλέξτε βήμα αίτησης --</option>
                                    { this.state.steps.map((dynamicData) =>
                                        <option value={dynamicData.Id}>{dynamicData.Status}. {dynamicData.Name}</option>
                                    )}
                                </Input>
                                <br></br>
                                <Button
                                    color="success"
                                    onClick={evt => { this.searchFee(evt) }}
                                    size="sm"
                                >
                                    Επιλογή
                                </Button>
                                </Col>
                            </Row>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
            : <> </>} */}

          {this.state.showDropdowns == 0 ? (
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Εύρεση αίτησης για επεξεργασία</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      {/* <h6 className="heading-small text-muted mb-4">
                            Βασικα στοιχεια
                            </h6> */}
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                ΦΕΕ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="ΦΕΕ"
                                type="text"
                                onChange={(evt) => this.updateFee(evt)}
                              />
                            </FormGroup>
                            <Button
                              color="success"
                              onClick={(evt) => {
                                this.searchFee(evt);
                              }}
                              size="sm"
                            >
                              Αναζήτηση
                            </Button>
                          </Col>
                        </Row>

                        <br></br>

                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Αριθμός Αίτησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                placeholder="Αριθμός Αίτησης"
                                type="text"
                                onChange={(evt) => this.updateSerial(evt)}
                              />
                            </FormGroup>
                            <Button
                              color="success"
                              onClick={(evt) => {
                                this.searchSerial(evt);
                              }}
                              size="sm"
                            >
                              Αναζήτηση
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <> </>
          )}

          {this.state.showDropdowns == 2 ? (
            <Accordion defaultActiveKey={this.state.instance.Status}>
              {this.state.steps.map((dynamicData) => (
                <Container className="mt-2" fluid>
                  <Row className="mt-5">
                    <Col className="order-xl-1" xl="12">
                      <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                          <Accordion.Toggle
                            as={CardHeader}
                            eventKey={dynamicData.step.Status}
                          >
                            <Row className="align-items-center">
                              {dynamicData.step.EditPermission.includes(
                                this.state.logged_user.PersonRole
                              ) ? (
                                <>
                                  {dynamicData.step.Status ==
                                  this.state.instance.Status ? (
                                    <>
                                      <Col className="text-right" xs="4">
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            this.saveInstance(
                                              dynamicData.step.Status
                                            );
                                          }}
                                          size="sm"
                                        >
                                          Αποθήκευση
                                        </Button>
                                      </Col>
                                    </>
                                  ) : (
                                    <> </>
                                  )}
                                </>
                              ) : (
                                <> </>
                              )}
                            </Row>
                          </Accordion.Toggle>
                        </CardHeader>
                        <Accordion.Collapse eventKey={dynamicData.step.Status}>
                          <CardBody>
                            <Form
                              id={dynamicData.step.Id}
                              onSubmit={this.handleSubmit}
                            >
                              <div className="pl-lg-4">
                                <Row>
                                  {
                                    ((this.state.enabled =
                                      dynamicData.step.Status ===
                                      this.state.instance.Status),
                                    dynamicData.actions.map(
                                      (dynamicActions) => (
                                        <>
                                          {dynamicData.step.ViewPermission.includes(
                                            this.state.logged_user.PersonRole
                                          ) ? (
                                            <>
                                              <Col lg="6">
                                                <FormGroup>
                                                  <label
                                                    className="form-control-label"
                                                    htmlFor="input-username"
                                                  >
                                                    {dynamicActions.action.Name}
                                                  </label>
                                                  {dynamicActions.action.Type ==
                                                  "file" ? (
                                                    <>
                                                      {dynamicData.step.EditPermission.includes(
                                                        this.state.logged_user
                                                          .PersonRole
                                                      ) &&
                                                      this.state.instance
                                                        .IsAssigned &&
                                                      this.state.instance
                                                        .UserAssignedId ==
                                                        this.state.logged_user
                                                          .Id ? (
                                                        <>
                                                          <Row>
                                                            <Col lg="10">
                                                              <label
                                                                className="btn btn-primary"
                                                                htmlFor={
                                                                  dynamicData
                                                                    .step.Id +
                                                                  "/" +
                                                                  dynamicActions
                                                                    .action.Id
                                                                }
                                                              >
                                                                Επιλογή αρχείου
                                                              </label>
                                                              <Input
                                                                className="form-control-alternative"
                                                                // defaultValue={ dynamicActions.data.Data }
                                                                id={
                                                                  dynamicData
                                                                    .step.Id +
                                                                  "/" +
                                                                  dynamicActions
                                                                    .action.Id
                                                                }
                                                                // placeholder={dynamicActions.action.Description}
                                                                style={{
                                                                  visibility:
                                                                    "hidden",
                                                                }}
                                                                type={
                                                                  dynamicActions
                                                                    .action.Type
                                                                }
                                                                disabled={
                                                                  !this.state
                                                                    .enabled
                                                                }
                                                                onChange={(
                                                                  evt
                                                                ) =>
                                                                  this.uploadFile(
                                                                    evt,
                                                                    dynamicData
                                                                      .step.Id +
                                                                      "/" +
                                                                      dynamicActions
                                                                        .action
                                                                        .Id
                                                                  )
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      ) : (
                                                        <> </>
                                                      )}

                                                      <Table
                                                        className="align-items-center table-flush"
                                                        responsive
                                                      >
                                                        <thead className="thead-light">
                                                          <tr>
                                                            <th scope="col">
                                                              Ονομα
                                                            </th>
                                                            <th scope="col">
                                                              Ημερομηνια
                                                              Υποβολης
                                                            </th>
                                                            <th scope="col">
                                                              Ενεργειες
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {this.state.files.map(
                                                            (dynamicFiles) => (
                                                              <>
                                                                {dynamicActions
                                                                  .action.Id ==
                                                                dynamicFiles.ActionId ? (
                                                                  <>
                                                                    <tr>
                                                                      <td>
                                                                        {
                                                                          dynamicFiles.FileName
                                                                        }
                                                                      </td>
                                                                      <td>
                                                                        {Moment(
                                                                          dynamicFiles.UploadDate
                                                                        )
                                                                          .locale(
                                                                            "el"
                                                                          )
                                                                          .format(
                                                                            "dddd, DD MMM YYYY"
                                                                          )}
                                                                      </td>
                                                                      <td>
                                                                        <Button
                                                                          color="primary"
                                                                          onClick={() => this.viewFile(dynamicFiles.Id)}
                                                                          size="sm"
                                                                          title="Προβολή"
                                                                        >
                                                                          <i className="fas fa-eye" />
                                                                        </Button>
                                                                        <Button
                                                                          color="danger"
                                                                          onClick={() => {
                                                                            this.deleteFile(
                                                                              dynamicFiles.Id
                                                                            );
                                                                          }}
                                                                          size="sm"
                                                                          title="Διαγραφή"
                                                                        >
                                                                          <i className="fas fa-trash" />
                                                                        </Button>
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </Table>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {dynamicActions.action
                                                        .Type == "button" ? (
                                                        <>
                                                          {dynamicData.step.EditPermission.includes(
                                                            this.state
                                                              .logged_user
                                                              .PersonRole
                                                          ) &&
                                                          this.state.instance
                                                            .IsAssigned &&
                                                          this.state.instance
                                                            .UserAssignedId ==
                                                            this.state
                                                              .logged_user
                                                              .Id ? (
                                                            <>
                                                              <Col lg="6">
                                                                <Button
                                                                  color="primary"
                                                                  onClick={(
                                                                    evt
                                                                  ) =>
                                                                    this.dynamicCall(
                                                                      dynamicActions
                                                                        .action
                                                                        .Target
                                                                    )
                                                                  }
                                                                  size="lg"
                                                                >
                                                                  {
                                                                    dynamicActions
                                                                      .action
                                                                      .Description
                                                                  }
                                                                </Button>
                                                              </Col>
                                                              {this.state
                                                                .buttonCondition ==
                                                              dynamicData.step
                                                                .Status ? (
                                                                <p>
                                                                  {
                                                                    this.state
                                                                      .buttonMessage
                                                                  }
                                                                </p>
                                                              ) : (
                                                                <>
                                                                  {this.state
                                                                    .buttonCondition ==
                                                                  5 ? (
                                                                    <p>
                                                                      {
                                                                        this
                                                                          .state
                                                                          .buttonMessage
                                                                      }
                                                                    </p>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          ) : (
                                                            <> </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <Input
                                                            className="form-control-alternative"
                                                            defaultValue={
                                                              dynamicActions
                                                                .data.Data
                                                            }
                                                            id={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                            placeholder={
                                                              dynamicActions
                                                                .action
                                                                .Description
                                                            }
                                                            type={
                                                              dynamicActions
                                                                .action.Type
                                                            }
                                                            disabled={
                                                              !this.state
                                                                .enabled
                                                            }
                                                            onChange={(evt) =>
                                                              this.updateInputValue(
                                                                evt
                                                              )
                                                            }
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </FormGroup>
                                              </Col>
                                            </>
                                          ) : (
                                            <> </>
                                          )}
                                        </>
                                      )
                                    ))
                                  }
                                </Row>
                              </div>
                            </Form>
                          </CardBody>
                        </Accordion.Collapse>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              ))}
            </Accordion>
          ) : (
            <> </>
          )}
        </Container>
      </>
    );
  }
}

export default EditMenu;
