import React from "react";
import { Breadcrumb } from "rsuite";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";

import endpoint from "./config.js";

class newAreaCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      area: "",
      code: "",
      state: "",
    };
  }

  saveAreaCode = (ikaspId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/ikasp/newareacode/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Area: this.state.area,
          Code: this.state.code,
          State: this.state.state,
        }),
      }).then((data) => {
        window.location.replace("/admin/ikaspcodes/");
      });
    }
  };

  updateArea(evt) {
    this.setState({
      area: evt.target.value,
    });
  }

  updateCode(evt) {
    this.setState({
      code: evt.target.value,
    });
  }

  updateState(evt) {
    this.setState({
      state: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <InstanceHeader />

        <Container className="mt--4" fluid>
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Δημιουργία νέου κωδικού</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Περιοχή
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              placeholder="Περιοχή"
                              type="text"
                              onChange={(evt) => this.updateArea(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Κωδικός
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              placeholder="Κωδικός"
                              type="number"
                              onChange={(evt) => this.updateCode(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Περιφέρεια
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              placeholder="Περιφέρεια"
                              type="text"
                              onChange={(evt) => this.updateState(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Button
                            color="primary"
                            onClick={() => {
                              this.saveAreaCode("");
                            }}
                            title="Επιλογή"
                          >
                            Προσθήκη <i className="fas fa-tick" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default newAreaCode;
