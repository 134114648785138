import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import endpoint from "./config.js";
import axios from "axios";
import { MDBDataTable } from "mdbreact";

import "../../assets/css/datatables.css";

import EVOMDBDataTableHelper from "../../components/common/evo.mdbDatatable-helper";

class IndustrialCLients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      industrials: [],
      user: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      axios
        .get(process.env.REACT_APP_API_LINK + "/api/industrials/all/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          let instances2 = res.data;
          function mapper(value) {
            return {
              InstanceId: value[1].InstanceId,
              IndustrialName: value[0].IndustrialName,
              SEDDCode: value[0].SEDDCode,
            };
          }
          instances2 = res.data.map(mapper);
          res.data = instances2;

          instances2.forEach(function (part, index) {
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/admin/details/" + this[index].InstanceId}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button>
                <Button
                  color="primary"
                  href={"/admin/instance/" + part.InstanceId}
                  size="sm"
                >
                  Βήματα Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);

          EVOMDBDataTableHelper.GridFilter.setStateFilters(
            this,
            "mdbdttable",
            res.data,
            ["SEDDCode"]
          );

          this.state.datas = {
            columns: [
              {
                label: "Ονομα ▼",
                field: "IndustrialName",
                sort: "asc",
                width: 100,
              },

              {
                label: "ΣΕΔΔ ▼",
                field: "SEDDCode",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ενεργειες ▼",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const industrials = res.data;
          this.setState({
            industrials: industrials,
            isLoaded: true,
          });
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0"> Βιομηχανικοί Πελάτες </h3>
                    </div>
                    <div className="col text-right">
                      {/* {this.state.user.PersonRole == 'Admin' ? 
                        <>
                          <Button
                            color="success"
                            href={"/admin/newuser"}
                            size="sm"
                          >
                            Προσθήκη νέου
                          </Button>
                        </> : <> </>} */}
                    </div>
                  </Row>
                  <Row>
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "SEDDCode",
                          fieldTitle: "Κωδικός ΣΕΔΔ",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IndustrialCLients;
