import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class OldInstances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      instances: [],
      user: [],
    };
  }

  async componentDidMount() {
    fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/excel/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            instances: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleClick = (instanceId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/" +
            instanceId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Αιτήσεις</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <Button
                            color="success"
                            href="/admin/newinstance"
                            size="sm"
                          >
                            Προσθήκη νέας αίτησης
                          </Button>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Α/Α</th>
                      <th scope="col">Ονομα</th>
                      <th scope="col">Επιθετο</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.instances.map((dynamicData) => (
                      <tr>
                        <th scope="row"> {dynamicData.SerialNumber}</th>
                        <td> {dynamicData.Name} </td>
                        <td> {dynamicData.LastName} </td>
                        <td>
                          <Button
                            color="primary"
                            href={"/admin/oldinstance/" + dynamicData.Id}
                            size="sm"
                            title="Προβολή"
                          >
                            <i className="fas fa-eye" />
                          </Button>
                          {/* {this.state.user.PersonRole == 'Admin' ? 
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => { this.handleClick(dynamicData.Id) }}
                                  size="sm"
                                  title="Διαγραφή"
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </> : <> </>} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default OldInstances;
