import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function getAllSuppliers() {
  var route = "/api/suppliers/all/";
  return getAsync(route);
}

export async function postSuppliersRequest({
  option,
  dateModal,
  reasonModal,
  SerialNumber,
}) {
  var route = "/api/suppliers/request/";
  let data = {
    option: option,
    dateModal: dateModal,
    reasonModal: reasonModal,
    SerialNumber: SerialNumber,
  };
  return postAsync(route, data);
}

export async function postSuppliersSavedata({
  serialNumber,
  name,
  lastName,
  phoneNumber,
  address,
  city,
  meterPower,
  meterType,
  afm,
  constype,
}) {
  var route = "/api/suppliers/savedata/";
  let data = {
    SerialNumber: serialNumber,
    Name: name,
    LastName: lastName,
    phoneNumber: phoneNumber,
    Address: address,
    City: city,
    MeterPower: meterPower,
    MeterType: meterType,
    afm: afm,
    ConsumerType: constype,
  };
  return postAsync(route, data);
}

export async function postSuppliersCrmSaveData({
  serialNumber,
  name,
  lastName,
  phoneNumber,
  address,
  city,
  meterPower,
  meterCount,
  dateSucc,
  meterType,
  afm,
  constype,
  stopDate,
  stopMeterCount,
  restartDate,
  restartMeterCount,
  stopRepresentDate,
  stopRepresentMeterCount,
}) {
  var route = "/api/suppliers/crmsavedata/";
  let data = {
    SerialNumber: serialNumber,
    Name: name,
    LastName: lastName,
    phoneNumber: phoneNumber,
    Address: address,
    City: city,
    MeterPower: meterPower,
    MeterType: meterType,
    ConsumerType: constype,
    MeterCount: meterCount,
    DateSucc: dateSucc,
    afm: afm,
    StopDate: stopDate,
    StopMeterCount: stopMeterCount,
    RestartDate: restartDate,
    RestartMeterCount: restartMeterCount,
    StopRepresentDate: stopRepresentDate,
    StopRepresentMeterCount: stopRepresentMeterCount,
  };
  return postAsync(route, data);
}

export async function postSuppliersSuccession({
  serialNumber,
  name,
  lastName,
  phoneNumber,
  address,
  city,
  meterPower,
  meterType,
  afm,
  constype,
}) {
  var route = "/api/suppliers/succession/";
  let data = {
    SerialNumber: serialNumber,
    Name: name,
    LastName: lastName,
    phoneNumber: phoneNumber,
    Address: address,
    City: city,
    MeterPower: meterPower,
    MeterType: meterType,
    afm: afm,
    ConsumerType: constype,
  };
  return postAsync(route, data);
}

export async function getSuppliersProfile(profileId) {
  var route = "/api/suppliers/profile/" + profileId + "/";
  return getAsync(route);
}

export async function getSuppliersHistory(profileId) {
  var route = "/api/suppliers/history/" + profileId + "/";
  return getAsync(route);
}

export async function getSupplierByInstanceId(instanceId) {
  var route = "/api/suppliers/instance/" + instanceId + "/";
  return getAsync(route);
}
