import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      instances: [],
    };
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/all/", {
      method: "get",
      headers: new Headers({
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJla29sYWl0aXMiLCJlbWFpbCI6ImVrb2xhaXRpc0Bpa2FwcHMuZ3IiLCJyb2xlIjoiQWRtaW5pc3RyYXRvciIsIm5iZiI6MTYxMTY0NzY2OSwiZXhwIjoxNjEyNTExNjY5LCJpYXQiOjE2MTE2NDc2Njl9.4MDyYWbdoNSSBBd9fRfffrqsedXYIzB2rk5ECGSlEFw",
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            instances: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Αρχείο Δραστηριοτήτων</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Α/Α</th>
                      <th scope="col">Αριθμος Πρωτοκολλου</th>
                      <th scope="col">Προγραμμα</th>
                      <th scope="col">Κατασταση</th>
                      <th scope="col">Χρηστης</th>
                      <th scope="col">Ανατεθηκε σε</th>
                      <th scope="col">Ενεργειες</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.instances.map((dynamicData) => (
                      <tr>
                        <th scope="row">
                          {dynamicData.serialNumber}/{new Date().getFullYear()}
                        </th>
                        <td> {dynamicData.protocolNumber} </td>
                        <td>{dynamicData.workflow.name}</td>
                        <td>{dynamicData.status}</td>
                        <td>{dynamicData.user.displayName}</td>
                        <td></td>
                        <td>
                          <Button
                            color="primary"
                            href={"/admin/instance/" + dynamicData.id}
                            size="sm"
                          >
                            Προβολή
                          </Button>
                          <Button
                            color="danger"
                            href={"/admin/instance/" + dynamicData.id}
                            size="sm"
                          >
                            Διαγραφή
                          </Button>
                          <Button
                            color="success"
                            href={"/admin/instance/" + dynamicData.id}
                            size="sm"
                          >
                            Τεχνικό Δελτίο
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Logs;
