import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import Switch from "react-switch";
import "moment/locale/el";

import GenericHeader from "components/Headers/GenericHeader.js";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import DynamicGridView from "../../components/DynamicPages/DynamicGridView/dynamicGridView.js";
import { RedirectHelper } from "../../common/redirectHelper";
import EVOAlertComponent from "components/Abstract/EvoAlertComponent";
import Select from "react-select";

class Meter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      checked: false,
      instances: [],
      details: [],
      user: [],
      serial: "",
      adaptation: "",
      type: "",
      date: "",
      mopdecleration: 0,
      mopyear: new Date().getFullYear(),
      _propertyId: "",
      _updateState: null,
      metricSystemsCategories: [],
      selectedMetricSystemValue: {},
      selectedSEDDIdOption: null,
    };

    this.handleChange =
      this.MeterHelper.MobBillingHelper.setToggleActive.bind(this);
    this.handleChange =
      this.MeterHelper.ActiveHelper.setToggleActive.bind(this);
  }
  SELECTION_OPTIONS = [
    { value: "G6", label: "G6" },
    { value: "G400", label: "G400" },
    { value: "G100", label: "G100" },
    { value: "G25", label: "G25" },
    { value: "ROTARY", label: "ROTARY" },
    { value: "G16", label: "G16" },
    { value: "G1000", label: "G1000" },
    { value: "G1600", label: "G1600" },
    { value: "G65", label: "G65" },
    { value: "G40", label: "G40" },
    { value: "G160", label: "G160" },
    { value: "G4", label: "G4" },
    { value: "G10", label: "G10" },
    { value: "G250", label: "G250" },
    { value: "ROTARY 3", label: "ROTARY 3" },
    { value: "G2500", label: "G2500" },
    { value: "G650", label: "G650" },
  ];

  SEDDID_OPTIONS = [
    { value: "U-0000", label: "U-0000 ΚΕΝΤΡΙΚΑ" },
    { value: "U-2340", label: "U-2340 ΚΑΤΕΡΙΝΗ" },
    { value: "U-3530", label: "U-3530 ΞΑΝΘΗ" },
    { value: "U-0001", label: "U-0001 ΓΡΕΒΕΝΑ" },
    { value: "U-2410", label: "U-2410 ΠΛΑΤΥ" },
    { value: "U-2880", label: "U-2880 ΟΙΝΟΦΥΤΑ" },
    { value: "U-2110", label: "U-2110 ΣΕΡΡΕΣ" },
    { value: "U-7045", label: "U-7045 ΑΓΙΟΙ ΘΕΟΔΩΡΟΙ" },
    { value: "U-0004", label: "U-0004 ΚΑΡΠΕΝΗΣΙ" },
    { value: "U-0006", label: "U-0006 ΛΙΒΑΔΕΙΑ" },
    { value: "U-0005", label: "U-0005 ΑΜΦΙΣΣΑ" },
    { value: "U-2170", label: "U-2170 ΚΑΒΑΛΑ" },
    { value: "U-2180", label: "U-2180 ΚΑΒΑΛΑ ΠΟΛΗ" },
    { value: "U-3630", label: "U-3630 ΑΛΕΞΑΝΔΡΟΥΠΟΛΗ" },
    { value: "U-3580", label: "U-3580 ΚΟΜΟΤΗΝΗ" },
    { value: "U-2550", label: "U-2550 ΚΟΣΜΙΟ" },
    { value: "U-0003", label: "U-0003 ΚΑΣΤΟΡΙΑ" },
    { value: "U-2620", label: "U-2620 ΛΑΜΙΑ" },
    { value: "U-0002", label: "U-0002 ΦΛΩΡΙΝΑ" },
    { value: "U-2060", label: "U-2060 ΚΙΛΚΙΣ" },
    { value: "U-0008", label: "U-0008 ΒΕΡΟΙΑ" },
    { value: "U-2140", label: "U-2140 ΔΡΑΜΑ" },
    { value: "U-0009", label: "U-0009 ΤΡΙΠΟΛΗ" },
    { value: "U-0007", label: "U-0007 ΟΡΕΣΤΙΑΔΑ" },
    { value: "U-0010", label: "U-0010 ΓΙΑΝΝΙΤΣΑ" },
  ];

  NOT_DEFINED = "Δεν έχει οριστεί";
  ADAPTION_OPTIONS = [
    { value: "Δεν υπάρχει", label: "Δεν υπάρχει" },
    { value: "β", label: "Προσθηκη (β)" },
    { value: "Π", label: "Παροχη (Π)" },
  ];

  async componentDidMount() {
    const meterId = this.props.match.params.id;
    let [getUserCurrentAsync, getMeterById, getMetricSystems] =
      await Promise.all([
        ApiCallerHelper.Auth.getUserCurrentAsync(),
        ApiCallerHelper.Meters.getMeterById(meterId),
        ApiCallerHelper.Meters.getMetricSystems(),
      ]);

    let meterResult = getMeterById.data;
    let selectedSEDDIdOption = this.SEDDID_OPTIONS.find(
      (option) => option.value === meterResult.meter.SEDDId
    );
    let meterDateInstalledFormatted = meterResult.meter.DateInstalled.substring(
      0,
      10
    );
    this.setState(
      {
        user: getUserCurrentAsync.data,
        instances: meterResult.instances,
        meterDateInstalledFormatted: meterDateInstalledFormatted,
        meter: meterResult.meter,
        selectedSEDDIdOption: selectedSEDDIdOption,
        mopbilling: meterResult.mopbilling,
        metricSystemsCategories: getMetricSystems.data,
      },
      this.MeterHelper.MetricSystemHelper.setStateSelectedMetricSystemValue
    );
  }

  saveChanges = async () => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      let meterId = this.state.meter.Id;
      let type = this.state.meter.Type;
      let adaptation = this.state.meter.Adaptation;
      let SEDDId = this.state.selectedSEDDIdOption;
      let dateInstalled = this.state.meter.DateInstalled;
      let serialNumber = this.state.meter.SerialNumber;
      let metricSystem = this.state.meter.MetricSystem;
      let metricSystemCategory = this.state.meter.MetricSystemCategory;
      await ApiCallerHelper.Meters.updateMeter(
        meterId,
        type,
        serialNumber,
        adaptation,
        dateInstalled,
        metricSystem,
        metricSystemCategory,
        SEDDId.value
      );
      RedirectHelper.RedirectTo.reloadPage();
    }
  };

  updateSerial(evt) {
    this.state.meter.SerialNumber = evt.target.value;
    this.setState({
      meter: this.state.meter,
    });
  }

  updateAdaptation(evt) {
    this.state.meter.Adaptation = evt?.value ?? "Δεν υπάρχει";
    this.setState({
      meter: this.state.meter,
    });
  }

  updateSelection(selectedOption) {
    this.setState({
      meter: {
        ...this.state.meter,
        Type: selectedOption ? selectedOption.value : null,
      },
    });
  }

  updateSEDDId(selectedSEDDIdOption) {
    this.setState((prevState) => ({
      ...prevState,
      selectedSEDDIdOption,
      meter: {
        ...prevState.meter,
        SEDDId: selectedSEDDIdOption ? selectedSEDDIdOption.value : null,
      },
    }));
  }

  updateDate(evt) {
    this.state.meter.DateInstalled = evt.target.value.replaceAll("-", "/");
    this.setState({
      meter: this.state.meter,
    });
  }

  updateMOPDeclaration(evt) {
    this.setState({
      mopdecleration: evt.target.value,
    });
  }

  updateMOPYear(evt) {
    this.setState({
      mopyear: evt.target.value,
    });
  }

  async saveMOPDeclaration() {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      let meterId = this.state.meter.Id;
      const updateMOPDeclarationResult =
        await ApiCallerHelper.Meters.setMOPDeclaration(
          meterId,
          this.state.mopdecleration,
          this.state.mopyear
        );
      RedirectHelper.RedirectTo.reloadPage();
    }
  }

  isSkipRender() {
    if (!this.state.meter) {
      return true;
    }
    return false;
  }

  isUserAllowedForChanges() {
    return (
      this.state.user.PersonRole == "Admin" ||
      this.state.user.PersonRole == "Mechanic" ||
      this.state.user.PersonRole == "Maintenance"
    );
  }

  refreshState = () => {
    this.componentDidMount();
  };
  MeterHelper = {
    MobBillingHelper: {
      setToggleActive: async () => {
        let isMobBillingEnabled =
          this.MeterHelper.MobBillingHelper.isMobBillingEnabled();
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
          let meterId = this.state.meter.Id;
          await ApiCallerHelper.Meters.setMOPBilling(
            meterId,
            !isMobBillingEnabled
          );
          this.refreshState();
        }
      },
      isMobBillingEnabled: () => {
        let isMobBillingEnabled = false;
        if (this.state.mopbilling) {
          isMobBillingEnabled = true;
        }
        return isMobBillingEnabled;
      },
    },
    ActiveHelper: {
      isMeterActive: () => {
        return this.state.meter.Active;
      },
      getButttonTitle: () => {
        if (this.MeterHelper.ActiveHelper.isMeterActive()) {
          return "Απενεργοποίηση μετρητή";
        }
        return "Ενεργοποίηση μετρητή";
      },
      getButttonColor: () => {
        if (this.MeterHelper.ActiveHelper.isMeterActive()) {
          return "danger";
        }
        return "success";
      },
      setToggleActive: async () => {
        let sendIsActiveOption = 1;
        if (this.MeterHelper.ActiveHelper.isMeterActive()) {
          sendIsActiveOption = 0;
        }
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
          let meterId = this.state.meter.Id;
          await ApiCallerHelper.Meters.setMetterActiveState(
            meterId,
            sendIsActiveOption
          );
          this.refreshState();
        }
      },
    },
    MetricSystemHelper: {
      getMetricSystemCategories: () => {
        if (!this.state.metricSystemsCategories) {
          return [];
        }

        let metricSystemsCategories = this.state.metricSystemsCategories.map(
          (x) => ({
            value: x.MetricSystem,
            label: x.MetricSystem,
          })
        );
        return metricSystemsCategories;
      },
      getMetricSystems: () => {
        if (!this.state.meter || !this.state.metricSystemsCategories) {
          return [];
        }
        let metricSystemCategory = this.state.metricSystemsCategories.find(
          (x) => x.MetricSystem == this.state.meter.MetricSystemCategory
        );
        if (!metricSystemCategory) {
          return [];
        }
        let metricSystems = metricSystemCategory.JsonMetaData.Subcategories.map(
          (x) => ({
            value: x,
            label: x,
          })
        );
        return metricSystems;
      },
      getMetricSystemValue: (metricSystem) => {
        return this.MeterHelper.MetricSystemHelper.getMetricSystems().find(
          (x) => x.value == (metricSystem ?? null)
        );
      },
      updateMetricSystemCategory: (evt) => {
        this.state.meter.MetricSystemCategory = evt?.value ?? this.NOT_DEFINED;
        this.state.meter.MetricSystem = this.NOT_DEFINED;
        this.setState(
          {
            meter: this.state.meter,
          },

          this.MeterHelper.MetricSystemHelper.setStateSelectedMetricSystemValue
        );
      },
      updateMetricSystem: (evt) => {
        let newValue = evt?.value ?? this.NOT_DEFINED;
        this.state.meter.MetricSystem = newValue;
        this.setState(
          {
            meter: this.state.meter,
          },
          this.MeterHelper.MetricSystemHelper.setStateSelectedMetricSystemValue
        );
      },
      setStateSelectedMetricSystemValue: (value) => {
        this.setState({
          selectedMetricSystemValue:
            this.MeterHelper.MetricSystemHelper.getMetricSystemValue(
              value ?? this.state.meter?.MetricSystem ?? null
            ),
        });
      },
    },
  };

  render() {
    if (this.isSkipRender()) {
      return;
    }
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Στοιχεία Μετρητή</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.isUserAllowedForChanges() && (
                        <Button
                          color="primary"
                          onClick={() => {
                            this.saveChanges();
                          }}
                          size="sm"
                        >
                          Αποθήκευση αλλαγών
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικες πληροφοριες
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Σειριακός Αριθμός
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.meter.SerialNumber}
                              id="input-username"
                              placeholder="Σειριακός Αριθμός"
                              type="text"
                              onChange={(evt) => this.updateSerial(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Τύπος Μετρητή
                            </label>
                            <Select
                              id="select-type"
                              options={this.SELECTION_OPTIONS}
                              isClearable={true}
                              placeholder="Επιλέξτε"
                              value={this.SELECTION_OPTIONS.find(
                                (x) => x.value === this.state.meter.Type // Use "Type" instead of "type"
                              )}
                              onChange={(selectedOption) => {
                                this.updateSelection(selectedOption);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ημερομηνία Εγκατάστασης
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-date"
                              type="date"
                              defaultValue={
                                this.state.meterDateInstalledFormatted
                              }
                              // defaultValue={Moment(this.state.date).locale('el').format('YYYY-MM-DD')}
                              onChange={(selectedOption) => {
                                if (
                                  this.updateSelection &&
                                  selectedOption &&
                                  selectedOption.value
                                ) {
                                  this.updateSelection(selectedOption);
                                } else {
                                  // Handle the case when the selection is cleared (null or undefined)
                                  this.updateSelection(null); // or update it to a default value if needed
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Προσθηκη (β) / Παροχη (Π)
                            </label>
                            <Select
                              id="select-adaptation"
                              options={this.ADAPTION_OPTIONS}
                              isClearable={true}
                              placeholder={"Επιλέξτε"}
                              defaultValue={this.ADAPTION_OPTIONS.find(
                                (x) => x.value == this.state.meter.Adaptation
                              )}
                              onChange={(e) => {
                                this.updateAdaptation(e);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Κατηγορία Metric System
                            </label>
                            <Select
                              id="select-metric-system-category"
                              isClearable={true}
                              placeholder="Επιλέξτε"
                              options={this.MeterHelper.MetricSystemHelper.getMetricSystemCategories()}
                              defaultValue={this.MeterHelper.MetricSystemHelper.getMetricSystemCategories().find(
                                (x) =>
                                  x.value ==
                                  this.state.meter.MetricSystemCategory
                              )}
                              onChange={(e) => {
                                this.MeterHelper.MetricSystemHelper.updateMetricSystemCategory(
                                  e
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Metric System
                            </label>
                            <Select
                              id="select-metric-system"
                              isClearable={true}
                              placeholder="Επιλέξτε"
                              options={this.MeterHelper.MetricSystemHelper.getMetricSystems()}
                              value={this.state.selectedMetricSystemValue}
                              onChange={(e) => {
                                this.MeterHelper.MetricSystemHelper.updateMetricSystem(
                                  e
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Κωδικός ΣΕΔΔ
                            </label>
                            <Select
                              id="select-sedd-id"
                              options={this.SEDDID_OPTIONS}
                              isClearable={true}
                              placeholder="Επιλέξτε"
                              value={this.state.selectedSEDDIdOption}
                              onChange={(selectedSEDDIdOption) => {
                                this.updateSEDDId(selectedSEDDIdOption);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <h6 className="heading-small text-muted mb-4">
                      Δυνατοτητα Κατασκευης
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            {this.isUserAllowedForChanges() && (
                              <>
                                <Button
                                  color={this.MeterHelper.ActiveHelper.getButttonColor()}
                                  onClick={() => {
                                    this.MeterHelper.ActiveHelper.setToggleActive();
                                  }}
                                  size="sm"
                                >
                                  {this.MeterHelper.ActiveHelper.getButttonTitle()}
                                </Button>
                              </>
                            )}
                          </FormGroup>
                        </Col>
                        {/* <Col lg="4">
                              <Button
                                color="primary"                              
                              // onClick={ () => {RedirectHelper.RedirectTo.property(this.state.details.PropertyId)}}
                              >
                                Ακίνητο εγκατάστασης
                              </Button>
                            </Col>    */}
                        <Col lg="4"></Col>
                        <Col lg="4">
                          <Row>
                            <label>
                              <span>Τιμολόγηση με ΜΩΠ </span>
                              <Switch
                                onChange={
                                  this.MeterHelper.MobBillingHelper
                                    .setToggleActive
                                }
                                checked={this.MeterHelper.MobBillingHelper.isMobBillingEnabled()}
                              />
                            </label>
                          </Row>
                          {this.MeterHelper.MobBillingHelper.isMobBillingEnabled() && (
                            <>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Έτος Αναφοράς
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.mopyear}
                                  id="input-username"
                                  placeholder="Έτος Αναφοράς"
                                  type="number"
                                  onChange={(evt) => this.updateMOPYear(evt)}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  Αρχικοποίηση ΜΩΠ
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={this.state.mopdecleration}
                                  id="input-username"
                                  placeholder="Αρχικοποίηση ΜΩΠ"
                                  type="number"
                                  onChange={(evt) =>
                                    this.updateMOPDeclaration(evt)
                                  }
                                />
                              </FormGroup>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.saveMOPDeclaration();
                                }}
                                size="sm"
                              >
                                Αποθήκευση ΜΩΠ
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <DynamicGridView
                    gridViewId="4b65f924-28cd-4c75-8593-a206cda2b130"
                    customApiArguments={{
                      arg1: this.state.meter.Id,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.MeterHelper.MobBillingHelper.isMobBillingEnabled() && (
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Καταχωρημένες ΜΩΠ</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <DynamicGridView
                      gridViewId="19e3b71d-3674-4e9e-92ff-f6b889624704"
                      customApiArguments={{
                        arg1: this.state.meter.Ikasp,
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default Meter;
