import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import Moment from "moment";
import "moment/locale/el";

import "../assets/css/datatables.css";

import EVOMDBDataTableHelper from "../components/common/evo.mdbDatatable-helper";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      user_token: "",
      activeNav: 1,
      isLoaded: false,
      instances: [],
      applications: [],
      user: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/workflowinstances/depall/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances2 = res.data;
          instances2.forEach(function (part, index) {
            this[index].NewStatus =
              this[index].Status == 1
                ? "1. Αίτηση Σύνδεσης"
                : this[index].Status == 2
                ? "2. Πρόσθετα Δικαιολογητικά"
                : this[index].Status == 3
                ? "3. Τεχνική Αξιολόγηση Ακινήτου"
                : this[index].Status == 4
                ? "4. Σύνταξη Προσφοράς"
                : this[index].Status == 5
                ? "5. Υπογραφή Σύμβασης"
                : this[index].Status == 6
                ? "6. Μηχ.Ολοκλήρωση & Μελέτη εσωτ. εγκατ."
                : this[index].Status == 7
                ? "7. Σύμβαση με Προμηθευτή"
                : this[index].Status == 8
                ? "8. Αίτημα Αυτοψίας"
                : this[index].Status == 9
                ? "9. Ενεργοποίηση εσωτερικής εγκατάστασης"
                : this[index].Status == 10
                ? "10. Φύλλο Καύσης"
                : this[index].Status == 11
                ? "11. Έκδοση άδειας χρήσης"
                : "";
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/admin/details/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button>
                <Button
                  color="warning"
                  href={"/admin/instance/" + this[index].Id}
                  size="sm"
                >
                  Βήματα Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);

          EVOMDBDataTableHelper.GridFilter.setStateFilters(
            this,
            "mdbdttable",
            res.data,
            ["City", "NewStatus"]
          );

          this.state.datas = {
            columns: [
              {
                label: "Αριθμος Αιτησης ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 100,
              },
              {
                label: "Πολη ▼",
                field: "City",
                sort: "asc",
                width: 100,
              },
              {
                label: "Κατασταση ▼",
                field: "NewStatus",
                sort: "asc",
                width: 150,
              },
              {
                label: "Τελ. Ενημερωση απο ▼",
                field: "EditedBy",
                sort: "asc",
                width: 150,
              },
              ,
              // {
              //   label: 'Ανατεθηκε σε ▼',
              //   field: 'AssignedUser',
              //   sort: 'asc',
              //   width: 150
              // },
              {
                label: "Μεταβαση ▼",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <Header />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Αιτήσεις</h3>
                    </div>
                    <div className="col text-right">
                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <Button
                            color="success"
                            href={"/admin/newinstance"}
                            size="sm"
                          >
                            Δημιουργία νέας
                          </Button>
                        </>
                      ) : (
                        <> </>
                      )}
                      <Button
                        color="primary"
                        href={"/admin/instances"}
                        size="sm"
                      >
                        Προβολή όλων
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <CardHeader>
                  <Row className="mt-3">
                    <Col xs="12">
                      <h3 className="mb-2">Φίλτρα</h3>
                    </Col>
                    <br />
                    <EVOMDBDataTableHelper.GridFilter.GridFilterForClassComponent
                      self={this}
                      dataTableId={"mdbdttable"}
                      dataStateKey={"datas"}
                      fields={[
                        {
                          fieldName: "City",
                          fieldTitle: "ΠΟΛΗ",
                          isIncludeEmptyItem: true,
                        },
                        {
                          fieldName: "NewStatus",
                          fieldTitle: "ΚΑΤΑΣΤΑΣΗ",
                          isIncludeEmptyItem: true,
                        },
                      ]}
                    />
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    name="mdbdttable"
                    data={this.state.datas}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div className="push"></div>
        </Container>
      </>
    );
  }
}

export default Index;
