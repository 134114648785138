import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";
import InstanceHeader from "components/Headers/InstanceHeader.js";

import ApiCallerHelper from "../../api/apiCallerHelper.js";

import { getDateOnly } from "../../common/utils.js";
import { CommonLoading } from "react-loadingg";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import Moment from "moment";
import "moment/locale/el";
import "leaflet/dist/leaflet.css";

import axios from "axios";
import Modal from "react-modal";

import endpoint from "./config.js";

import L from "leaflet";

const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

class InstanceDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      details: [],
      files: [],
      owner: [],
      mechanic: [],
      ikasp: "",
      supplier: "",
      property: "",
      user: [],
      dates: [],
      meter: "",
      price: 0,
      modalFieldName: "",
      modalFieldLastName: "",
      modalFieldEmail: "",
      modalFieldPhone: "",
      yellow: "#FFD712",
      showModal: false,
      showModalProperty: false,
      comment: "",
      datesactiv: "",
      datesaut: "",
      datesmech: "",
      sign: "",
      lat: "",
      lon: "",
      coordinates: [],
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleClosePropertyModal = this.handleClosePropertyModal(this);
  }

  formatDefaultDate = (value) => {
    let result = Moment(value.split("T")[0]).format("DD-MM-YYYY");
    return result;
  };

  parseDates(value, flag) {
    if (value) {
      if (flag) {
        return value.split("T")[0];
      }
      return value;
    }
    return false;
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      let promiseResults = await Promise.all([
        ApiCallerHelper.WorkflowInstances.getWorkflowInstanceById(handle),
        ApiCallerHelper.Files.getAllInstanceFilesByInstanceId(handle),
        ApiCallerHelper.WorkflowInstances.getFundingFlag(handle),
      ]);
      let workflowInstancesDetailsResult = promiseResults[0];
      let instanceFilesResult = promiseResults[1];
      let florinaFlagResult = promiseResults[2];

      //fix after end point updates
      this.setState({
        details: workflowInstancesDetailsResult.data.instance,
        files: instanceFilesResult.data,
        instanceIsCustomFlorinaFlag: florinaFlagResult.data ?? false,
      });

      fetch(
        process.env.REACT_APP_API_LINK + "/api/ikasp/instance/" + handle + "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              ikasp: result,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/property/instance/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            // this.setState({
            //   property: result
            // });
            this.state.property = result;
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/property/coordinates/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.state.lat = parseFloat(result.Lat);
            this.state.lon = parseFloat(result.Lon);
            this.state.coordinates = [this.state.lat, this.state.lon];
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK + "/api/meters/instance/" + handle + "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              meter: result,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/dates/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              datesactiv: getDateOnly(result.activ),
              datesaut: getDateOnly(result.aut),
              datesmech: getDateOnly(result.mech),
              datessign: getDateOnly(result.sign),
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/pricing/instance/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.Price) {
              this.setState({
                price: result.Price.Price,
                datePrice: result.dateCalculated,
              });
            } else {
              this.setState({
                price: 0,
                datePrice: result.dateCalculated,
              });
            }
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/suppliers/instance/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              loading: false,
              supplier: result.SupplierId,
            });
          },
          (error) => {
            this.setState({
              loading: false,
              error,
            });
          }
        );

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/mechanic/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              mechanic: result,
            });
          },
          (error) => {
            this.setState({
              loading: false,
              error,
            });
          }
        );
    }
  }

  handleOpenModal(choice) {
    if (choice == 1) {
      // get owner data
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/ownerdata/" +
          this.state.details.Id +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            modalFieldName: data.name,
            modalFieldLastName: data.lastname,
            modalFieldEmail: data.email,
            modalFieldPhone: data.phone,
            showModal: true,
          });
        });
    } else if (choice == 2) {
      // get mechanic data
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/mechanicdata/" +
          this.state.details.Id +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            modalFieldName: data.name,
            modalFieldLastName: data.lastname,
            modalFieldEmail: data.email,
            modalFieldPhone: data.phone,
            showModal: true,
          });
        });
    } else if (choice == 3) {
      // get property data
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/meterdata/" +
          this.state.details.Id +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            modalFieldName: data.meter_type,
            modalFieldLastName: data.serial,
            showModalProperty: true,
          });
        });
    } else if (choice == 4) {
      this.setState({
        modalFieldName: "test",
        modalFieldLastName: "test",
        modalFieldEmail: "test",
        modalFieldPhone: "test",
        showModal: true,
      });
    }
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleClosePropertyModal() {
    this.setState({ showModalProperty: false });
  }

  recallStep = (status) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/recall/" +
          this.state.details.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  saveChanges = (id) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/updatecomment/",
        {
          method: "PUT",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            InstanceId: this.state.details.Id,
            Comment: this.state.comment,
          }),
        }
      ).then((data) => {
        // window.location.reload(false);
      });
    }
  };

  unblockInstance = (instanceId) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/unblock/" +
          this.state.details.Id +
          "/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  {this.state.details.Status == 6 &&
                  this.state.details.Completed ? (
                    <CardHeader className="bg-red border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            Στοιχεία Αίτησης -- ΥΠΑΝΑΧΩΡΗΣΗ
                          </h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            href={
                              "/admin/instancesettings/" + this.state.details.Id
                            }
                            // style={{ backgroundColor: this.state.yellow }}
                            // color={this.state.yellow}
                            color="primary"
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}

                  {this.state.details.Status < 6 &&
                  this.state.details.Completed ? (
                    <CardHeader className="bg-warning border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Στοιχεία Αίτησης -- ΑΚΥΡΩΣΗ</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            href={
                              "/admin/instancesettings/" + this.state.details.Id
                            }
                            // style={{ backgroundColor: this.state.yellow }}
                            // color={this.state.yellow}
                            color="primary"
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}

                  {this.state.details.Status == 11 &&
                  this.state.details.Completed ? (
                    <CardHeader className="bg-green border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            Στοιχεία Αίτησης -- ΟΛΟΚΛΗΡΩΜΕΝΗ
                          </h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            href={
                              "/admin/instancesettings/" + this.state.details.Id
                            }
                            // style={{ backgroundColor: this.state.yellow }}
                            // color={this.state.yellow}
                            color="primary"
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}

                  {this.state.details.Status > 6 &&
                  this.state.details.Completed ? (
                    <CardHeader className="bg-red border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Στοιχεία Αίτησης -- ΑΚΥΡΩΣΗ</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            href={
                              "/admin/instancesettings/" + this.state.details.Id
                            }
                            // style={{ backgroundColor: this.state.yellow }}
                            // color={this.state.yellow}
                            color="primary"
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}

                  {this.state.instanceIsCustomFlorinaFlag ? (
                    <CardHeader className="bg-green border-0">
                      <Row className="align-it ems-center">
                        <Col xs="8">
                          <h3 className="mb-0">
                            Αίτηση που αφορά Χρηματοδότηση
                          </h3>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}

                  {!this.state.details.Completed ? (
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Στοιχεία Αίτησης</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            href={
                              "/admin/instancesettings/" + this.state.details.Id
                            }
                            // style={{ backgroundColor: this.state.yellow }}
                            // color={this.state.yellow}
                            color="primary"
                            size="sm"
                          >
                            Ρυθμίσεις
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Έναρξης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={Moment(
                                  this.state.details.StartedAt
                                )
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός ΗΚΑΣΠ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.ikasp.Ikasp}
                                placeholder="Αριθμός ΗΚΑΣΠ"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Κατάσταση Αίτησης
                              </label>
                              {this.state.details.Status == 1 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="1. Αίτηση Σύνδεσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 2 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="2. Πρόσθετα Δικαιολογητικά"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 3 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="3. Τεχνική Αξιολόγηση Ακινήτου"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 4 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="4. Σύνταξη Προσφοράς"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 5 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="5. Υπογραφή Σύμβασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 6 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="6. Μηχανική Ολοκλήρωση και Μελέτη εσωτερικής εγκατάστασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 7 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="7. Σύμβαση με Προμηθευτή"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 8 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="8. Αίτημα Αυτοψίας"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 9 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="9. Ενεργοποίηση εσωτερικής εγκατάστασης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 10 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="10. Φύλλο Καύσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                              {this.state.details.Status == 11 ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  defaultValue="11. Έκδοση άδειας χρήσης"
                                  placeholder="Κατάσταση Αίτησης"
                                  type="text"
                                />
                              ) : (
                                <> </>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Τέλη σύνδεσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.price}
                                placeholder="Τέλη σύνδεσης"
                                type="text"
                                // disabled={true}
                                // onChange={evt => this.updatePhone(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Σημαντικες Ημερομηνιες
                        </h6>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Υπογραφής Σύμβασης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.datessign}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Μηχανικής Ολοκλήρωσης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.datesmech}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Αιτήματος Αυτοψίας
                              </label>

                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.datesaut}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Ενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.datesactiv}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Επιλογες Αιτησης
                            </h6>
                            <Button
                              color="primary"
                              href={"/admin/instance/" + this.state.details.Id}
                              size="sm"
                            >
                              Βήματα αίτησης
                            </Button>
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                <Button
                                  color="warning"
                                  href={
                                    "/admin/commenthistory/" +
                                    this.state.details.Id
                                  }
                                  size="sm"
                                >
                                  Ιστορικό σχολίων
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Sales" ? (
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.recallStep();
                                  }}
                                  size="sm"
                                >
                                  Ανάκληση πρόσφατου βήματος
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ? (
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.unblockInstance();
                                  }}
                                  size="sm"
                                >
                                  Ξεμπλοκάρισμα βήματος
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                          </Col>

                          <Col md="6">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολιο Αιτησης
                            </h6>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              defaultValue={this.state.details.Comments}
                              placeholder="Σχόλιο"
                              type="textarea"
                              // disabled={true}
                              onChange={(evt) => this.updateComment(evt)}
                            />
                            <Button
                              color="primary"
                              onClick={() => {
                                this.saveChanges();
                              }}
                              size="sm"
                            >
                              Αποθήκευση σχολίου
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Στοιχεια συμβεβλημενων προσωπων
                            </h6>
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ||
                            this.state.user.PersonRole == "Finance" ? (
                              <>
                                <Button
                                  color="primary"
                                  href={
                                    "/admin/consumer/" +
                                    this.state.details.UserId
                                  }
                                  size="sm"
                                >
                                  Στοιχεία Καταναλωτή
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Guest' || this.state.user.PersonRole == 'Sales' || this.state.user.PersonRole == 'Marketing' || this.state.user.PersonRole == 'Maintenance' || this.state.user.PersonRole == 'Mechanic' || this.state.user.PersonRole == 'Finance' ?
                              <>
                                <Button
                                  color="primary"
                                  onClick={evt => this.handleOpenModal(1)}
                                  size="sm"
                                >
                                  Στοιχεία Ιδιοκτήτη
                                </Button>
                              </> : <> </>} */}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                {this.state.mechanic == "-1" ? (
                                  <> </>
                                ) : (
                                  <Button
                                    color="primary"
                                    href={
                                      "/admin/mechanic/" + this.state.mechanic
                                    }
                                    size="sm"
                                  >
                                    Στοιχεία Μηχανικού
                                  </Button>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ? (
                              <>
                                {this.state.supplier == "Δεν υπάρχει ακόμα" ? (
                                  <> </>
                                ) : (
                                  <>
                                    <Button
                                      color="primary"
                                      href={
                                        "/admin/supplier/" + this.state.supplier
                                      }
                                      size="sm"
                                    >
                                      Στοιχεία Προμηθευτή
                                    </Button>
                                  </>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                {this.state.property.details.Id == "-1" ? (
                                  <> </>
                                ) : (
                                  <Button
                                    color="primary"
                                    href={
                                      "/admin/property/" +
                                      this.state.property.details.Id
                                    }
                                    size="sm"
                                  >
                                    Στοιχεία Ακινήτου
                                  </Button>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.user.PersonRole == "Admin" ||
                            this.state.user.PersonRole == "Guest" ||
                            this.state.user.PersonRole == "Sales" ||
                            this.state.user.PersonRole == "Marketing" ||
                            this.state.user.PersonRole == "Maintenance" ||
                            this.state.user.PersonRole == "Mechanic" ? (
                              <>
                                {this.state.meter.meter ==
                                "Δεν υπάρχει ακόμα μετρητής για την αίτηση." ? (
                                  <> </>
                                ) : (
                                  <Button
                                    color="primary"
                                    href={
                                      "/admin/meter/" + this.state.meter.meter
                                    }
                                    size="sm"
                                  >
                                    Στοιχεία Μετρητή
                                  </Button>
                                )}
                              </>
                            ) : (
                              <> </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {this.state.user.PersonRole == "Admin" ||
            this.state.user.PersonRole == "Guest" ||
            this.state.user.PersonRole == "Sales" ||
            this.state.user.PersonRole == "Maintenance" ||
            this.state.user.PersonRole == "Mechanic" ||
            this.state.user.PersonRole == "Marketing" ? (
              <>
                <Row className="mt-5">
                  <Col className="mb-5 mb-xl-0" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Έγγραφα αίτησης</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Ονομα αρχειου</th>
                            <th scope="col">Ημερομηνια Αναρτησης</th>
                            <th scope="col">Ενεργειες</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.files.map((dynamicFiles) => (
                            <tr>
                              <td>{dynamicFiles.FileName}</td>
                              <td>
                                {Moment(dynamicFiles.UploadDate)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </td>
                              <td>
                                <Button
                                  color="primary"
                                  onClick={() => this.viewFile(dynamicFiles.Id)}
                                  size="sm"
                                  title="Προβολή"
                                >
                                  <i className="fas fa-eye" />
                                </Button>
                                {this.state.user.PersonRole == "Admin" ? (
                                  <>
                                    <Button
                                      color="danger"
                                      // href={dynamicFiles.Link}
                                      size="sm"
                                      title="Διαγραφή"
                                    >
                                      <i className="fas fa-trash" />
                                    </Button>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>

                  <Col className="mb-5 mb-xl-0" xl="6">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Στίγμα αίτησης</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <MapContainer
                          center={this.state.coordinates}
                          zoom={13}
                          scrollWheelZoom={false}
                          style={{ height: "50vh", width: "50wh" }}
                        >
                          <TileLayer
                            attribution="&copy; EvolutionProjects+"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          {/* <Marker position={[41.1506139, 24.1509843]} icon={customMarker}>
                            <Popup>
                              A/A : {this.state.details.SerialNumber} <br /> HKAΣΠ : {this.state.ikasp.Ikasp}
                            </Popup>
                          </Marker> */}
                          <Marker
                            position={this.state.coordinates}
                            icon={customMarker}
                          >
                            <Popup>
                              A/A : {this.state.details.SerialNumber} <br />
                              HKAΣΠ : {this.state.ikasp.Ikasp}
                            </Popup>
                          </Marker>
                        </MapContainer>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              <> </>
            )}
          </Container>

          <Modal
            isOpen={this.state.showModal}
            ariaHideApp={false}
            contentLabel="Info Modal"
            style={{
              overlay: {},
              content: {
                position: "absolute",
                top: "30%",
                left: "30%",
                marginTop: "-50px",
                marginLeft: "-30px",
                width: "35%",
                height: "40%",
              },
            }}
          >
            <label className="form-control-label" htmlFor="namemodal">
              Όνομα
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldName}
              id="namemodal"
              placeholder=""
            />
            <label className="form-control-label" htmlFor="lastmodal">
              Επίθετο
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldLastName}
              id="lastmodal"
              placeholder=""
            />
            <label className="form-control-label" htmlFor="phonemodal">
              Τηλέφωνο
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldPhone}
              id="phonemodal"
              placeholder=""
            />
            <label className="form-control-label" htmlFor="emailmodal">
              Email
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldEmail}
              id="emailmodal"
              placeholder=""
            />
            <p></p>
            <Button
              color="primary"
              onClick={this.handleCloseModal}
              size="sm"
              title="Κλείσιμο"
            >
              <i className="fas fa-window-close" /> &nbsp; Κλείσιμο
            </Button>
          </Modal>

          <Modal
            isOpen={this.state.showModalProperty}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            style={{
              overlay: {},
              content: {
                position: "absolute",
                top: "30%",
                left: "30%",
                marginTop: "-50px",
                marginLeft: "-30px",
                width: "35%",
                height: "40%",
              },
            }}
          >
            <label className="form-control-label" htmlFor="input-username">
              Τύπος Μετρητή
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldName}
              id="comment"
              placeholder=""
            />
            <label className="form-control-label" htmlFor="input-username">
              Σειριακός Αριθμός
            </label>
            <Input
              className="form-control-alternative"
              defaultValue={this.state.modalFieldLastName}
              id="comment"
              placeholder=""
            />
            <p></p>
            <Button
              color="primary"
              onClick={this.handleClosePropertyModal}
              size="sm"
              title="Κλείσιμο"
            >
              <i className="fas fa-window-close" /> &nbsp; Κλείσιμο
            </Button>
          </Modal>
        </>
      );
    }
  }
}

export default InstanceDetails;
