import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import Moment from "moment";
import "moment/locale/el";
import Iframe from "react-iframe";

import axios from "axios";
import Modal from "react-modal";

import endpoint from "./config.js";
import { ApiCallerHelper } from "../../api/apiCallerHelper";

import DynamicGridView from "../../components/DynamicPages/DynamicGridView/dynamicGridView.js";
import { RedirectHelper } from "../../common/redirectHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
class Property extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      instances: [],
      details: [],
      user: [],
      meters: [],
      comment: "",
      floors: 0,
      apartments: 0,
      instanceSerialNumber: 0,
      showModal: false,
      showModalEdit: false,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      const result = await ApiCallerHelper.Property.getPropertyDetails(handle);

      this.setState({
        details: result.data.details,
        instances: result.data.instances,
        meters: result.data.meters,
        floors: result.data.details.Floors,
        apartments: result.data.details.Apartments,
        comment: result.data.details.Comment,
      });
    }

    const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync();
    this.setState({
      isLoaded: true,
      user: currentUserResult.data,
    });
  }

  messageHandler = (event) => {
    const { action, key, value, data } = event.data;
    if (action && action == "returnData") {
      let GISDataAddress = null;
      data.forEach((item) => {
        if (item["address"] != null) {
          GISDataAddress = item;
        }
      }, this);

      if (GISDataAddress) {
        this.updateProperty(GISDataAddress);
      }
    }
  };

  async updateProperty(GISDataAddress) {
    const updateResult = await ApiCallerHelper.Property.updatePropertyGIS(
      this.state.details.Id,
      GISDataAddress
    );
  }

  handleOpenModal(evt) {
    this.setState({
      instanceSerialNumber: 0,
      showModal: true,
    });
  }

  handleEditModal(evt) {
    this.setState({
      showModalEdit: true,
    });
  }

  async handleCloseModalSubmit() {
    const transferResult =
      await ApiCallerHelper.Property.transferInstanceProperty(
        this.state.details.Id,
        this.state.instanceSerialNumber
      );

    this.setState({ showModal: false });
  }

  async handleCloseEditModalSubmit() {
    const iframe = document.getElementById("iframe-map");
    iframe.contentWindow.postMessage(
      {
        action: "get",
        key: "GISData",
      },
      "*"
    );

    window.addEventListener("message", this.messageHandler, false);

    this.setState({ showModalEdit: false });
    window.location.reload();
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleCloseEditModal() {
    this.setState({ showModalEdit: false });
  }

  updateInstanceSerial(evt) {
    this.setState({
      instanceSerialNumber: evt.target.value,
    });
  }

  updateFloors(evt) {
    this.setState({
      floors: evt.target.value,
    });
  }

  updateApartments(evt) {
    this.setState({
      apartments: evt.target.value,
    });
  }

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  async saveChanges(option) {
    if (option == 1) {
      var value = String(this.state.floors);
    } else if (option == 2) {
      var value = String(this.state.apartments);
    } else if (option == 3) {
      var value = this.state.comment;
    }

    if (localStorage.getItem("token")) {
      const saveChangesResult =
        await ApiCallerHelper.Property.savePropertyChanges(
          this.state.details.Id,
          option,
          value
        );
      window.location.reload(false);
    }
  }

  async inabilityChange(option) {
    if (localStorage.getItem("token")) {
      const chnageInabilityResult =
        await ApiCallerHelper.Property.changePropertyInability(
          this.state.details.Id,
          option
        );
      window.location.reload(false);
    }
  }

  async deleteProperty() {
    if (localStorage.getItem("token")) {
      const deletePropertyResult =
        await ApiCallerHelper.Property.deleteProperty(this.state.details.Id);
      window.location.replace("/admin/index");
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Ακινήτου</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {this.state.user.PersonRole == "Admin" ||
                        this.state.user.PersonRole == "Mechanic" ? (
                          <>
                            {this.state.details.Inability == 1 ? (
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.inabilityChange(0);
                                  }}
                                  size="sm"
                                >
                                  Ορισμός τεχνικής αδυναμίας
                                </Button>
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    this.inabilityChange(2);
                                  }}
                                  size="sm"
                                >
                                  Αναθεώρηση σημείωσης
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.details.Inability == 0 ? (
                              <>
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.inabilityChange(1);
                                  }}
                                  size="sm"
                                >
                                  Ορισμός δυνατότητας κατασκευής
                                </Button>
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    this.inabilityChange(2);
                                  }}
                                  size="sm"
                                >
                                  Αναθεώρηση σημείωσης
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                            {this.state.details.Inability == 2 ? (
                              <>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.inabilityChange(0);
                                  }}
                                  size="sm"
                                >
                                  Ορισμός τεχνικής αδυναμίας
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() => {
                                    this.inabilityChange(1);
                                  }}
                                  size="sm"
                                >
                                  Ορισμός δυνατότητας κατασκευής
                                </Button>
                              </>
                            ) : (
                              <> </>
                            )}
                          </>
                        ) : (
                          <> </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Διεύθυνση
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.Address}
                                id="input-username"
                                placeholder="Διεύθυνση"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Αριθμός
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.AddressNum}
                                id="input-username"
                                placeholder="Αριθμός"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ταχυδρομικός Κώδικας
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.PostalCode}
                                id="input-username"
                                placeholder="ΤΚ"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Πόλη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.City}
                                id="input-username"
                                placeholder="Πόλη"
                                type="text"
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <h6 className="heading-small text-muted mb-4">
                        Επιπλεον Πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όροφοι
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.Floors}
                                id="input-username"
                                placeholder="Όροφοι"
                                type="text"
                                onChange={(evt) => this.updateFloors(evt)}
                              />
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => {
                                  this.saveChanges(1);
                                }}
                                size="sm"
                              >
                                Αποθήκευση ορόφων
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Διαμερίσματα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.Apartments}
                                id="input-username"
                                placeholder="Διαμερίσματα"
                                type="text"
                                onChange={(evt) => this.updateApartments(evt)}
                              />
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={() => {
                                  this.saveChanges(2);
                                }}
                                size="sm"
                              >
                                Αποθήκευση διαμερισμάτων
                              </Button>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            {this.state.details.Exported ? (
                              <FormGroup>
                                <Button color="success" size="lg">
                                  Έχει εξαχθεί για σημείωση
                                </Button>
                              </FormGroup>
                            ) : (
                              <FormGroup>
                                <Button color="danger" size="lg">
                                  Δεν έχει εξαχθεί για σημείωση
                                </Button>
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <h6 className="heading-small text-muted mb-4">
                                Δυνατοτητα Κατασκευης
                              </h6>
                              {this.state.details.Inability == 0 ? (
                                <>
                                  <Button color="danger" size="sm">
                                    Αδυναμία Κατασκευής
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {this.state.details.Inability == 1 ? (
                                    <>
                                      <Button color="success" size="sm">
                                        Δυνατότητα Κατασκευής
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button color="warning" size="sm">
                                        Υπό εξέταση
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <h6 className="heading-small text-muted mb-4">
                              Σχολιο Ακινητου
                            </h6>
                            <FormGroup>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.Comment}
                                placeholder="Σχόλιο"
                                type="textarea"
                                onChange={(evt) => this.updateComment(evt)}
                              />
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.saveChanges(3);
                                }}
                                size="sm"
                              >
                                Αποθήκευση σχολίου
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales'} */}
            {this.state.user.PersonRole == "Admin" ? (
              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardBody>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <Button
                              color="success"
                              onClick={() => {
                                this.handleOpenModal();
                              }}
                              size="sm"
                            >
                              Μεταφορά Αίτησης στο παρόν ακίνητο
                            </Button>
                          </Col>
                          {this.state.instances.length == 0 &&
                          this.state.meters.length == 0 ? (
                            <>
                              <Col lg="3">
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    this.handleEditModal();
                                  }}
                                  size="sm"
                                >
                                  Τροποποίηση Ακινήτου
                                </Button>
                              </Col>
                              <Col lg="3">
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.deleteProperty();
                                  }}
                                  size="sm"
                                >
                                  Διαγραφή Ακινήτου
                                </Button>
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* <Button
                              color="success"
                              href="/admin/newinstance"
                              size="sm"
                          >
                              Προσθήκη νέας σύνδεσης
                          </Button> */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <DynamicGridView
                      gridViewId="0fc0498e-16ea-48ec-aa3a-8413bd825bcf"
                      customApiArguments={{
                        arg1: this.props.match.params.id,
                      }}
                      customActionsCallbackMethods={{
                        callbackA: (value) => {
                          RedirectHelper.RedirectTo.details(value.InstanceId);
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Εγκατεστημένοι μετρητές</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <DynamicGridView
                      gridViewId="b92bb719-a2db-4e17-91c3-eb6044040f9b"
                      customApiArguments={{
                        arg1: this.props.match.params.id,
                      }}
                      customActionsCallbackMethods={{
                        callbackA: (value) => {
                          RedirectHelper.RedirectTo.details(value.InstanceId);
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {},
              content: {
                position: "absolute",
                top: "40%",
                left: "30%",
                marginTop: "-50px",
                marginLeft: "-30px",
                width: "35%",
                height: "30%",
              },
            }}
          >
            <label className="form-control-label" htmlFor="input-username">
              Αριθμός Αίτησης προς μεταφορά
            </label>
            <Input
              className="form-control-alternative"
              defaultValue=""
              id="comment"
              placeholder="Αριθμός Αίτησης"
              onChange={(evt) => this.updateInstanceSerial(evt)}
            />
            <p></p>
            <Button
              color="primary"
              onClick={() => this.handleCloseModalSubmit()}
              size="sm"
            >
              Μεταφορά Αίτησης
            </Button>
            <button onClick={() => this.handleCloseModal()}>Κλείσιμο</button>
          </Modal>

          <Modal
            isOpen={this.state.showModalEdit}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {},
              content: {
                position: "absolute",
                top: "10%",
                left: "20%",
                marginTop: "-20px",
                marginLeft: "-20px",
                width: "68%",
                height: "80%",
              },
            }}
          >
            <Iframe
              url={GlobalParametersHelper.getDedaMapLocation()}
              width="1010px"
              height="490px"
              id="iframe-map"
              className=""
              display="block"
              position="relative"
              scrolling="no"
            />
            <Button
              color="primary"
              onClick={() => this.handleCloseEditModalSubmit()}
              size="sm"
            >
              Ανανέωση Τοποθεσίας Ακινήτου
            </Button>
            <button onClick={() => this.handleCloseEditModal()}>
              Κλείσιμο
            </button>
          </Modal>
        </>
      );
    }
  }
}

export default Property;
