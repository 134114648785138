import React, { useState, Fragment } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";
import Modal from "react-modal";

import endpoint from "./config.js";
import ApiCallerHelper from "../../api/apiCallerHelper.js";
import EVOAlertComponent from "../../components/Abstract/EvoAlertComponent";
import { isInputHtmlType } from "../../common/enums/dynamicStepActionTypes.enum";
import TechnicalDescription from "../custom/florina/technicalDescriptionComponent/TechnicalDescription";
import {
  SetTechnicalDescriptionStepActionData,
  GetTechnicalDescriptionStepActionIsValidForSubmit,
  TechnicalDescriptionActionIdEnum,
} from "../custom/florina/technicalDescriptionComponent/TechnicalDescription.Helper";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
  Alert,
  Util,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";
import { getDateOnlyForUi } from "../../common/utils";
import WorkflowInstancesHelper from "../../common/workflowInstancesHelper";
import RedirectHelper from "../../common/redirectHelper";
import DynamicButtonsActionsHelper from "../../common/DynamicButtonsActionsHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
class Instance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      instance: null,
      workflow: [],
      user: [],
      logged_user: [],
      steps: [],
      files: [],
      enabled: true,
      modalFieldName: "",
      modalFieldStepId: "",
      modalFieldActionId: "",
      fileUpload: null,
      comment: "",
      buttonCondition: 0,
      buttonMessage: "",
      removeActions: 0,
      actionMessages: [],
    };
    // this.handleSubmit = this.handleSubmit.bind(this);

    this.handleCloseModalSubmit = this.handleCloseModalSubmit.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  async componentDidMount() {
    const instanceId = this.props.match.params.id;
    let promiseResults = await Promise.all([
      ApiCallerHelper.WorkflowInstances.getWorkflowInstanceById(instanceId),
      ApiCallerHelper.Files.getAllInstanceFilesByInstanceId(instanceId),
      ApiCallerHelper.WorkflowInstances.getFundingFlag(instanceId),
    ]);
    let workflowInstancesResult = promiseResults[0];
    let fileStatusResult = promiseResults[1];
    let florinaFlagResult = promiseResults[2];

    this.setState({
      instance: workflowInstancesResult.data.instance,
      instanceIsCustomFlorinaFlag: florinaFlagResult.data ?? false,
      workflow: workflowInstancesResult.data.workflow,
      user: workflowInstancesResult.data.user,
      logged_user: workflowInstancesResult.data.logged_user,
      steps: workflowInstancesResult.data.stepsInfused,
      showModal: false,
      modalFieldName: "",
      modalFieldStepId: "",
      modalFieldActionId: "",
      fileUpload: null,
      comment: "",
      files: fileStatusResult.data,
    });
  }

  getDateValueOrValueIfNotDate(value, type) {
    if (type != "date") {
      return value;
    }
    let result = getDateOnlyForUi(value);
    return result;
  }

  handleOpenModal(evt, fieldName) {
    var res = evt.split("/");
    this.setState({
      modalFieldName: fieldName,
      modalFieldStepId: res[0],
      modalFieldActionId: res[1],
      showModal: true,
    });
  }

  handleCloseModalSubmit() {
    fetch(
      process.env.REACT_APP_API_LINK + "/api/workflowinstances/addcomment/",
      {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          InstanceId: this.state.instance.Id,
          StepId: this.state.modalFieldStepId,
          ActionId: this.state.modalFieldActionId,
          Comment: this.state.comment,
        }),
      }
    ).then((data) => {});

    this.setState({ showModal: false });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  saveInstance = async (stepStatus) => {
    // let hasStepStatusRequiredFieldMissing = WorkflowInstancesHelper.Action.IsAllRequiredFieldsInStepWithValue(this.state.steps, stepStatus, this.state.files);
    // if (hasStepStatusRequiredFieldMissing){
    //     EVOAlertComponent.Alert({message:"Το πεδίο [" + hasStepStatusRequiredFieldMissing.name + "] είναι κενό"});
    // }
    // return;

    let workflowInstanceId = this.state.instance.Id;
    var data = this.state.steps[stepStatus - 1];
    let result =
      await ApiCallerHelper.WorkflowInstances.postUpdateWorkflowInstances(
        workflowInstanceId,
        data
      );
    RedirectHelper.RedirectTo.reloadPageFromCache();
  };

  approveInstance = async (stepStatus) => {
    let instanceIsCustomFlorinaFlag =
      this.state.instanceIsCustomFlorinaFlag ?? false;
    let hasStepStatusRequiredFieldMissing =
      WorkflowInstancesHelper.Action.IsAllRequiredFieldsInStepWithValue(
        this.state.steps,
        stepStatus,
        this.state.files,
        instanceIsCustomFlorinaFlag
      );
    if (hasStepStatusRequiredFieldMissing) {
      EVOAlertComponent.Alert({
        message:
          "Το πεδίο [" +
          hasStepStatusRequiredFieldMissing.name +
          "] είναι κενό",
      });
      return;
    }

    //CHECK VALIDATION FOR CUSTOM GRID
    if (
      GetTechnicalDescriptionStepActionIsValidForSubmit(
        this.state.steps,
        stepStatus,
        TechnicalDescriptionActionIdEnum.ConsumerActionId
      ) == false
    ) {
      return;
    }
    //CHECK VALIDATION FOR CUSTOM GRID
    if (
      GetTechnicalDescriptionStepActionIsValidForSubmit(
        this.state.steps,
        stepStatus,
        TechnicalDescriptionActionIdEnum.CRMActionId
      ) == false
    ) {
      return;
    }

    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      this.setState({ removeActions: 1 });
      let workflowInstanceId = this.state.instance.Id;
      var data = this.state.steps[stepStatus - 1];
      let result =
        await ApiCallerHelper.WorkflowInstances.postApproveWorkflowInstances(
          workflowInstanceId,
          data
        );
      RedirectHelper.RedirectTo.reloadPageFromCache();
    }
  };

  assignInstance = async (stepStatus) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      let workflowInstanceId = this.state.instance.Id;
      let userId = this.state.logged_user.Id;

      let result =
        await ApiCallerHelper.WorkflowInstances.postAssignWorkflowInstances(
          workflowInstanceId,
          userId
        );
      RedirectHelper.RedirectTo.reloadPageFromCache();
    }
  };

  declineInstance = async (stepStatus) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      this.setState({ removeActions: 1 });

      let workflowInstanceId = this.state.instance.Id;
      var data = this.state.steps[stepStatus - 1];
      let result =
        await ApiCallerHelper.WorkflowInstances.postDeclineWorkflowInstances(
          workflowInstanceId,
          data
        );
      RedirectHelper.RedirectTo.details(workflowInstanceId);
    }
  };

  async dynamicCall(call) {
    await DynamicButtonsActionsHelper.executor(
      this,
      call,
      this.state.instance.Id,
      document
    );
  }

  viewFile = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.open(data.url, "_blank"); // Open the presigned URL in a new tab
      }
    } catch (error) {
      console.error("Failed to get presigned URL", error);
    }
  };

  deleteFile = async (fileId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(process.env.REACT_APP_API_LINK + "/api/files/" + fileId + "/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then(async (res) => {
          let filesResult =
            await ApiCallerHelper.Files.getAllInstanceFilesByInstanceId(
              this.state.instance.Id
            );
          this.setState({
            files: filesResult.data,
          });
        });
    }
  };

  async uploadFile(evt, fileId) {
    const formData = new FormData();
    const res = fileId.split("/");

    // Append the file to the formData
    formData.append("file", evt.target.files[0]);

    // Append additional data to formData
    formData.append("serial_number", this.state.instance.SerialNumber);
    formData.append("step_id", res[0]);
    formData.append("action_id", res[1]);


    fetch(process.env.REACT_APP_API_LINK + "/api/files/new/", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: formData
    }).then(async (data) => {
      let filesResult =
        await ApiCallerHelper.Files.getAllInstanceFilesByInstanceId(
          this.state.instance.Id
        );
      this.setState({
        files: filesResult.data,
      });
    });
  }

  updateInputValue(evt) {
    let isValueUpdated = SetTechnicalDescriptionStepActionData(
      evt,
      this.state.steps
    );
    if (isValueUpdated == true) {
      return;
    }
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };
            if (tempAction.action.Target == "limit14") {
              if (evt.target.value.length > 14) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }

            if (tempAction.action.Target == "limit9") {
              if (evt.target.value.length > 9) {
                evt.target.value = evt.target.value.substring(
                  0,
                  evt.target.value.length - 1
                );
              }
            }
            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
          }
        }
      }
    }
  }

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  //ADDING LOGIC FOR ENABLED AND DISABLED CONTROLS
  _isCurrentStep = (dynamicData) => {
    let isEnabled = dynamicData.step.Status == this.state.instance.Status;
    return isEnabled;
  };
  /**
   * This function checks whether a step action is enabled based on the dynamic data and action ID passed in as arguments
   *
   * @param {String} dynamicData - the object that contains the dynamic data for the current workflow instance, such as the current step, the user assigned to the instance, and the user's role. .
   * @param {String} actionId - A string that represents the ID of the action that is being checked for enablement (Optional).
   * @returns {Boolean} Check whether the current step matches the step in the dynamicData parameter, and combine with the existing isEnabled check to determine whether the action is enabled.
   */
  _isStepActionEnabled = (dynamicData, actionId) => {
    let isEditPermitted = dynamicData.step.EditPermission.includes(
      this.state.logged_user.PersonRole
    );
    let isAssignedToUser =
      this.state.instance.IsAssigned &&
      this.state.instance.UserAssignedId == this.state.logged_user.Id;
    let isEnabled = isEditPermitted && isAssignedToUser;

    function _isCustomFlorinaRuleEnabled(self) {
      //Custom Rules for florina
      let instanceIsCustomFlorinaFlag =
        self.state.instanceIsCustomFlorinaFlag ?? false;
      if (instanceIsCustomFlorinaFlag && actionId) {
        let stepStatus = self.state.instance.Status;
        let isForcedEnabled =
          WorkflowInstancesHelper.IsWorfklowInstanceStepActionForcedEnabledByCustomFlag(
            actionId,
            instanceIsCustomFlorinaFlag,
            stepStatus
          );
        if (isForcedEnabled && isEnabled) {
          return true;
        }
      }
      return false;
    }

    if (_isCustomFlorinaRuleEnabled(this)) {
      return true;
    }

    isEnabled = isEnabled && this._isCurrentStep(dynamicData);

    return isEnabled;
  };

  _isCurrentStepHeaderButtonsEnabledAndVisible = (dynamicData) => {
    let isStepActionEnabled = this._isStepActionEnabled(dynamicData);
    return isStepActionEnabled;
  };
  _isCurrentStepHeaderButtonBeginStepEnabledAndVisible = (dynamicData) => {
    let isCurrentStep = this._isCurrentStep(dynamicData);
    let isEditPermitted = dynamicData.step.EditPermission.includes(
      this.state.logged_user.PersonRole
    );
    let isAssigned = this.state.instance.IsAssigned ?? false;
    let result = isCurrentStep && isEditPermitted && isAssigned == false;
    return result;
  };

  //ADDING LOGIC FOR RENDER VISIBILITY
  _isRenderStepActionVisible = (action) => {
    let isVisible = false;
    let actionId = action.Id;
    let instanceIsCustomFlorinaFlag = this.state.instanceIsCustomFlorinaFlag;
    isVisible =
      WorkflowInstancesHelper.IsWorfklowInstanceStepActionEnabledByCustomFlag(
        actionId,
        instanceIsCustomFlorinaFlag
      );
    return isVisible;
  };

  _isRenderStepVisible = (step) => {
    let isVisible = false;
    let stepId = step.Id;
    let instanceIsCustomFlorinaFlag = this.state.instanceIsCustomFlorinaFlag;
    isVisible =
      WorkflowInstancesHelper.IsWorfklowInstanceStepEnabledByCustomFlag(
        stepId,
        instanceIsCustomFlorinaFlag
      );
    return isVisible;
  };

  render() {
    if (!this.state.instance) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    }

    return (
      <>
        <InstanceHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Φυσικό Αέριο
                          </span>
                          <CardBody>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Α/Α: &nbsp;
                              </span>
                              {this.state.instance.SerialNumber}/
                              {new Date().getFullYear()}
                            </Row>
                            <Row className="align-items-center">
                              <span style={{ fontWeight: "bold" }}>
                                Ημερομηνία Έναρξης: &nbsp;
                              </span>
                              {Moment(this.state.instance.StartedAt)
                                .locale("el")
                                .format("dddd, DD MMM YYYY")}
                            </Row>
                            <Row className="align-items-center">
                              <br></br>
                            </Row>
                            <Row className="align-items-center">
                              <Button
                                color="primary"
                                href={
                                  "/admin/details/" + this.state.instance.Id
                                }
                                size="sm"
                              >
                                Στοιχεία Αίτησης
                              </Button>
                            </Row>
                          </CardBody>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Accordion defaultActiveKey={this.state.instance.Status}>
          {this.state.steps.map((dynamicData, key) => (
            <Fragment key={key}>
              {this._isRenderStepVisible(dynamicData.step) && (
                <Container className="mt-2" fluid key={key}>
                  <Row className="mt-5">
                    <Col className="order-xl-1" xl="12">
                      <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                          <Accordion.Toggle
                            as={CardHeader}
                            eventKey={dynamicData.step.Status}
                          >
                            <Row className="align-items-center">
                              <Col xs="8">
                                <h3 className="mb-0">
                                  {dynamicData.step.Status}.
                                  {dynamicData.step.Name}
                                </h3>
                                {dynamicData.step.Status <
                                  this.state.instance.Status && (
                                  <>
                                    {/* <p>Υποβλήθηκε {Moment(dynamicData.actions[0].data.DateCreated, "YYYYMMDDhhmm").fromNow()}</p> */}
                                  </>
                                )}
                              </Col>
                              {this._isCurrentStepHeaderButtonsEnabledAndVisible(
                                dynamicData
                              ) &&
                                (this.state.removeActions ?? false) ==
                                  false && (
                                  <Col className="text-right" xs="4">
                                    <Button
                                      color="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.saveInstance(
                                          dynamicData.step.Status
                                        );
                                      }}
                                      size="sm"
                                    >
                                      Αποθήκευση
                                    </Button>
                                    <Button
                                      color="success"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.approveInstance(
                                          dynamicData.step.Status
                                        );
                                      }}
                                      size="sm"
                                    >
                                      Έγκριση
                                    </Button>
                                    <Button
                                      color="danger"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.declineInstance(
                                          dynamicData.step.Status
                                        );
                                      }}
                                      size="sm"
                                    >
                                      Επανεξέταση
                                    </Button>
                                  </Col>
                                )}
                              {this._isCurrentStepHeaderButtonBeginStepEnabledAndVisible(
                                dynamicData
                              ) && (
                                <Col className="text-right" xs="4">
                                  <Button
                                    color="success"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.assignInstance(
                                        dynamicData.step.Status
                                      );
                                    }}
                                    size="sm"
                                  >
                                    Έναρξη Εργασίας
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </Accordion.Toggle>
                        </CardHeader>
                        <Accordion.Collapse eventKey={dynamicData.step.Status}>
                          <Container fluid>
                            <CardBody>
                              <Form
                                id={dynamicData.step.Id}
                                onSubmit={this.handleSubmit}
                              >
                                <Row>
                                  <Fragment>
                                    {dynamicData.actions.map(
                                      (dynamicActions, key) => (
                                        <Fragment key={key}>
                                          {dynamicData.step.ViewPermission.includes(
                                            this.state.logged_user.PersonRole
                                          ) && (
                                            <>
                                              {dynamicActions.action.Type ==
                                                "file" &&
                                                this._isRenderStepActionVisible(
                                                  dynamicActions.action
                                                ) && (
                                                  <Col lg="6" className="mb-5">
                                                    <label className="form-control-label">
                                                      {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                        dynamicActions.action
                                                      )}
                                                    </label>
                                                    {this._isStepActionEnabled(
                                                      dynamicData,
                                                      dynamicActions.action.Id
                                                    ) && (
                                                      <Row>
                                                        <Col lg="10">
                                                          <label
                                                            className="btn btn-primary"
                                                            htmlFor={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                          >
                                                            Επιλογή αρχείου
                                                          </label>
                                                          <Input
                                                            className="form-control-alternative"
                                                            // defaultValue={ dynamicActions.data.Data }
                                                            id={
                                                              dynamicData.step
                                                                .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id
                                                            }
                                                            // placeholder={dynamicActions.action.Description}
                                                            style={{
                                                              visibility:
                                                                "hidden",
                                                            }}
                                                            type={
                                                              dynamicActions
                                                                .action.Type
                                                            }
                                                            disabled={
                                                              !this._isStepActionEnabled(
                                                                dynamicData,
                                                                dynamicActions
                                                                  .action.Id
                                                              )
                                                            }
                                                            onChange={(evt) =>
                                                              this.uploadFile(
                                                                evt,
                                                                dynamicData.step
                                                                  .Id +
                                                                  "/" +
                                                                  dynamicActions
                                                                    .action.Id
                                                              )
                                                            }
                                                          />
                                                        </Col>
                                                        {/* <Button
                                                                                                    color="primary"
                                                                                                    onClick={evt => this.uploadFile(dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                    size="sm"
                                                                                                >
                                                                                                    Υποβολή
                                                                                                </Button> */}
                                                      </Row>
                                                    )}
                                                    <Table
                                                      className="align-items-center table-flush"
                                                      responsive
                                                    >
                                                      <thead className="thead-light">
                                                        <tr>
                                                          <th scope="col">
                                                            Ονομα
                                                          </th>
                                                          <th scope="col">
                                                            Ημερομηνια Υποβολης
                                                          </th>
                                                          <th scope="col">
                                                            Ενεργειες
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state.files.map(
                                                          (
                                                            dynamicFiles,
                                                            key
                                                          ) => (
                                                            <Fragment key={key}>
                                                              {dynamicActions
                                                                .action.Id ==
                                                                dynamicFiles.ActionId && (
                                                                <>
                                                                  <tr>
                                                                    <td>
                                                                      {
                                                                        dynamicFiles.FileName
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {Moment(
                                                                        dynamicFiles.UploadDate
                                                                      )
                                                                        .locale(
                                                                          "el"
                                                                        )
                                                                        .format(
                                                                          "dddd, DD MMM YYYY"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                      <Button
                                                                        color="primary"
                                                                        onClick={() => this.viewFile(dynamicFiles.Id)}
                                                                        size="sm"
                                                                        title="Προβολή"
                                                                      >
                                                                        <i className="fas fa-eye" />
                                                                      </Button>
                                                                      <Button
                                                                        color="danger"
                                                                        onClick={() => {
                                                                          this.deleteFile(
                                                                            dynamicFiles.Id
                                                                          );
                                                                        }}
                                                                        size="sm"
                                                                        title="Διαγραφή"
                                                                      >
                                                                        <i className="fas fa-trash" />
                                                                      </Button>
                                                                    </td>
                                                                  </tr>
                                                                </>
                                                              )}
                                                            </Fragment>
                                                          )
                                                        )}
                                                      </tbody>
                                                    </Table>
                                                    {this._isStepActionEnabled(
                                                      dynamicData
                                                    ) && (
                                                      <Button
                                                        color="warning"
                                                        onClick={(evt) =>
                                                          this.handleOpenModal(
                                                            dynamicData.step
                                                              .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id,
                                                            dynamicActions
                                                              .action.Name
                                                          )
                                                        }
                                                        size="sm"
                                                      >
                                                        Αναφορά προβλήματος
                                                      </Button>
                                                    )}
                                                  </Col>
                                                )}

                                              {dynamicActions.action.Type ==
                                                "button" &&
                                                this._isRenderStepActionVisible(
                                                  dynamicActions.action
                                                ) && (
                                                  <>
                                                    {this._isStepActionEnabled(
                                                      dynamicData
                                                    ) && (
                                                      <Col
                                                        lg="6"
                                                        className="mb-5"
                                                      >
                                                        <label className="form-control-label">
                                                          {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                            dynamicActions.action
                                                          )}
                                                        </label>

                                                        <Col lg="6">
                                                          <Button
                                                            color="primary"
                                                            onClick={(evt) =>
                                                              this.dynamicCall(
                                                                dynamicActions
                                                                  .action.Target
                                                              )
                                                            }
                                                            size="lg"
                                                          >
                                                            {
                                                              dynamicActions
                                                                .action
                                                                .Description
                                                            }
                                                          </Button>
                                                        </Col>
                                                        {this.state
                                                          .buttonCondition ==
                                                        dynamicData.step
                                                          .Status ? (
                                                          <p>
                                                            {
                                                              this.state
                                                                .buttonMessage
                                                            }
                                                          </p>
                                                        ) : (
                                                          <>
                                                            {this.state
                                                              .buttonCondition ==
                                                              5 && (
                                                              <p>
                                                                {
                                                                  this.state
                                                                    .buttonMessage
                                                                }
                                                              </p>
                                                            )}
                                                          </>
                                                        )}
                                                      </Col>
                                                    )}
                                                  </>
                                                )}

                                              {isInputHtmlType(
                                                dynamicActions.action.Type
                                              ) &&
                                                this._isRenderStepActionVisible(
                                                  dynamicActions.action
                                                ) && (
                                                  <Col lg="6" className="mb-5">
                                                    <label className="form-control-label">
                                                      {WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                        dynamicActions.action
                                                      )}
                                                    </label>
                                                    <Input
                                                      className="form-control-alternative"
                                                      //defaultValue={ dynamicActions.data.Data }
                                                      defaultValue={this.getDateValueOrValueIfNotDate(
                                                        dynamicActions.data
                                                          .Data,
                                                        dynamicActions.action
                                                          .Type
                                                      )}
                                                      id={
                                                        dynamicData.step.Id +
                                                        "/" +
                                                        dynamicActions.action.Id
                                                      }
                                                      placeholder={
                                                        dynamicActions.action
                                                          .Description
                                                      }
                                                      type={
                                                        dynamicActions.action
                                                          .Type
                                                      }
                                                      disabled={
                                                        !this._isStepActionEnabled(
                                                          dynamicData
                                                        )
                                                      }
                                                      onChange={(evt) =>
                                                        this.updateInputValue(
                                                          evt
                                                        )
                                                      }
                                                    />
                                                    {this._isStepActionEnabled(
                                                      dynamicData
                                                    ) && (
                                                      <Button
                                                        color="warning"
                                                        onClick={(evt) =>
                                                          this.handleOpenModal(
                                                            dynamicData.step
                                                              .Id +
                                                              "/" +
                                                              dynamicActions
                                                                .action.Id,
                                                            dynamicActions
                                                              .action.Name
                                                          )
                                                        }
                                                        size="sm"
                                                      >
                                                        Αναφορά προβλήματος
                                                      </Button>
                                                    )}
                                                  </Col>
                                                )}
                                              {this._isRenderStepActionVisible(
                                                dynamicActions.action
                                              ) && (
                                                <TechnicalDescription
                                                  steps={this.state.steps}
                                                  title={WorkflowInstancesHelper.Action.getNameWithAsteriscIfRequired(
                                                    dynamicActions.action
                                                  )}
                                                  actionType={
                                                    dynamicActions.action.Type
                                                  }
                                                  actionId={
                                                    dynamicActions.action.Id
                                                  }
                                                  stepId={dynamicData.step.Id}
                                                  actionDataValue={
                                                    dynamicActions.data.Data
                                                  }
                                                  onUpdateCallback={(evt) =>
                                                    this.updateInputValue(evt)
                                                  }
                                                  isReadOnly={
                                                    !this._isStepActionEnabled(
                                                      dynamicData
                                                    )
                                                  }
                                                />
                                              )}
                                            </>
                                          )}
                                        </Fragment>
                                      )
                                    )}
                                  </Fragment>
                                </Row>
                              </Form>
                            </CardBody>
                          </Container>
                        </Accordion.Collapse>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )}
            </Fragment>
          ))}
        </Accordion>

        <Modal
          isOpen={this.state.showModal}
          contentLabel="Minimal Modal Example"
          style={{
            overlay: {},
            content: {
              position: "absolute",
              top: "40%",
              left: "30%",
              marginTop: "-50px",
              marginLeft: "-30px",
              width: "35%",
              height: "30%",
            },
          }}
        >
          <label className="form-control-label" htmlFor="input-username">
            Σχολιο για {this.state.modalFieldName}
          </label>
          <Input
            className="form-control-alternative"
            defaultValue=""
            id="comment"
            placeholder="Προσθέστε το σχόλιό σας"
            onChange={(evt) => this.updateComment(evt)}
            // type={dynamicActions.action.Type}
          />
          <p></p>
          <Button
            color="primary"
            onClick={this.handleCloseModalSubmit}
            size="sm"
          >
            Προσθήκη σχολίου
          </Button>
          {/* <Button
                            color="primary"
                            onClick={this.handleCloseModal}
                            size="sm"
                        >
                            Απενεργοποίηση σχολίου
                        </Button> */}
          <button onClick={this.handleCloseModal}>Κλείσιμο</button>
        </Modal>
      </>
    );
  }
}

export default Instance;
