import React from "react";

import endpoint from "./config.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

class ConsumerTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ticket: [],
      consumer: [],
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/support/ticket/" + handle + "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            ticket: result.ticket,
            consumer: result.consumer,
          });
        });
    }
  }

  solvedTicket = (ticketId) => {
    fetch(
      process.env.REACT_APP_API_LINK + "/api/support/viewed/" + ticketId + "/",
      {
        method: "put",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {});
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Στοιχεία Μηνύματος Υποστήριξης</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="success"
                        onClick={() => {
                          this.solvedTicket(this.state.ticket.Id);
                        }}
                        size="sm"
                      >
                        Ορισμός ως επιλυμένου
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Βασικες πληροφοριες
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Όνομα
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.consumer.Name}
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Επίθετο
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.consumer.LastName}
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.consumer.Email}
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Τηλέφωνο
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.consumer.phoneNumber}
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <h6 className="heading-small text-muted mb-4">
                      Μήνυμα Υποστήριξης
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Μήνυμα
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.ticket.Message}
                              id="input-username"
                              placeholder="Όνομα"
                              type="textarea"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ημερομηνία Υποβολής Μηνύματος
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={Moment(
                                this.state.ticket.SubmittedAt
                              )
                                .locale("el")
                                .format("dddd, DD MMM YYYY")}
                              id="input-username"
                              placeholder="Όνομα"
                              type="text"
                              disabled={true}
                              // onChange={evt => this.updateName(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ConsumerTicket;
