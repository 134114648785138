import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Table,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import axios from "axios";

class EditMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mctimer: 0,
      signtimer: 0,
      activtimer: 0,
      discount: 0,
      deposit: 0,
      price: 0,
      collateral: 0,
      final: 0,
      instance: [],
      params: [],
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/details/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            instance: result,
          });
        });

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/timers/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            params: result,
            mctimer: result.mc,
            signtimer: result.sign,
            activtimer: result.active,
            price: result.price,
            collateral: result.collateral,
            deposit: result.deposit,
            discount: result.discount,
            final: result.final,
          });
        });
    }
  }

  saveParameters = (stepStatus) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/workflowinstances/saveparams/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            InstanceId: this.state.instance.Id,
            MC: this.state.mctimer,
            Sign: this.state.signtimer,
            Activation: this.state.activtimer,
            Price: this.state.price,
            Collateral: this.state.collateral,
            FinalPrice: this.state.final,
            Discount: this.state.discount,
            Deposit: this.state.deposit,
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateSignTimer(evt) {
    this.setState({
      signtimer: evt.target.value,
    });
  }

  updateMCTimer(evt) {
    this.setState({
      mctimer: evt.target.value,
    });
  }

  updateActivTimer(evt) {
    this.setState({
      activtimer: evt.target.value,
    });
  }

  updatePricing(evt) {
    this.setState({
      price: evt.target.value,
    });
  }

  updateDiscount(evt) {
    this.setState({
      discount: evt.target.value,
    });
  }

  updateDeposit(evt) {
    this.setState({
      deposit: evt.target.value,
    });
  }

  updateCollateral(evt) {
    this.setState({
      collateral: evt.target.value,
    });
  }

  updateFinal(evt) {
    this.setState({
      final: evt.target.value,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        Παράμετροι αίτησης με Α/Α :
                        {this.state.instance.SerialNumber}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Παραμετροι Timers
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Timer Υπογραφής Σύμβασης <br></br> (σε ημέρες)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.sign}
                              placeholder="Timer Υπογραφής Σύμβασης"
                              type="text"
                              onChange={(evt) => this.updateSignTimer(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Timer Μηχανικής Ολοκλήρωσης <br></br> (σε μήνες)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.mc}
                              placeholder="Timer Μηχανικής Ολοκλήρωσης"
                              type="text"
                              onChange={(evt) => this.updateMCTimer(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Timer Ενεργοποίησης <br></br> (σε ημέρες)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.active}
                              placeholder="Timer Ενεργοποίησης"
                              type="text"
                              onChange={(evt) => this.updateActivTimer(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>
                    </div>
                  </Form>
                  <br></br>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Παραμετροι Pricing
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Υπολογισμένα Τέλη Σύνδεσης
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.price}
                              placeholder="Υπολογισμένα Τέλη Σύνδεσης"
                              type="text"
                              onChange={(evt) => this.updatePricing(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ποσό Προκαταβολής
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.deposit}
                              placeholder="Ποσό Προκαταβολής"
                              type="text"
                              onChange={(evt) => this.updateDeposit(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Ποσό Εγγύησης
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.collateral}
                              placeholder="Ποσό Εγγύησης"
                              type="text"
                              onChange={(evt) => this.updateCollateral(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Τελικό Ποσό Χρέωσης
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.final}
                              placeholder="Τελικό Ποσό Χρέωσης"
                              type="text"
                              onChange={(evt) => this.updateFinal(evt)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Έκπτωση Τελών Σύνδεσης (%)
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              defaultValue={this.state.params.discount}
                              placeholder=" Έκπτωση Τελών Σύνδεσης (%)"
                              type="text"
                              onChange={(evt) => this.updateDiscount(evt)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br></br>

                      <Row>
                        <Col lg="3">
                          <Button
                            color="success"
                            onClick={(evt) => {
                              this.saveParameters(evt);
                            }}
                            size="sm"
                          >
                            Αποθήκευση αλλαγών
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditMenu;
