import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import endpoint from "./config.js";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

class InstanceComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      workflow: [],
      user: [],
      steps: [],
      enabled: true,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    const result = await axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/instance/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          instance: res.data.instance,
          workflow: res.data.workflow,
          user: res.data.user,
          steps: res.data.stepsInfused,
        });
      });
  }

  handleClick = (stepStatus) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/update/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify(this.state.steps[stepStatus]),
      }).then((data) => {});
    }
  };

  updateCommentValue(evt) {
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
            // this.state.steps[i].actions[j].action.Data = evt.target.value;
          }
        }
      }
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Φυσικό Αέριο
                            </span>
                            <CardBody>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>Α/Α:</span>
                                {this.state.instance.SerialNumber}/
                                {new Date().getFullYear()}
                              </Row>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Έναρξης:
                                </span>
                                {Moment(this.state.instance.StartedAt)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          {this.state.steps.map((dynamicData) => (
            <Container className="mt-2" fluid>
              <Row className="mt-5">
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">{dynamicData.step.Name}</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                          <Button
                            color="primary"
                            onClick={() => {
                              this.handleClick(dynamicData.step.Status);
                            }}
                            size="sm"
                          >
                            Υποβολή σχολίων
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form id="main-login" onSubmit={this.handleSubmit}>
                        <div className="pl-lg-4">
                          <Row>
                            {dynamicData.step.Status ===
                            this.state.instance.Status ? (
                              dynamicData.actions.map((dynamicActions) => (
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-username"
                                    >
                                      {dynamicActions.action.Name}
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue={dynamicActions.data.Data}
                                      id={
                                        dynamicData.step.Id +
                                        "/" +
                                        dynamicActions.action.Id
                                      }
                                      placeholder={
                                        dynamicActions.action.Description
                                      }
                                      // type={dynamicActions.action.Type}
                                      disabled="true"
                                    />
                                    <label
                                      className="form-control-label"
                                      htmlFor="comment"
                                    >
                                      Σχόλιο για : {dynamicActions.action.Name}
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue=""
                                      id={
                                        dynamicData.step.Id +
                                        "/" +
                                        dynamicActions.action.Id
                                      }
                                      placeholder="Προσθήκη σχολίου για καταναλωτή"
                                      type="text"
                                      onChange={(evt) =>
                                        this.updateCommentValue(evt)
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              ))
                            ) : (
                              <p></p>
                            )}
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          ))}
        </>
      );
    }
  }
}

export default InstanceComments;
