import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components

import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";

import { MDBDataTable } from "mdbreact";

import Moment from "moment";
import "moment/locale/el";

import axios from "axios";
import { ApiCallerHelper } from "../../api/apiCallerHelper";

import endpoint from "./config.js";

class Consumer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      details: [],
      instances: [],
      applications: [],
      name: "",
      lastname: "",
      email: "",
      phone: "",
      user: [],
      loadedDatatable: false,
      loadedDatatablePublishedInstances: false,
      newSerial: "",
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      const consumerDetailsResult =
        await ApiCallerHelper.Consumer.getConsumerDetails(handle);
      this.setState({
        details: consumerDetailsResult.data,
        name: consumerDetailsResult.data.Name,
        lastname: consumerDetailsResult.data.LastName,
        email: consumerDetailsResult.data.Email,
        phone: consumerDetailsResult.data.phoneNumber,
      });

      const consumerInstancesResult =
        await ApiCallerHelper.Consumer.getConsumerInstances(handle);
      this.setState({
        loading: false,
        instances: consumerInstancesResult.data,
      });

      const userResult = await ApiCallerHelper.Auth.getUserCurrentAsync();
      this.setState({
        isLoaded: true,
        user: userResult.data,
      });
    }
  }

  applicationInterest = (user) => {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/consumer/applications/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              applications: result.applications,
            });

            result.applications.forEach(function (part, index) {
              this[index].FysikoAerio = "Φυσικό Αέριο";

              this[index].Buttons = (
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/application/" + this[index].Id}
                    size="sm"
                    title="Επεξεργασία"
                  >
                    <i className="fas fa-pencil-alt" />
                  </Button>

                  {user.PersonRole == "Admin" || user.PersonRole == "Sales" ? (
                    <>
                      <Button
                        color="danger"
                        onClick={() => {
                          this.deleteApplication(this[index].Id);
                        }}
                        size="sm"
                        title="Διαγραφή"
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </>
                  ) : (
                    <> </>
                  )}
                </React.Fragment>
              );

              this[index].dateCreatedNew = Moment(this[index].dateCreated)
                .locale("el")
                .format("dddd, DD MMM YYYY");
            }, result.applications);
            this.state.dummy = {
              columns: [
                {
                  label: "Ονομα ▼",
                  field: "Name",
                  sort: "asc",
                  width: 100,
                },
                {
                  label: "Επιθετο ▼",
                  field: "LastName",
                  sort: "asc",
                  width: 100,
                },
                {
                  label: "Τηλεφωνο ▼",
                  field: "phoneNumber",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Email ▼",
                  field: "Email",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Περιφερεια ▼",
                  field: "state",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "ΗΜΕΡΟΜΗΝΙΑ ΥΠΟΒΟΛΗΣ	 ▼",
                  field: "dateCreatedNew",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Ενεργειες",
                  field: "Buttons",
                  sort: "asc",
                  width: 150,
                },
              ],
              rows: this.state.applications,
            };

            this.setState({
              loadedDatatable: true,
            });
          },
          (error) => {
            this.setState({
              error,
            });
          }
        );
    }
  };

  publishedInstances = (user) => {
    const handle = this.props.match.params.id;
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/consumer/instances/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              loading: false,
              instances: result,
              loadedDatatablePublishedInstances: true,
            });

            result.forEach(function (part, index) {
              this[index].FysikoAerio = "Φυσικό Αέριο";

              this[index].Buttons = (
                <React.Fragment>
                  <Button
                    color="primary"
                    href={"/admin/instance/" + this[index].Id}
                    size="sm"
                    title="Επεξεργασία"
                  >
                    <i className="fas fa-pencil-alt" />
                  </Button>

                  {user.PersonRole == "Admin" || user.PersonRole == "Sales" ? (
                    <>
                      <Button
                        color="danger"
                        onClick={() => {
                          this.deleteApplication(this[index].Id);
                        }}
                        size="sm"
                        title="Διαγραφή"
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    </>
                  ) : (
                    <> </>
                  )}
                </React.Fragment>
              );

              this[index].FysikoAerio = "Φυσικό Αέριο";
            }, result);

            this.state.publishedInstancesData = {
              columns: [
                {
                  label: "Α/Α ▼",
                  field: "SerialNumber",
                  sort: "asc",
                  width: 100,
                },
                {
                  label: "Προγραμμα ▼",
                  field: "FysikoAerio",
                  sort: "asc",
                  width: 100,
                },
                {
                  label: "Κατασταση ▼",
                  field: "Status",
                  sort: "asc",
                  width: 150,
                },
                {
                  label: "Ενεργειες",
                  field: "Buttons",
                  sort: "asc",
                  width: 150,
                },
              ],
              rows: this.state.instances,
            };
          },
          (error) => {
            this.setState({
              loading: false,
              error,
            });
          }
        );
    }
  };

  resetPassword = (consumerId) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/auth/resetpassword/" +
          consumerId +
          "/",
        {
          method: "PUT",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
        }
      ).then((res) => {
        window.location.reload(false);
      });
    }
  };

  saveChanges = (consumerId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/consumer/update/", {
        method: "PUT",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Id: this.state.details.Id,
          Name: this.state.name,
          LastName: this.state.lastname,
          Email: this.state.email,
          phoneNumber: this.state.phone,
        }),
      }).then((res) => {
        window.location.reload(false);
      });
    }
  };

  async changeConsumer() {
    const updateResult = await ApiCallerHelper.Consumer.changeConsumer(
      this.state.details.Id,
      this.state.newSerial
    );
    window.location.reload(false);
  }

  updateName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  updateLastName(evt) {
    this.setState({
      lastname: evt.target.value,
    });
  }

  updateEmail(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  updatePhone(evt) {
    this.setState({
      phone: evt.target.value,
    });
  }

  deleteApplication = (applicationId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/applications/" +
            applicationId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  deleteInstance = (instanceId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/" +
            instanceId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  deleteConsumer = (instanceId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/consumer/" +
            this.state.details.Id +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setTimeout(function () {
            window.location.replace("/admin/index/");
          }, 2500);
        });
    }
  };

  updateSerial(evt) {
    this.setState({
      newSerial: evt.target.value,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />

          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Στοιχεία Καταναλωτή</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {this.state.user.PersonRole == "Admin" ||
                        this.state.user.PersonRole == "Sales" ? (
                          <>
                            <Button
                              color="primary"
                              href="#pablo"
                              onClick={() => {
                                this.saveChanges(this.state.details.Id);
                              }}
                              size="sm"
                            >
                              Αποθήκευση αλλαγών
                            </Button>
                          </>
                        ) : (
                          <> </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.Name}
                                id="input-username"
                                placeholder="Όνομα"
                                type="text"
                                onChange={(evt) => this.updateName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Επίθετο
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.details.LastName}
                                id="input-email"
                                placeholder="Επίθετο"
                                type="email"
                                onChange={(evt) => this.updateLastName(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.Email}
                                placeholder="Email"
                                type="email"
                                onChange={(evt) => this.updateEmail(evt)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Τηλέφωνο
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                defaultValue={this.state.details.phoneNumber}
                                placeholder="Τηλέφωνο"
                                type="email"
                                onChange={(evt) => this.updatePhone(evt)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Επαναφορα κωδικου προσβασης
                          </h6>

                          <div className="pl-lg-4">
                            <Row>
                              <Col md="4" style={{ alignTextAlign: "center" }}>
                                <Button
                                  color="primary"
                                  href="#pablo"
                                  onClick={() => {
                                    this.resetPassword(this.state.details.Id);
                                  }}
                                  size="sm"
                                >
                                  Επαναφορά κωδικού
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}

                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Μεταφορα Αιτησης στον Καταναλωτη
                          </h6>

                          <div className="pl-lg-4">
                            <Row>
                              <Col md="4" style={{ alignTextAlign: "center" }}>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-serial"
                                  >
                                    Αριθμός Αίτησης
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-serial"
                                    placeholder="Αριθμός Αίτησης"
                                    type="text"
                                    onChange={(evt) => this.updateSerial(evt)}
                                  />
                                  <Button
                                    color="success"
                                    onClick={() => {
                                      this.changeConsumer();
                                    }}
                                    size="sm"
                                  >
                                    Μεταφορά
                                  </Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}

                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <hr className="my-4" />
                          <h6 className="heading-small text-muted mb-4">
                            Διαγραφη Καταναλωτη και δεδομενων
                          </h6>

                          <div className="pl-lg-4">
                            <Row>
                              <Col md="4" style={{ alignTextAlign: "center" }}>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    this.deleteConsumer(this.state.details.Id);
                                  }}
                                  size="sm"
                                >
                                  Διαγραφή
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Υποβεβλημένες αιτήσεις καταναλωτή
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                          <>
                            <Button
                                color="success"
                                href="/admin/newinstance"
                                size="sm"
                            >
                                Προσθήκη νέας αίτησης
                            </Button>
                          </> : <> </>} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  {this.state.loadedDatatablePublishedInstances == false ? (
                    this.publishedInstances(this.state.user)
                  ) : (
                    <></>
                  )}
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <MDBDataTable
                          responsive
                          bordered
                          exportToCSV
                          hover
                          strip
                          data={this.state.publishedInstancesData}
                          id="mdbdttable"
                          entriesOptions={[10, 25, 50, 100, 500, 1000]}
                          entries={10}
                          noDataComponent="Δεν υπάρχουν δεδομένα"
                          searchLabel="Αναζήτηση"
                          paginationLabel={["Προηγούμενο", "Επόμενο"]}
                          infoLabel={[
                            "Εμφανίζονται",
                            "έως",
                            "από",
                            "καταχωρήσεις",
                          ]}
                          entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                        />
                      </div>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Εκδηλώσεις ενδιαφέροντος καταναλωτή
                        </h3>
                      </Col>
                      <Col className="text-right" xs="4">
                        {/* {this.state.user.PersonRole == 'Admin' || this.state.user.PersonRole == 'Sales' ? 
                        <>
                          <Button
                              color="success"
                              href={"https://deda.gr/connect"}
                              size="sm"
                          >
                              Προσθήκη νέας αίτησης
                          </Button>
                        </> : <> </>} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  {this.state.loadedDatatable == false ? (
                    this.applicationInterest(this.state.user)
                  ) : (
                    <></>
                  )}

                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <MDBDataTable
                          responsive
                          bordered
                          exportToCSV
                          hover
                          strip
                          data={this.state.dummy}
                          id="mdbdttable"
                          entriesOptions={[10, 25, 50, 100, 500, 1000]}
                          entries={10}
                          noDataComponent="Δεν υπάρχουν δεδομένα"
                          searchLabel="Αναζήτηση"
                          paginationLabel={["Προηγούμενο", "Επόμενο"]}
                          infoLabel={[
                            "Εμφανίζονται",
                            "έως",
                            "από",
                            "καταχωρήσεις",
                          ]}
                          entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                        />
                      </div>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Έγγραφα καταναλωτή</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Ονομα αρχειου</th>
                        <th scope="col">Ημερομηνια Αναρτησης</th>
                        <th scope="col">Ενεργειες</th>
                      </tr>
                    </thead>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Consumer;
