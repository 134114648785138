import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class Management extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      instance: [],
      workflow: [],
      user: [],
      steps: [],
      enabled: true,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  // handleClick = stepStatus => {
  //     // console.log(this.state.steps);

  //     if (localStorage.getItem('token')) {
  //         fetch(process.env.REACT_APP_API_LINK + '/api/workflowinstances/update/', {
  //             method: 'POST',
  //             headers: new Headers({
  //                 'Authorization' : 'Bearer ' + localStorage.getItem('token'),
  //             }),
  //             body: JSON.stringify(this.state.steps[stepStatus])
  //         })
  //         .then(data => {
  //
  //         })
  //     }
  // }

  updateCommentValue(evt) {
    var res = evt.target.id.split("/");
    let data = [...this.state.steps];

    var i;
    for (i = 0; i < this.state.steps.length; i++) {
      if (this.state.steps[i].step.Id == res[0]) {
        var j;
        for (j = 0; j < this.state.steps[i].actions.length; j++) {
          if (this.state.steps[i].actions[j].action.Id == res[1]) {
            let tempAction = { ...data[i].actions[j] };

            tempAction.data.Data = evt.target.value;
            data[i].actions[j] = tempAction;

            this.setState({
              steps: data,
            });
            // this.state.steps[i].actions[j].action.Data = evt.target.value;
          }
        }
      }
    }
  }

  render() {
    if (this.state.user.PersonRole !== "Admin") {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Διαχείριση CRM</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Επιλογες
                            </h6>
                            <Button
                              color="primary"
                              // href={"/admin/instance/" + this.state.details.Id}
                              size="sm"
                            >
                              Τροποποίηση προτύπων αιτήσεων
                            </Button>
                            <Button
                              color="success"
                              // href={"/admin/instance/" + this.state.details.Id}
                              size="sm"
                            >
                              Δημιουργία νέου προτύπου
                            </Button>
                            <Button
                              color="warning"
                              href={"/admin/editmenu/"}
                              size="sm"
                            >
                              Τροποποίηση αιτήσεων
                            </Button>
                            {/* <Button
                                                color="primary"
                                                // href={"/admin/commenthistory/" + this.state.details.Id}                              
                                                size="sm"
                                            >
                                                Αλλαγές δικαιωμάτων
                                            </Button>
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                
                                                size="sm"
                                            >
                                                Ρυθμίσεις
                                            </Button> */}
                            {/* <Button
                                                color="danger"
                                                // onClick={() => { this.recallStep() }}
                                                size="sm"
                                            >
                                                Κλείδωμα συστήματος
                                            </Button> */}
                            <hr className="my-4" />
                          </Col>

                          <Col md="12">
                            <h6 className="heading-small text-muted mb-4">
                              Δοκιμες
                            </h6>
                            {/* <Button
                                            color="primary"
                                            // href={"/admin/instance/" + this.state.details.Id}
                                            size="sm"
                                            >
                                            Τροποποίηση προτύπων αιτήσεων
                                            </Button> */}
                            <Button
                              color="danger"
                              href={"/admin/testing/"}
                              size="sm"
                            >
                              Testing Zone
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Τίτλος</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row> */}
          </Container>
        </>
      );
    }
  }
}

export default Management;
