import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

import { getUserCurrentAsync } from "./apiCallerHelper.auth";

import { getAllWorkflows } from "./apiCallerHelper.workflows";

import {
  getWorkflowInstanceById,
  getWorkflowInstanceDetailsById,
  postUpdateWorkflowInstances,
  postApproveWorkflowInstances,
  postAssignWorkflowInstances,
  postDeclineWorkflowInstances,
  checkSerialAndUpdate,
  newInstanceSystemic,
  getFundingFlag,
  respondToFunding,
  getdepall,
} from "./apiCallerHelper.workflowinstances";

import {
  getAllConsumers,
  getConsumerDetails,
  getConsumerInstances,
  changeConsumer,
} from "./apiCallerHelper.consumer";

import {
  getAllSuppliers,
  getSuppliersProfile,
  postSuppliersRequest,
  postSuppliersSavedata,
  postSuppliersSuccession,
  postSuppliersCrmSaveData,
  getSuppliersHistory,
  getSupplierByInstanceId,
} from "./apiCallerHelper.suppliers";

import {
  // getAllMeters,
  setMOPBilling,
  setMOPDeclaration,
  getMeterById,
  setMetterActiveState,
  updateMeter,
  getMetricSystems,
} from "./apiCallerHelper.meters";

import {
  getAllProperties,
  getPropertyDetails,
  postNewPropertyGIS,
  updatePropertyGIS,
  savePropertyChanges,
  changePropertyInability,
  transferInstanceProperty,
  deleteProperty,
  getPropertyByInstanceId,
} from "./apiCallerHelper.property";

import {
  getExportConnections,
  getExportTechnicalReview,
} from "./apiCallerHelper.export";

import {
  getGridViewDataById,
  getPageViewDataById,
} from "./apiCallerHelper.views";

import {
  getFilesStatusByInstanceId,
  getFilesForFunding,
  getAllInstanceFilesByInstanceId,
  deleteFileByFileId,
} from "./apiCallerHelper.files";

import {
  createMeter,
  createIkasp,
  failedMechanicCompletion,
  requestAutopsy,
  calculatePriceOrRiser,
  getLocations,
  instanceLocation,
  updateLocation,
} from "./apiCallerHelper.dynamic";

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Auth: {
    getUserCurrentAsync,
  },
  Users: {
    getCurrentAsync: getUserCurrentAsync,
  },
  Consumer: {
    getAllConsumers,
    getConsumerDetails,
    getConsumerInstances,
    changeConsumer,
  },
  Workflows: {
    getAllWorkflows: getAllWorkflows,
  },
  WorkflowInstances: {
    getWorkflowInstanceById,
    getWorkflowInstanceDetailsById,
    postUpdateWorkflowInstances,
    postApproveWorkflowInstances,
    postAssignWorkflowInstances,
    postDeclineWorkflowInstances,
    checkSerialAndUpdate,
    newInstanceSystemic,
    getFundingFlag,
    respondToFunding,
    getdepall,
  },
  Suppliers: {
    getAllSuppliers,
    getSuppliersProfile,
    postSuppliersRequest,
    postSuppliersSavedata,
    postSuppliersSuccession,
    postSuppliersCrmSaveData,
    getSuppliersHistory,
    getSupplierByInstanceId,
  },
  Meters: {
    setMOPBilling,
    setMOPDeclaration,
    getMeterById,
    setMetterActiveState,
    updateMeter,
    getMetricSystems,
  },
  Property: {
    getAllProperties,
    getPropertyDetails,
    postNewPropertyGIS,
    updatePropertyGIS,
    savePropertyChanges,
    changePropertyInability,
    transferInstanceProperty,
    deleteProperty,
    getPropertyByInstanceId,
  },
  Export: {
    getExportConnections,
    getExportTechnicalReview,
  },
  Views: {
    getGridViewDataById,
    getPageViewDataById,
  },
  Files: {
    getFilesStatusByInstanceId,
    getAllInstanceFilesByInstanceId,
    getFilesForFunding,
    deleteFileByFileId,
  },
  Dynamic: {
    createMeter,
    createIkasp,
    failedMechanicCompletion,
    requestAutopsy,
    calculatePriceOrRiser,
    getLocations,
    instanceLocation,
    updateLocation,
  },
};

export default ApiCallerHelper;
