import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Label,
  Col,
  Alert,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import endpoint from "./config.js";

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Υποστήριξη (support)</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Ενημερωστε μας για το προβλημα σας
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="exampleText">Μήνυμα</Label>
                            <Input
                              type="textarea"
                              name="text"
                              id="exampleText"
                              style={{ height: 200 }}
                            />
                          </FormGroup>

                          <Button color="primary">Αποστολή</Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>

                  <hr className="my-4" />

                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Εναλλακτικος τροπος επικοινωνιας
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <p>Email :</p>
                          <p>Τηλέφωνο :</p>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Support;
