import React from "react";
import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import "moment/locale/el";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import EVOAlertComponent from "../../components/Abstract/EvoAlertComponent";
import Select from "react-select";

class InstanceSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      user: [],
      details: [],
      locations: [],
      selectedLocationValue: null,
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            isLoaded: true,
            user: result,
            id: handle,
          });
        });

      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/details/" +
          handle +
          "/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            details: result,
          });
        });
    }
    try {
      const locationsResponse = await ApiCallerHelper.Dynamic.getLocations();
      const instanceLocationResponse =
        await ApiCallerHelper.Dynamic.instanceLocation(handle);
      const locationsData = locationsResponse.data;
      if (Array.isArray(locationsData)) {
        const locationsOptions = locationsData.map((location) => ({
          value: location.Id,
          label: location.City,
        }));

        this.setState({
          locations: locationsOptions,
          selectedLocationValue: instanceLocationResponse.data,
        });
      } else {
        console.error(
          "Locations response data is not an array:",
          locationsData
        );
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  }

  updateInstanceLocation = async () => {
    const { selectedLocationValue } = this.state;
    const { id } = this.state;

    if (localStorage.getItem("token")) {
      try {
        // Make the API call to update the instance location
        await ApiCallerHelper.Dynamic.updateLocation(id, selectedLocationValue);

        // Update the state to reflect the new location
        this.setState({ selectedLocationValue });

        this.setState({ selectedLocationValue });
      } catch (error) {
        console.error("Error updating location:", error);
      }
    }
  };

  deleteInstance = async (instanceId) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (localStorage.getItem("token")) {
        axios
          .delete(
            process.env.REACT_APP_API_LINK +
              "/api/workflowinstances/" +
              this.state.details.Id +
              "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            setTimeout(function () {
              window.location.replace("/admin/index/");
            }, 2500);
          });
      }
    }
  };

  freezeInstance = async (status) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (localStorage.getItem("token")) {
        fetch(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/freeze/" +
            this.state.details.Id +
            "/",
          {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),
            }),
          }
        ).then((data) => {
          window.location.reload(false);
        });
      }
    }
  };

  reactivateInstance = async (status) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (localStorage.getItem("token")) {
        fetch(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/reactivate/" +
            this.state.details.Id +
            "/",
          {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),
            }),
          }
        ).then((data) => {
          window.location.reload(false);
        });
      }
    }
  };

  disengagementMechanic = async () => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      const handle = this.props.match.params.id;

      if (localStorage.getItem("token")) {
        fetch(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/disengagement/" +
            handle +
            "/",
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem("token"),
            }),
          }
        ).then((data) => {
          window.location.reload(false);
        });
      }
    }
  };
  renderLocationSelector() {
    const { locations, selectedLocationValue } = this.state;
    return (
      <div>
        <Row>
          <Col md="4">
            <br></br>
            <br></br>
            <label className="form-control-label">
              Επιλογή Νέας Πόλης ( Τρέχουσα: {selectedLocationValue} )
            </label>
            <Select
              options={locations}
              placeholder="Επιλογή..."
              value={selectedLocationValue}
              onChange={(selectedOption) => {
                this.setState({ selectedLocationValue: selectedOption.label });
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Ρυθμίσεις Αίτησης</h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        {(this.state.user.PersonRole == "Admin" ||
                          this.state.user.PersonRole == "Guest" ||
                          this.state.user.PersonRole == "Maintenance") &&
                        this.state.details.Status > 7 ? (
                          <Button
                            color="primary"
                            href={
                              "/admin/supplierdata/" + this.state.details.Id
                            }
                            size="sm"
                          >
                            Στοιχεία παρόχων
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {this.state.user.PersonRole == "Admin" ||
                        this.state.user.PersonRole == "Guest" ||
                        this.state.user.PersonRole == "Sales" ? (
                          <Button
                            color="primary"
                            href={"/admin/timers/" + this.state.details.Id}
                            size="sm"
                          >
                            Αλλαγή παραμέτρων αίτησης
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {this.state.user.PersonRole == "Admin" ||
                        this.state.user.PersonRole == "Guest" ||
                        this.state.user.PersonRole == "Maintenance" ? (
                          <Button
                            color="primary"
                            href={"/admin/requests/" + this.state.details.Id}
                            size="sm"
                          >
                            Αιτήματα Προμηθευτών
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {this.state.user.PersonRole == "Admin" ? (
                          <Button
                            color="warning"
                            href={"/admin/edit/" + this.state.details.Id}
                            size="sm"
                          >
                            Τροποποίηση πεδίων αίτησης
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {this.state.user.PersonRole == "Admin" ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              this.deleteInstance();
                            }}
                            size="sm"
                          >
                            Διαγραφή Αίτησης
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {(this.state.user.PersonRole == "Admin" ||
                          this.state.user.PersonRole == "Guest" ||
                          this.state.user.PersonRole == "Maintenance") &&
                        !this.state.details.Completed ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              this.freezeInstance();
                            }}
                            size="sm"
                          >
                            Διακοπή σύνδεσης
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {(this.state.user.PersonRole == "Admin" ||
                          this.state.user.PersonRole == "Sales") &&
                        this.state.details.Status == 6 &&
                        !this.state.details.Completed ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              this.freezeInstance();
                            }}
                            size="sm"
                          >
                            Υπαναχώρηση
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {(this.state.user.PersonRole == "Admin" ||
                          this.state.user.PersonRole == "Sales") &&
                        this.state.details.Status < 6 &&
                        !this.state.details.Completed ? (
                          <Button
                            color="danger"
                            onClick={() => {
                              this.freezeInstance();
                            }}
                            size="sm"
                          >
                            Ακύρωση Αίτησης
                          </Button>
                        ) : (
                          <> </>
                        )}
                        {(this.state.user.PersonRole == "Admin" ||
                          this.state.user.PersonRole == "Sales" ||
                          this.state.user.PersonRole == "Maintenance") &&
                        this.state.details.Completed ? (
                          <Button
                            color="success"
                            onClick={() => {
                              this.reactivateInstance();
                            }}
                            size="sm"
                          >
                            Επανενεργοποίηση Αίτησης
                          </Button>
                        ) : (
                          <> </>
                        )}
                      </Row>
                    </div>
                    {this.state.user.PersonRole == "Admin" &&
                      this.renderLocationSelector()}
                    <div className="pl-lg-4">
                      <br></br>
                      <Row>
                        <Button
                          color="success"
                          onClick={this.updateInstanceLocation}
                          size="sm"
                        >
                          Αποθήκευση
                        </Button>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="push"></div>
        </Container>
      </>
    );
  }
}

export default InstanceSettings;
