import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from "react-loadingg";

import axios from "axios";

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";
import ApplicationHelper from "../../common/ApplicationHelper.js";
class Instance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      loading: true,
      application: [],
      user: [],
      enabled: true,
      comment: "",
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;
    const result = await axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/applications/check/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          application: res.data,
        });
      });

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  changeAvailability = (status) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/applications/availability/",
        {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            ApplicationId: this.state.application.Id,
            Option: status,
          }),
        }
      ).then((data) => {
        window.location.reload(false);
      });
    }
  };

  updateComment(evt) {
    this.setState({
      comment: evt.target.value,
    });
  }

  saveChanges = (id) => {
    if (localStorage.getItem("token")) {
      fetch(
        process.env.REACT_APP_API_LINK + "/api/applications/updatecomment/",
        {
          method: "PUT",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
          }),
          body: JSON.stringify({
            InstanceId: this.state.application.Id,
            Comment: this.state.comment,
          }),
        }
      ).then((data) => {
        // window.location.reload(false);
      });
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <InstanceHeader />
          <div
            className="header pb-4"
            style={{
              background: "linear-gradient(to right, #00467F, #5CBC59)",
            }}
          >
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col lg="8" xl="12">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            ></CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              Φυσικό Αέριο
                            </span>
                            <CardBody>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Διαθεσιμότητα Δικτύου:
                                </span>
                                {ApplicationHelper.GetApplicationAvailabilityDescription(
                                  this.state.application
                                )}
                              </Row>
                              <Row className="align-items-center">
                                <span style={{ fontWeight: "bold" }}>
                                  Ημερομηνία Υποβολής:
                                </span>
                                {Moment(this.state.application.dateCreated)
                                  .locale("el")
                                  .format("dddd, DD MMM YYYY")}
                              </Row>
                            </CardBody>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          <Container className="mt-2" fluid>
            <Row className="mt-5">
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8"></Col>
                      {this.state.user.PersonRole == "Admin" ||
                      this.state.user.PersonRole == "Sales" ? (
                        <>
                          <Col className="text-right" xs="4">
                            {/* <Button
                                                        color="primary"
                                                        href="#pablo"
                                                        onClick={e => e.preventDefault()}
                                                        size="sm"
                                                    >
                                                        Αποθήκευση αλλαγών
                                                    </Button> */}
                            {this.state.application.availability == 1 ? (
                              <Button
                                color="danger"
                                onClick={() => {
                                  this.changeAvailability(2);
                                }}
                                size="sm"
                              >
                                Μεταφορά εκτός δικτύου
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                onClick={() => {
                                  this.changeAvailability(1);
                                }}
                                size="sm"
                              >
                                Μεταφορά εντός δικτύου
                              </Button>
                            )}
                          </Col>
                        </>
                      ) : (
                        <> </>
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Καταναλωτη
                        </h6>

                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Όνομα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.Name}
                                id="input-username"
                                placeholder="Όνομα"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επίθετο
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.LastName}
                                id="input-username"
                                placeholder="Επίθετο"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Τηλέφωνο Επικοινωνίας
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={
                                  this.state.application.phoneNumber
                                }
                                id="input-username"
                                placeholder="Τηλέφωνο Επικοινωνίας"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.Email}
                                id="input-username"
                                placeholder="Email"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Σημειου Εγκαταστασης
                        </h6>
                        <div className="pl-lg-4"></div>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Διεύθυνση
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={
                                  this.state.application.mainAddress
                                }
                                id="input-username"
                                placeholder="Διεύθυνση"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Αριθμός
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.addressNum}
                                id="input-username"
                                placeholder="Αριθμός"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Πόλη
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.city}
                                id="input-username"
                                placeholder="Πόλη"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Περιφέρεια
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.state}
                                id="input-username"
                                placeholder="Περιφέρεια"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ταχυδρομικός Κώδικας
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.zipCode}
                                id="input-username"
                                placeholder="Διεύθυνση"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Γεωγραφικό πλάτος
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.lat}
                                id="input-username"
                                placeholder="Αριθμός"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Γεωγραφικό μήκος
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.lon}
                                id="input-username"
                                placeholder="Πόλη"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Σχόλια
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.application.Comments}
                                id="input-username"
                                placeholder="Σχόλια"
                                type="textarea"
                                onChange={(evt) => this.updateComment(evt)}
                              />
                              <br />
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.saveChanges();
                                }}
                                size="sm"
                              >
                                Αποθήκευση σχολίου
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Instance;
