import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
} from "./apiCallerHelper.core";
    

export async function getWorkflowInstanceById(workflowInstanceId) {
    var route = "/api/workflowinstances/instance/" + workflowInstanceId + "/";
    return getAsync(route);
}

export async function getWorkflowInstanceDetailsById(workflowInstanceId) {
    var route = "/api/workflowinstances/details/" + workflowInstanceId + "/"
}

export async function postUpdateWorkflowInstances(workflowInstanceId, data) {
    var route = "/api/workflowinstances/update/" + workflowInstanceId + "/";
    return postAsync(route, data);
}

export async function postApproveWorkflowInstances(workflowInstanceId, data) {
    var route = "/api/workflowinstances/approve/" + workflowInstanceId + "/";
    return postAsync(route, data);
}

export async function postAssignWorkflowInstances(workflowInstanceId, userId) {
    var route = "/api/workflowinstances/assign/";
    let data = {
        "InstanceId": workflowInstanceId,
        "UserId": userId
    }     
    return postAsync(route, data);
}

export async function postDeclineWorkflowInstances(workflowInstanceId, data) {
var route = "/api/workflowinstances/decline/" + workflowInstanceId + "/";
return postAsync(route, data);
}


export async function getAllSuppliers() {
    var route = "/api/workflowinstances/all/";
    return getAsync(route);
}

    
export async function checkSerialAndUpdate(serial, instanceId) {
    var route = "/api/workflowinstances/checkserial/";
    let data = {
        NewSerial : serial,
        InstanceId : instanceId,
    }
    return postAsync(route, data);
}

export async function newInstanceSystemic(GISData, workflowId) {
    var route = "/api/workflowinstances/newinstancesystemic/";
    let data = {
        WorkflowId : workflowId,
        Address : GISData.mainAddress,
        Num: GISData.addressNum,
        Postal: GISData.zipCode,
        City: GISData.city,
        Lat: GISData.lat,
        Lon: GISData.lon,
    }
    return postAsync(route, data);
}

export async function getFundingFlag(instanceId) {
    var route = "/api/workflowinstances/exists_funding/" + instanceId + "/";
    return getAsync(route);
}

export async function respondToFunding(fundingId, approved) {
    var route = "/api/workflowinstances/funding_response/";
    let data = {
        FundingId : fundingId,
        Approved : approved
    }
    return postAsync(route, data);
}


export async function getdepall() {
    var route = "/api/workflowinstances/depall/";
    return getAsync(route);
}
