import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import endpoint from "../../views/examples/config.js";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      total: 0,
      monthly: 0,
      perc: 0,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflowinstances/count/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              totalCount: result.count,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/userstats/editstats/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              total: result.total,
              monthly: result.monthly,
              perc: result.perc,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <div
          className="header pb-8 pt-3 pt-md-7"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="8" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aιτησεις που εχω επεξεργαστει συνολικα
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.total}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          {/* <i className="fa fa-arrow-up" /> 3.48% */}
                        </span>
                        <span className="text-nowrap"></span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="8" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Aιτησεις που εχω επεξεργαστει αυτο το μηνα
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.monthly}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-blue text-white rounded-circle shadow">
                            <i className="fas fa-layer-group" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.perc > 0 ? (
                          <>
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up" />
                              {Math.round(this.state.perc)} %
                            </span>
                            <span className="text-nowrap">
                              Τον τελευταίο μήνα
                            </span>
                          </>
                        ) : (
                          <>
                            {this.state.perc < 0 ? (
                              <>
                                <span className="text-danger mr-2">
                                  <i className="fa fa-arrow-down" />
                                  {Math.round(this.state.perc)} %
                                </span>
                                <span className="text-nowrap">
                                  Τον τελευταίο μήνα
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="text-gray mr-2">
                                  <i className="fa fa-grip-lines" />
                                  {Math.round(this.state.perc)} %
                                </span>
                                <span className="text-nowrap">
                                  Τον τελευταίο μήνα
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
