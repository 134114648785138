import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
// import Spinner from "views/examples/Spinner.js";
import Moment from "moment";
import "moment/locale/el";

import { MDBDataTable } from "mdbreact";

class InstanceRoutingHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      instances: [],
      user: [],
      history: [],
      userdict: {},
      instancesdict: {},
    };
  }

  async componentDidMount() {
    const handle = this.props.match.params.id;

    await fetch(process.env.REACT_APP_API_LINK + "/api/user/all/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        const self = this;

        result.forEach(function (element, index) {
          self.state.userdict[element.Id] = element.DisplayName;
        });
      });

    await fetch(
      process.env.REACT_APP_API_LINK + "/api/workflowinstances/allinstances/",
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        const self = this;
        result.forEach(function (element, index) {
          self.state.instancesdict[element.Id] = element.SerialNumber;
        });
      });

    axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/mechanicroutinghistory/" +
          handle +
          "/",
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const instances2 = res.data;
        var self = this;

        instances2.forEach(function (part, index) {
          this[index].DateChanged = this[index].DateChanged.split("T")[0];
          this[index].UserId = self.state.userdict[this[index].UserId];
          this[index].InstanceId =
            self.state.instancesdict[this[index].InstanceId];
        }, instances2);

        this.datas = {
          columns: [
            {
              label: "Αριθμος Αιτησης ▼",
              field: "InstanceId",
              sort: "asc",
              width: 100,
            },
            // {
            //   label: 'Τοποθεσια ▼',
            //   field: 'Location',
            //   sort: 'asc',
            //   width: 150
            // },
            {
              label: "Ανατεθηκε Σε ▼",
              field: "UserId",
              sort: "asc",
              width: 150,
            },
            {
              label: "Ημερομηνια",
              field: "DateChanged",
              sort: "asc",
              width: 150,
            },
          ],
          rows: res.data,
        };

        const instances = res.data;
        this.setState({ instances });
      });

    await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            user: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  handleClick = (instanceId) => {
    if (localStorage.getItem("token")) {
      axios
        .delete(
          process.env.REACT_APP_API_LINK +
            "/api/workflowinstances/" +
            instanceId +
            "/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          window.location.reload(false);
        });
    }
  };

  acceptReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/answerreq/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Id: reqId,
          Answer: 1,
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  declineReq = (reqId) => {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/mechanics/answerreq/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Id: reqId,
          Answer: 0,
        }),
      }).then((data) => {
        window.location.reload(false);
      });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--8" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        Ιστορικό Δρομολόγησης της Αίτησης
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    responsive
                    strip
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    // noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    // sNext="Suivant"
                    // noDataComponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default InstanceRoutingHistory;
