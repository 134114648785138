import React from "react";
import {
  Button,
  Card,
  CardHeader,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";

import "leaflet/dist/leaflet.css";

import axios from "axios";
import Select from "react-select";

import { MDBDataTable } from "mdbreact";
class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      allUsers: [],
      instanceNumber: "",
      selectedUser: "",
      user: "",
      instances: [],
      routingUser: [],
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              user: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      axios
        .get(process.env.REACT_APP_API_LINK + "/api/mechanics/unrouted/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          const instances2 = res.data;
          var self = this;

          instances2.forEach(function (part, index) {
            this[index].StartedAt = this[index].StartedAt;
            const isInitialSubmission =
              this[index].StateActionData === undefined ||
              this[index].StateActionData === null;
            // this[index].StateActionData === "";
            this[index].SubmissionType = isInitialSubmission
              ? "Αρχική"
              : "Επανυποβολή";
            this[index].Buttons = (
              <React.Fragment>
                {/* <Button
                                color="success"
                                // onClick={() => { self.acceptReq(this[index].ReqId) }}
                                href={"/admin/instanceroutinghistory/" + this[index].Id + "/"}
                                size="sm"
                                title="Ιστορικό Δρομολόγησης"
                            >
                                Ιστορικό Δρομολόγησης
                            </Button> */}
                <Button
                  color="primary"
                  href={"/admin/details/" + this[index].Id}
                  size="sm"
                  title="Στοιχεία Αίτησης"
                >
                  Στοιχεία Αίτησης
                </Button>
                <Button
                  color="warning"
                  href={"/admin/instance/" + this[index].Id}
                  size="sm"
                >
                  Βήματα Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);
          this.datas = {
            columns: [
              {
                label: "Αριθμος Αιτησης ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 100,
              },
              {
                label: "Τοποθεσια ▼",
                field: "Location",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ημερομηνια Υποβολης ▼",
                field: "StartedAt",
                sort: "asc",
                width: 150,
              },
              {
                label: "Υποβολη ▼",
                field: "SubmissionType",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ενεργειες",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });

      await fetch(
        process.env.REACT_APP_API_LINK + "/api/user/routing_privileges/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              routingUser: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      await fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );

      await fetch(
        process.env.REACT_APP_API_LINK + "/api/user/studymechanics/",
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let temp_list = [...result];
            let options_list = [];

            temp_list.forEach((part, index) => {
              options_list.push({ value: part.Id, label: part.DisplayName });
            });
            this.setState({
              allUsers: options_list,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  handleSelectChange(e) {
    this.setState({
      selectedUser: e.value,
    });
  }

  handleChange(e) {
    this.setState({
      instanceNumber: e.target.value,
    });
  }

  assignFunc() {
    if (this.state.selectedUser != "" && this.state.instanceNumber != "") {
      fetch(
        process.env.REACT_APP_API_LINK +
          "/api/workflowinstances/assigninstance/",
        {
          method: "post",
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/x-www-form-urlencoded",
          }),
          body: JSON.stringify({
            UserId: this.state.selectedUser,
            SerialNumber: this.state.instanceNumber,
            type: "Μελέτη",
          }),
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result != -1 && result != -2) window.location.reload();
            if (result == -1)
              alert(
                "Η αίτηση με αριθμό " +
                  this.state.instanceNumber +
                  " δεν βρέθηκε!"
              );
            if (result == -2)
              alert(
                "Η αίτηση με αριθμό " +
                  this.state.instanceNumber +
                  " είναι ήδη δρομολογημένη!"
              );
          },
          (error) => {
            alert(
              "Η αίτηση με αριθμό " +
                this.state.instanceNumber +
                " δεν βρέθηκε!"
            );
          }
        );
    }
  }

  render() {
    return (
      <>
        {this.state.routingUser.includes(this.state.user.Email) ? (
          <>
            <GenericHeader />
            <Container className="mt--7" fluid>
              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">Δρομολόγηση Μελετών</h3>
                        </Col>
                        <Col className="text-right" xs="4"></Col>
                      </Row>
                    </CardHeader>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="input-instance-number"
                          >
                            Αριθμός Αίτησης
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input-instance-number"
                            type="number"
                            placeholder="Αριθμός Αίτησης"
                            onChange={(evt) => this.handleChange(evt)}
                          />
                        </Col>
                        <Col xs="4">
                          <label
                            className="form-control-label"
                            htmlFor="select-user"
                          >
                            Επιλογή Χρήστη
                          </label>
                          <Select
                            id="select-user"
                            options={this.state.allUsers}
                            placeholder={"Επιλέξτε Χρήστη..."}
                            onChange={(e) => {
                              this.handleSelectChange(e);
                            }}
                          />
                        </Col>
                        <Col xs="4">
                          <Button
                            color="primary"
                            size="sm"
                            style={{ padding: "10px", marginTop: "20px" }}
                            onClick={() => this.assignFunc()}
                          >
                            Ανάθεση
                          </Button>
                        </Col>
                      </Row>
                      <br />
                      <h4>Μελέτες προς δρομολόγηση</h4>
                      <br />
                      <MDBDataTable
                        bordered
                        exportToCSV
                        hover
                        responsive
                        strip
                        data={this.datas}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]}
                        entries={10}
                        noDataComponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        sNext="Suivant"
                        paginationLabel={["Προηγούμενο", "Επόμενο"]}
                        infoLabel={[
                          "Εμφανίζονται",
                          "έως",
                          "από",
                          "καταχωρήσεις",
                        ]}
                        entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                      />
                    </CardHeader>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default Routing;
