import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import dedaBackground from "../../assets/img/deda-background.jpg";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import GlobalParametersHelper from "../../common/GlobalParametersHelper";

async function loginUser(credentials) {
  return fetch(process.env.REACT_APP_API_LINK + "/api/auth/token/", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [erroremail, setErrorEmail] = useState(false);
  const [errorpass, setErrorPass] = useState(false);
  const [platform, setPlatform] = useState("crm");

  const handleSubmit = async e => {
    e.preventDefault();
    const auth = await loginUser({
      email,
      password, 
      platform
    });

    if (auth.detail == "Incorrect username or password")
    {
      setErrorEmail(true);
    }    
    if (auth.errors) {
      setErrorEmail(false);
      setErrorPass(false);
      if (auth.errors == 'Email not exists') {
        alert('caught wrong email');
        setErrorEmail(true);
        GlobalParametersHelper.Token.remove();        
      }
      if (auth.errors == 'Password Incorrect') {
        alert('caught wrong pass');
        setErrorPass(true);
        GlobalParametersHelper.Token.remove();                
      }
    };
    if (auth.access_token) {
      GlobalParametersHelper.Token.set(String(auth.access_token));            
      window.location.replace("/admin/index");
    };
    
  }

  return(
    <div className="main-content" style={{backgroundImage: `url(${dedaBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed", height: "100vh" }}>
          <AuthNavbar />
          <div className="header py-7 py-lg-8" ></div>
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
    <Col lg="5" md="7" >
      <Card className="shadow border-0" style={{ backgroundColor: "rgba(245, 245, 245, 0.4)"}}>
        <CardBody className="px-lg-5 py-lg-5">
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Email" type="text" onChange={e => setEmail(e.target.value)}/>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  </InputGroupAddon>
                    <Input placeholder="Κωδικός" type="password" onChange={e => setPassword(e.target.value)}/>
                  </InputGroup>
            </FormGroup>
            {erroremail ?  <p style={{color: 'orange'}}>To email ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.</p> : <></>}
            {errorpass ?  <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="text" style={{color: 'red'}}/> : <Input placeholder="Error with password" value="Λανθασμένος κωδικός." type="hidden" style={{color: 'red'}}/>}            
            
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                Σύνδεση
              </Button>
            </div>
          </Form>
          {/* <a
            className="text-light"
            href="#pablo"
            onClick={e => e.preventDefault()}
          >
            <small>Ξεχάσατε τον κωδικό σας ;</small>
          </a> */}
        </CardBody>
      </Card>
    </Col>
    </Row>
          </Container>
          </div>
  )
}

// <form onSubmit={handleSubmit}>
    //   <label>
    //     <p>Email</p>
    //     <input type="text" onChange={e => setEmail(e.target.value)}/>
    //   </label>
    //   <label>
    //     <p>Password</p>
    //     <input type="password" onChange={e => setPassword(e.target.value)}/>
    //   </label>
    //   <div>
    //     <button type="submit">Submit</button>
    //   </div>
    // </form>

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}