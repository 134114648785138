import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import WorkflowStepsHeader from "components/Headers/WorkflowStepsHeader.js";
import Accordion from "react-bootstrap/Accordion";
import Moment from "moment";
import "moment/locale/el";

import endpoint from "./config.js";

class WorkflowSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      workflow: [],
      steps: [],
      roles: [],
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/workflows/" + handle + "/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              workflow: result,
              steps: result.steps,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/user/roles/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              roles: result,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  render() {
    return (
      <>
        <WorkflowStepsHeader />
        <div
          className="header pb-4"
          style={{ background: "linear-gradient(to right, #00467F, #5CBC59)" }}
        >
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col lg="8" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          ></CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.workflow.name}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Accordion defaultActiveKey={this.state.steps.status}>
          {this.state.steps
            .sort((a, b) => a.status - b.status)
            .map((dynamicData) => (
              <Container className="mt-2" fluid>
                <Row className="mt-5">
                  <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                      <CardHeader className="bg-white border-0">
                        <Accordion.Toggle
                          as={CardHeader}
                          eventKey={dynamicData.status}
                        >
                          <Row className="align-items-center">
                            <Col xs="8">
                              <h3 className="mb-0">{dynamicData.name}</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                              <Button
                                color="primary"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                size="sm"
                              >
                                Αποθήκευση αλλαγών
                              </Button>
                              <Button
                                color="primary"
                                href={"/admin/stepactions/" + dynamicData.id}
                                size="sm"
                              >
                                Προβολή ενεργειών
                              </Button>
                            </Col>
                          </Row>
                        </Accordion.Toggle>
                      </CardHeader>
                      <Accordion.Collapse eventKey={dynamicData.status}>
                        <CardBody>
                          <Form>
                            <h6 className="heading-small text-muted mb-4">
                              Βασικα στοιχεια βήματος
                            </h6>
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-username"
                                    >
                                      Όνομα βήματος
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue={dynamicData.name}
                                      id="input-username"
                                      placeholder="Όνομα προγράμματος"
                                      type="text"
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-username"
                                    >
                                      Διάρκεια (ημέρες)
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue={dynamicData.duration}
                                      id="input-username"
                                      placeholder="Διάρκεια προγράμματος"
                                      type="number"
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Κατάσταση
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue={dynamicData.priority}
                                      id="input-username"
                                      placeholder="Προτεραιότητα"
                                      type="number"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-email"
                                    >
                                      Περιγραφή
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      placeholder="Περιγραφή προγράμματος"
                                      rows="6"
                                      defaultValue={dynamicData.description}
                                      type="textarea"
                                    />
                                  </FormGroup>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-first-name"
                                    >
                                      Ολοκληρώθηκε από
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      defaultValue={dynamicData.role}
                                      id="input-first-name"
                                      placeholder="First name"
                                      type="select"
                                    >
                                      {this.state.roles.map((dynamicRoles) => (
                                        <option>{dynamicRoles}</option>
                                      ))}
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                            <hr className="my-4" />
                          </Form>

                          {/* <Form>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        {dynamicData.actions.sort((a, b) => a.order - b.order).map((dynamicActions) =>
                                                        <Col lg="4">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-username"
                                                                >
                                                                    {dynamicActions.name}
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    defaultValue=""
                                                                    id="input-username"
                                                                    placeholder={dynamicActions.description}
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        )}
                                                    </Row>
                                                </div>
                                            </Form> */}
                        </CardBody>
                      </Accordion.Collapse>
                    </Card>
                  </Col>
                </Row>
              </Container>
            ))}
        </Accordion>
      </>
    );
  }
}

export default WorkflowSteps;
